import React, { useEffect, useState } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useStore } from "@/contexts/userStore";
import ImportDetailsDisplayTable from "@/components/tables/ImportDetailsDisplayTable";
import {
  cn,
  getBackendUrl,
  parseDocumentUrls,
  splitArrayIntoParts,
} from "@/lib/utils";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PaymentForm } from "@/components/forms/PaymentForm";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { registrationsData } from "@/lib/dummy-data/registrationsData";
import { clearanceApplications } from "@/lib/dummy-data/clearanceApplicationData";
import ItemDetailsAdminTable from "@/components/tables/itemDetailsAdminTable";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import ClearanceCertificate from "@/components/docs/certificate";
import { format } from "fecha";
import { toast } from "sonner";
import { saveAs } from "file-saver";
import { ImportItem } from "@/lib/interfaces";

type CertificateDetailsResponse = {
  certificate_details: {
    id: number;
    nesrea_id: string;
    importer_name: string;
    importer_email: string;
    clearance_id: string;
    issuerance_date: string;
    certificate_number: string;
    expiry_date: string;
    created_by: string;
    status: string;
    created_at: string;
    updated_at: string;
    activities: string;
  };
  clearance_details: {
    id: number;
    nesrea_id: string;
    importer_email: string;
    importer_category: string;
    clearance_id: string;
    clearance_date: string;
    item: string;
    hs_code: string;
    item_unit: string;
    item_quantity: string;
    container_cargo_number: string;
    country_of_origin: string;
    port_of_entry: string;
    purpose_of_importation: string;
    final_destination_address: string;
    expected_date_of_arrival: string;
    item_status: string;
    waiver_status: string | null;
    created_by: string;
    created_at: string;
    updated_at: string;
    action_taken_item: string;
    item_approved_by: string;
    item_approval_date: string;
    item_approval_comment: string;
    category_id: string;
    category_label: string;
    category_code: string;
    sub_category_id: string;
    sub_category_label: string;
    sub_category_code: string;
    item_category_id: string;
    item_category_label: string;
    item_category_code: string;
    item_id: string;
    item_label: string;
    item_code: string;
    cost: string;
  }[];
};
export default function CertificatesDetailsPage() {
  const SECTION_NAME = "Clearance";
  const MODULE_NAME = "Certificates";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === SECTION_NAME);
  const mod_perm = module_perms?.find((perm) => perm.module === MODULE_NAME);
  if (!permission_data || !permission_data.isAllowed || !mod_perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const [searchParams] = useSearchParams();
  let id = searchParams.get("id");
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = React.useState(0);
  const [count, setCount] = React.useState(0);

  let clearanceApplicationDetails = clearanceApplications.find(
    (data) => data.id == id
  );

  const { data, isLoading } = useQuery({
    queryKey: ["clearance-certificates"],
    queryFn: async () => {
      const { data }: { data?: CertificateDetailsResponse } = await axios.get(
        `${getBackendUrl()}/get-certifiacte-details/${id}`
      );

      if (!data?.certificate_details) {
        return undefined;
      }

      const { data: userData } = await axios.get(
        `${getBackendUrl()}/get-clearance-details/${
          data.certificate_details.clearance_id
        }`
      );

      const import_items: ImportItem[] = data?.clearance_details.map(
        (item: any) => {
          return {
            itemName: item.item,
            hscode: item.hs_code,
            unit: item.item_unit,
            quantity: item.item_quantity,
            cargo_number: item.container_cargo_number,
            proforma_invoice: "",
            country_of_origin: item.country_of_origin,
            port_of_entry: item.port_of_entry,
            purpose_of_importation: item.purpose_of_importation,
            final_destination_address: item.final_destination_address,
            expected_date_of_arrival: item.expected_date_of_arrival,
            cost: item.cost,
            classification: item.classification,
          };
        }
      );
      return { ...data, import_items, ...userData };
    },
  });

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className="w-full flex flex-col gap-7">
        <h3 className="text-2xl font-medium">
          The following are details of the certificate:
        </h3>
        <div className="w-full grid grid-cols-2 gap-7">
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">
              Importer Details
            </h3>
            <div className="w-full flex flex-col gap-6">
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Name</h6>
                <p className="text-neutral-500">
                  {data?.certificate_details.importer_name}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">Email</h6>
                <p className="text-neutral-500">
                  {data?.certificate_details.importer_email}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">NESREA ID</h6>
                <p className="text-neutral-500">
                  {data?.certificate_details.nesrea_id}
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <h6 className="font-medium text-primary-700">
                  Importer Category
                </h6>
                <p className="text-neutral-500 capitalize">
                  {data?.Clearance_info.importer_category.toLowerCase()}
                </p>
              </div>
            </div>
          </div>
          <div className="border-[0.5px] border-primary-700 bg-white rounded-xl p-11 flex flex-col gap-7">
            <h3 className="text-2xl font-semibold text-center">
              Documents Uploaded
            </h3>
            {data ? (
              <Carousel
                opts={{
                  align: "start",
                }}
                setApi={setApi}
                className="w-full h-full flex flex-col"
              >
                <CarouselContent>
                  {splitArrayIntoParts<{ name: string; url: string }>(
                    parseDocumentUrls(
                      data?.Clearance_info.document_url,
                      data?.Clearance_info.document_name
                    ),
                    3
                  ).map((sets, index) => (
                    <CarouselItem key={index} className="">
                      <div className="p-1">
                        <div className="w-full flex flex-col gap-6">
                          {sets.map((document, index) => (
                            <div
                              key={`${index}document`}
                              className="w-full flex items-center justify-between"
                            >
                              <div className="flex gap-2">
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <mask
                                    id="mask0_3238_3301"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="24"
                                    height="24"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      fill="#D9D9D9"
                                    />
                                  </mask>
                                  <g mask="url(#mask0_3238_3301)">
                                    <path
                                      d="M9 12.5H10V10.5H11C11.2833 10.5 11.5208 10.4042 11.7125 10.2125C11.9042 10.0208 12 9.78333 12 9.5V8.5C12 8.21667 11.9042 7.97917 11.7125 7.7875C11.5208 7.59583 11.2833 7.5 11 7.5H9V12.5ZM10 9.5V8.5H11V9.5H10ZM13 12.5H15C15.2833 12.5 15.5208 12.4042 15.7125 12.2125C15.9042 12.0208 16 11.7833 16 11.5V8.5C16 8.21667 15.9042 7.97917 15.7125 7.7875C15.5208 7.59583 15.2833 7.5 15 7.5H13V12.5ZM14 11.5V8.5H15V11.5H14ZM17 12.5H18V10.5H19V9.5H18V8.5H19V7.5H17V12.5ZM8 18C7.45 18 6.97917 17.8042 6.5875 17.4125C6.19583 17.0208 6 16.55 6 16V4C6 3.45 6.19583 2.97917 6.5875 2.5875C6.97917 2.19583 7.45 2 8 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H8ZM8 16H20V4H8V16ZM4 22C3.45 22 2.97917 21.8042 2.5875 21.4125C2.19583 21.0208 2 20.55 2 20V6H4V20H18V22H4Z"
                                      fill="#1C1B1F"
                                    />
                                  </g>
                                </svg>
                                <p className="font-medium text-sm max-w-[250px]">
                                  {document.name}
                                </p>
                              </div>
                              <Dialog>
                                <DialogTrigger className="text-neutral-500 underline cursor-pointer text-xs">
                                  View Document
                                </DialogTrigger>
                                <DialogContent>
                                  <embed
                                    src={document.url}
                                    width="100%"
                                    height="600px"
                                    type="application/pdf"
                                  />

                                  <DialogClose
                                    className={cn(
                                      buttonVariants({ variant: "default" }),
                                      "px-8"
                                    )}
                                  >
                                    Close
                                  </DialogClose>
                                </DialogContent>
                              </Dialog>
                            </div>
                          ))}
                        </div>
                      </div>
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <div className="w-full mt-auto flex items-center justify-center py-5 gap-4">
                  <CarouselPrevious className=" translate-y-0 w-10 h-10" />
                  {current} of {count}
                  <CarouselNext className="translate-y-0 w-10 h-10" />
                </div>
              </Carousel>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex w-full items-center gap-5 justify-end">
          <Dialog>
            <DialogTrigger
              className={cn(buttonVariants({ variant: "default" }), "px-8")}
            >
              View Certificate
            </DialogTrigger>
            <DialogContent>
              <PDFViewer className="w-full aspect-[1/1.414]">
                <ClearanceCertificate
                  nesrea_id={data?.certificate_details.nesrea_id || ""}
                  certificate_number={
                    data?.certificate_details.certificate_number || ""
                  }
                  date_issued={
                    data?.certificate_details.created_at
                      ? `${format(
                          new Date(data?.certificate_details.created_at),
                          "mediumDate"
                        )}`
                      : "N/A"
                  }
                  import_items={data?.import_items || []}
                />
              </PDFViewer>
              <Button
                onClick={async () => {
                  try {
                    const blob = await pdf(
                      <ClearanceCertificate
                        nesrea_id={data?.certificate_details.nesrea_id || ""}
                        certificate_number={
                          data?.certificate_details.certificate_number || ""
                        }
                        date_issued={
                          data?.certificate_details.created_at
                            ? `${format(
                                new Date(data?.certificate_details.created_at),
                                "mediumDate"
                              )}`
                            : "N/A"
                        }
                        import_items={data?.import_items || []}
                      />
                    ).toBlob();
                    saveAs(blob, "Environmental_Import_Clearance_Invoice.pdf");
                    toast.success("Saved Successfully.");
                  } catch (error) {
                    toast.error("An error occurred.");
                  }
                }}
                className={cn("w-full max-w-[80%] mx-auto border-none")}
              >
                Download
              </Button>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </LoaderWrapper>
  );
}
