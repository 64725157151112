import { RegistrationsData } from "../interfaces";

export const registrationsData: RegistrationsData = [
  {
    id: "NESREA/2024/001",
    name: "John Doe",
    importerCategory: "individual",
    applicationDate: "2024-01-15",
    status: "approved",
  },
  {
    id: "NESREA/2024/002",
    name: "Global Exports Ltd.",
    importerCategory: "company",
    applicationDate: "2024-02-20",
    status: "pending",
  },
  {
    id: "NESREA/2024/010",
    name: "Cargo Express",
    importerCategory: "company",
    applicationDate: "2024-09-21",
    status: "rejected",
  },
  {
    id: "NESREA/2024/003",
    name: "Ministry of Trade",
    importerCategory: "government",
    applicationDate: "2024-03-10",
    status: "approved",
  },
  {
    id: "NESREA/2024/004",
    name: "Express Logistics",
    importerCategory: "agent",
    applicationDate: "2024-04-05",
    status: "pending",
  },
  {
    id: "NESREA/2024/005",
    name: "Jane Smith",
    importerCategory: "individual",
    applicationDate: "2024-04-15",
    status: "approved",
  },
  {
    id: "NESREA/2024/006",
    name: "TransCorp Holdings",
    importerCategory: "company",
    applicationDate: "2024-05-12",
    status: "pending",
  },
  {
    id: "NESREA/2024/007",
    name: "Customs Department",
    importerCategory: "government",
    applicationDate: "2024-06-18",
    status: "approved",
  },
  {
    id: "NESREA/2024/008",
    name: "Quick Transport Services",
    importerCategory: "agent",
    applicationDate: "2024-07-01",
    status: "pending",
  },
  {
    id: "NESREA/2024/009",
    name: "Alice Brown",
    importerCategory: "individual",
    applicationDate: "2024-08-10",
    status: "approved",
  },
  {
    id: "NESREA/2024/010",
    name: "TradeCo Solutions",
    importerCategory: "company",
    applicationDate: "2024-09-21",
    status: "pending",
  },
];
