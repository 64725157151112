import React from "react";
import ImportClearanceApplicationsReport from "../../clearance/_components/ImportClearanceApplicationsReport";
import ImportClearanceApplicationsByItemCategory from "../../clearance/_components/ClearancesByItemCategory";
import ImportClearanceApplicationsbByTopItemCategoryReport from "../../clearance/_components/ClearancesByTopItemCategory";
import ImportClearanceApplicationsByUserCategoryReport from "../../clearance/_components/ClearanceByUserCategory";
import { useStore } from "@/contexts/userStore";

export default function ClearanceAnalyticsPage() {
  const SECTION_NAME = "Reports";
  const MODULE_NAME = "Clearances";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (!permission_data || !permission_data.isAllowed || !perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-10">
      {perm?.permissions.includes("view:import-clearance-applications") && (
        <ImportClearanceApplicationsReport />
      )}
      {perm?.permissions.includes("view:clearances-by-item-category") && (
        <ImportClearanceApplicationsByItemCategory />
      )}
      {perm?.permissions.includes("view:clearances-by-top-item-category") && (
        <ImportClearanceApplicationsbByTopItemCategoryReport />
      )}
      {perm?.permissions.includes("view:clearances-by-user-category") && (
        <ImportClearanceApplicationsByUserCategoryReport />
      )}
    </div>
  );
}
