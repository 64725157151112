import { z } from "zod";

export const ImportItemFormSchema = z.object({
  item: z.string().min(2, { message: "" }),
  hsCode: z.string().min(2, { message: "" }),
  unit: z.string().min(2, { message: "" }),
  quantity: z.number().min(1, { message: "Invalid Item Quantity" }),
  cargoNumber: z.string().optional(),
  countryOfOrigin: z.string().min(1, { message: "" }),
  proformaInvoice: z.string().min(1, { message: "" }),
  portOfEntry: z.string().min(1, { message: "" }),
  purposeOfImportation: z.string().min(1, { message: "" }),
  finalDestinationAddress: z.string().min(1, { message: "" }),
  expectedDateofArrival: z.string().min(1, { message: "" }),
  classification: z.string().min(1, { message: "" }),
});

export type ImportItemFormSchemaType = z.infer<typeof ImportItemFormSchema>;
