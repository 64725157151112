import React from "react";
import { DataTable } from "./data-table";
import { SupportColumns } from "./columns";
import { useStore } from "@/contexts/userStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { certificatesAdminData } from "@/lib/dummy-data/certificatesAdminData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function AdminSupportTable() {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["support-table"],
    queryFn: async () => {
      const { data } = await axios.get(`${getBackendUrl()}/fetch-all-ticket`);
      
      return data?.ticketList || [];
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={SupportColumns} data={data || []} />
    </LoaderWrapper>
  );
}
