import React from "react";
import FalsifiedRecordsReport from "../../legal/_components/FalsifiedRecordsAnalytics";
import FalsifiedRecordsTable from "@/components/tables/FalsifiedRecordsTable";
import { useStore } from "@/contexts/userStore";

export default function LegalReportPage() {
  const SECTION_NAME = "Reports";
  const MODULE_NAME = "Legal";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (!permission_data || !permission_data.isAllowed || !perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div>
      <FalsifiedRecordsTable />
    </div>
  );
}
