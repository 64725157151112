import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface ReportCert {
  id: number;
  importer_name: string;
  certificate_number: string;
  document_name: string;
  document_url: string;
  status: string | null;
  created_at: string;
  updated_at: string;
}

const processData = (data: ReportCert[]) => {
  const totalCertificates = data.length;
  const certificatesByStatus = data.reduce((acc, cert) => {
    const status = cert.status || "unknown";
    if (!acc[status]) {
      acc[status] = 0;
    }
    acc[status]++;
    return acc;
  }, {} as Record<string, number>);

  return {
    totalCertificates,
    certificatesByStatus,
  };
};

export const useLegalAnalytics = (keys?: string[]) => {
  const { data, isLoading } = useQuery({
    queryKey: keys ? ["useLegalAnalytics", ...keys] : ["useLegalAnalytics"],
    queryFn: async () => {
      const { data }: { data?: { reportcerts: ReportCert[] } } =
        await axios.get(`${getBackendUrl()}/get-all-report-certificates`);
      const proccesedData = processData(data?.reportcerts || []);
      return proccesedData;
    },
  });

  return {
    data,
    isLoading,
  };
};
