"use client";

import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, Filter } from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button, buttonVariants } from "@/components/ui/button";
import { UserCategories } from "@/components/tables/UserCategoryTable";
import { cn } from "@/lib/utils";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { SheetTrigger } from "@/components/ui/sheet";
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import CSVExport from "@/components/ui/csv-export";
import { Link } from "react-router-dom";
import DataFilter, { FilterData } from "@/components/ui/filter";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  category: UserCategories;
  showInactive: boolean;
}

const categoryPluralMap = {
  agent: "agents",
  individual: "individuals",
  company: "companies",
  government: "governments",
};

export function UserCategoryDataTable<TData, TValue>({
  columns,
  data,
  category,
  showInactive,
  data_,
  setData_,
  filteredData,
  setFilteredData,
}: DataTableProps<TData, TValue> & {
  data_: FilterData;
  setData_: React.Dispatch<React.SetStateAction<FilterData>>;
  filteredData: FilterData;
  setFilteredData: React.Dispatch<React.SetStateAction<FilterData>>;
}) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });

  return (
    <div className="rounded-xl border border-neutral-300 overflow-hidden flex flex-col gap-3">
      <div className="">
        <div className="flex items-center justify-between px-6 py-7 pb-0">
          <h4 className="text-lg text-primary-700 font-medium">
            List of registered{" "}
            <span className="capitalize">{categoryPluralMap[category]}</span>
            {showInactive && (
              <span className="text-xs text-destructive">
                {" "}
                (Deactivated Accounts)
              </span>
            )}
          </h4>
          <div className="flex items-center gap-2">
            {data_.length > 0 && (
              <DataFilter
                data={data_}
                setData={setData_}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
              />
            )}
            <CSVExport data={data as any} fileName="CSV Data" />
          </div>
        </div>
        <p className="text-sm text-neutral-500 px-6">
          List of registered{" "}
          <span className="capitalize">{categoryPluralMap[category]}</span>
        </p>
      </div>
      <div className="border-y border-neutral-300">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="bg-secondary-50 hover:bg-secondary-50/80"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => (
                  <TableHead className="text-neutral-600 px-6" key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-neutral-300 border-none hover:bg-secondary-50"
                >
                  {row.getVisibleCells().map((cell) => {
                    const { status }: any = row.original; // Assuming status is part of the row data
                    return (
                      <TableCell
                        className={`border-none px-6 text-sm ${
                          status === "inactive"
                            ? "text-red-500"
                            : "text-neutral-800"
                        }`}
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft size={15} />
        </Button>
        <span className="text-xs">
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight size={15} />
        </Button>
      </div>
    </div>
  );
}
