import React from "react";
import { useImporterApplicationForm } from "./_hooks";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import ComponentSwitch from "@/components/ui/component-switch";

export default function ImporterApplicationForm({
  importer_email,
}: {
  importer_email?: string;
}) {
  const { form, importDetails, setImportDetails, utils } =
    useImporterApplicationForm(importer_email);

  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="flex flex-col gap-4">
        <ComponentSwitch
          activeKey={form.formPage.toString()}
          components={form.formPages}
        />
      </div>
    </LoaderWrapper>
  );
}
