import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";

const chartConfig = {
  approved: {
    label: "approved",
    color: "rgb(var(--primary))",
  },
  pending: {
    label: "pending",
    color: "rgb(var(--secondary))",
  },
  rejected: {
    label: "rejected",
    color: "#8AC58A",
  },
} satisfies ChartConfig;

export function ImportClearanceChart({
  data,
}: {
  data: ReturnType<typeof useClearanceAnalytics>["data"];
}) {
  const chartData = [
    {
      status: "approved",
      count: data?.approvedApplications || 0,
      fill: "var(--color-approved)",
    },
    {
      status: "pending",
      count: data?.pendingApplications || 0,
      fill: "var(--color-pending)",
    },
    {
      status: "rejected",
      count: data?.disapprovedApplications || 0,
      fill: "var(--color-rejected)",
    },
  ];


  const totalVisitors = React.useMemo(() => {
    return chartData.reduce((acc, curr) => acc + curr.count, 0);
  }, [data]);

  const legendData = Object.entries(chartConfig).map(([key, value]) => ({
    label: value.label,
    color: value.color,
  }));
  return (
    <div className="flex flex-col">
      <div className="flex-1 flex justify-center items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="aspect-square min-w-[350px]  max-h-[350px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartData}
              dataKey="count"
              nameKey="status"
              innerRadius={100}
              strokeWidth={5}
            >
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <text
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) - 10}
                          className="fill-foreground text-3xl font-bold"
                        >
                          {totalVisitors.toLocaleString()}
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 14}
                          className=""
                        >
                          Total Number of
                        </tspan>
                        <tspan
                          x={viewBox.cx}
                          y={(viewBox.cy || 0) + 30}
                          className=""
                        >
                          Applications
                        </tspan>
                      </text>
                    );
                  }
                }}
              />
            </Pie>
          </PieChart>
        </ChartContainer>
        <div className="flex flex-col">
          {legendData.map((data, index) => (
            <div className="flex items-center gap-1">
              <div
                className="w-2 aspect-square rounded-full"
                style={{ backgroundColor: data.color }}
              ></div>
              <span className="capitalize">{data.label}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
