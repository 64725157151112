import React from "react";
import { IndividualUpdateForm, IndividualUpdateFormType } from "../_schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { LGAs, stateNamesMap, states } from "@/lib/site-data";
import { STATES } from "@/lib/interfaces";

export default function IndividualUpdateFormPageOne({
  handleSubmit,
  canSubmit,
}: {
  handleSubmit: (values: IndividualUpdateFormType) => void;
  canSubmit: boolean;
}) {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["update-information"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-importer-info/${userStore.email}`
        );
        return data?.Importer_info;
      } catch (error) {}
    },
  });

  return (
    <LoaderWrapper isLoading={isLoading}>
      {data ? (
        <FormRender
          handleSubmit={handleSubmit}
          canSubmit={canSubmit}
          data={data}
        />
      ) : (
        <></>
      )}
    </LoaderWrapper>
  );
}

const FormRender = ({
  data,
  canSubmit,
  handleSubmit,
}: {
  data: any;
  canSubmit: boolean;
  handleSubmit: any;
}) => {
  const form = useForm<IndividualUpdateFormType>({
    resolver: zodResolver(IndividualUpdateForm),
    defaultValues: {
      fullName: data?.importer_name || "",
      phoneNumber: data?.phone_number || "",
      state: data?.state || "abia",
      lga: data?.lga || "",
      association: data?.association || "",
    },
  });

  const datak = form.getValues().state;
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-5"
        onSubmit={form.handleSubmit(canSubmit ? handleSubmit : () => {})}
      >
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Company Name</FormLabel>
              <FormControl>
                <Input
                  disabled={!canSubmit}
                  type="text"
                  placeholder="Enter your company name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Phone Number <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="tel"
                  disabled={!canSubmit}
                  placeholder="Enter your phone number"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="flex-1 min-w-[300px] flex-col gap-3 flex">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Select
                  {...field}
                  disabled={!canSubmit}
                  onValueChange={(currentValue: any) => {
                    const val: IndividualUpdateFormType["state"] = currentValue;
                    form.setValue("state", val);
                    const label: any = states.filter(
                      (state) => state.value == val
                    )[0].label;
                    const myLabel: STATES = label;
                    form.setValue("lga", LGAs[myLabel][0]);
                    form.trigger("state")
                  }}
                >
                  <SelectTrigger className="!m-0">
                    <SelectValue placeholder="State" />
                  </SelectTrigger>
                  <SelectContent>
                    {states.map((state) => (
                      <SelectItem key={state.value} value={state.value}>
                        {state.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="lga"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Local Government Area (LGA)</FormLabel>
              <FormControl>
                <Select
                  disabled={!canSubmit}
                  onValueChange={(value) => {
                    form.setValue("lga", value);
                    field.onBlur();
                    form.trigger("lga")
                  }}
                  {...field}
                >
                  <SelectTrigger className="">
                    <SelectValue placeholder="Local Government Area" />
                  </SelectTrigger>
                  <SelectContent>
                    {LGAs[stateNamesMap[form.getValues().state]] ? (
                      LGAs[stateNamesMap[form.getValues().state]].map((lga) => (
                        <SelectItem key={lga} value={lga}>
                          {lga}
                        </SelectItem>
                      ))
                    ) : (
                      <SelectItem value="n/a">Please Select a State</SelectItem>
                    )}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Street</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  disabled={!canSubmit}
                  placeholder="Enter your street name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="association"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Association</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  disabled={!canSubmit}
                  placeholder="Enter your street name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {canSubmit && (
          <Button
            type="submit"
            disabled={!form.formState.isDirty}
            className="w-fit"
          >
            Save Changes
          </Button>
        )}
      </form>
    </Form>
  );
};
