import React from "react";

export default function ComponentSwitch({
  activeKey,
  components,
}: {
  activeKey: string;
  components: { key: string; component: React.ReactNode }[];
}) {
  const Component = components.find((component) => component.key === activeKey);
  return Component ? Component.component : <>NO ACTIVE COMPONENT</>;
}
