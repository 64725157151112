import GeneratedInvoiceTable from "@/components/tables/generatedInvoicesTable";
import InvoiceTable from "@/components/tables/InvoiceTable";
import { buttonVariants } from "@/components/ui/button";
import { useStore } from "@/contexts/userStore";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function InvoicesPage() {
  const SECTION_NAME = "Accounting";
  const MODULE_NAME = "Invoicing";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms.find((perm) => perm.module === MODULE_NAME);
  return (
    <div className="flex flex-col gap-4">
      {perm?.permissions.includes("action:generate-invoice") && (
        <div className="flex justify-end w-full">
          <Link
            to={"/admin/accounting/invoicing/generate-invoice"}
            className={cn(buttonVariants({ variant: "default" }), "px-8")}
          >
            Generate Invoice
          </Link>
        </div>
      )}
      {perm?.permissions.includes("view:invoices") && (
        <InvoiceTable />
      )}
      {perm?.permissions.includes("view:generated-invoices") && (
        <GeneratedInvoiceTable />
      )}
    </div>
  );
}
