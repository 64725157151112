import React, { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { PasswordInput } from "@/components/ui/password-input";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getBackendUrl } from "@/lib/utils";
import axios, { AxiosError } from "axios";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import Cookies from "js-cookie";
import { Key } from "lucide-react";

const FormSchema = z.object({
  email: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  password: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
});

export default function SignInForm({
  setIsOTP,
}: {
  setIsOTP: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const userStore = useStore();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  async function onSubmitUser(values: z.infer<typeof FormSchema>) {
    try {
      setIsLoading(true);
      let {
        data: { session_id, userLoginInfo, message, user_Profile_info },
      } = await axios.post(`${getBackendUrl()}/user-login`, {
        email: values.email,
        password: values.password,
      });

      if (userLoginInfo.email_verification_status == null) {
        toast.error("Your email is not verified", {
          description: "Please verify your email to continue",
        });
        setIsLoading(false);
        setIsOTP(true);
        return;
      }

      const user_data: userStoreInitialState["user"] = {
        id: userLoginInfo.id,
        profile_updated: user_Profile_info?.status == null ? false : true,
        registration_status:
          user_Profile_info?.status === null
            ? "unverified"
            : user_Profile_info?.status == "Approved"
            ? "verified"
            : "pending",
        registration_payment_status: user_Profile_info?.payment_status
          ? "paid"
          : "unpaid",
        registration_payment_date: "",
        nesrea_id: userLoginInfo.nesrea_id,
        name: user_Profile_info?.importer_name || "",
        email: userLoginInfo.email,
        account_type: userLoginInfo.account_type,
        email_verification_status: userLoginInfo.email_verification_status,
        phone_number: user_Profile_info?.phone_number || "",
        state: user_Profile_info?.state || "",
        association: user_Profile_info?.association || "",
        nin: user_Profile_info?.tin_nin_cac,
        created_at: userLoginInfo.created_at,
        updated_at: userLoginInfo.updated_at,
      };

      // store user data in local storage
      localStorage.setItem("nesrea_session_id", session_id);
      localStorage.setItem("nesrea_user", JSON.stringify(user_data));

      const expiryTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
      localStorage.setItem("nesrea_session_expiry", expiryTime.toString());

      toast.success("Login successful", {
        description: <>You can now access your dashboard</>,
      });
      userStore.setUser(user_data);
      navigate("/user");
    } catch (error: any) {
      const errMessage = error.response?.data?.message;

      toast.error(errMessage || "An error occurred while signing in", {
        description: "Please try again",
      });
      setIsLoading(false);
    }
  }

  return (
    <div className="w-full max-w-lg shadow-lg m-auto p-12 flex flex-col items-center gap-7 text-center">
      <img src="/images/Logo.png" className="w-[200px]" alt="" />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitUser)}
          className="max-w-sm w-full space-y-6 text-left"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary capitalize">
                  User Email <span className="text-destructive">*</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder="example@mail.com" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-primary flex justify-between items-center">
                  <span className="flex items-center gap-1">
                    Password <span className="text-destructive">*</span>
                  </span>

                  <Link
                    to="/user/forgot-password"
                    className="text-sm underline text-muted-400"
                  >
                    Forgot password?
                  </Link>
                </FormLabel>
                <FormControl>
                  <PasswordInput placeholder="********" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button disabled={isLoading} type="submit" className="w-full">
            {isLoading ? "Signing in..." : "Sign in"}
          </Button>
        </form>
      </Form>
      <div className="grid gap-2">
        <Link to="/user/sign-up" className="text-primary underline text-sm">
          Don't have an account? Sign up
        </Link>
        {/* <div
          onClick={() => setIsOTP(true)}
          className="text-muted-500 cursor-pointer flex gap-1 justify-center items-center underline text-sm"
        >
          <Key size={15} />
          Verify OTP
        </div> */}
      </div>
    </div>
  );
}
