import React from "react";
import { useIndividualUpdateForm } from "./_hooks";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import ComponentSwitch from "@/components/ui/component-switch";
import { Button } from "@/components/ui/button";

export function GovernmentProfileUpdateForm() {
  const { utils, individual_form } = useIndividualUpdateForm();
  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="flex flex-col gap-10">
        <div className="w-full flex items-center justify-between">
          <h1 className="text-2xl font-medium">
            Below are details of your profile
          </h1>
          <Button
            disabled={utils.isEditing}
            onClick={() => utils.setIsEditing(true)}
            type="button"
          >
            Edit Profile
          </Button>
        </div>
        <ComponentSwitch
          activeKey={individual_form.formPage.toString()}
          components={individual_form.formPages}
        />
      </div>
    </LoaderWrapper>
  );
}
