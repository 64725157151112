import React from "react";
import UserProfiling from "../../profile-management/components/UserProfiling";
import Registrations from "../../profile-management/components/Registrations";
import RevenueOverview from "../../profile-management/components/RevenueOverview";
import { useStore } from "@/contexts/userStore";

export default function ProfileManagementAnalyticsPage() {
  const SECTION_NAME = "Reports";
  const MODULE_NAME = "Profile Management";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (!permission_data || !permission_data.isAllowed || !perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-10">
      {perm?.permissions.includes("view:user-profiling") && <UserProfiling />}
      {perm?.permissions.includes("view:registrations") && <Registrations />}
      {perm?.permissions.includes("view:registrations-revenue-overview") && (
        <RevenueOverview />
      )}
    </div>
  );
}
