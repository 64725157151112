import React from "react";

const ProcessItems = [
  {
    title: "Make Payment",
    description:
      "With lots of unique blocks, you can easily build a page without coding. ",
  },
  {
    title: "Submit Application",
    description:
      "Provide the necessary details about your consignment and submit required documents.",
  },
  {
    title: "Receive Clearance",
    description:
      "Upon approval, download your Environmental Import Clearance Certificate within 48 hours.",
  },
  {
    title: "Verification",
    description:
      "NESREA verifies your documents and checks compliance with regulations.",
  },
];

export default function ClearanceProcess() {
  return (
    <div
      id="clearance"
      className="w-full h-fit md:py-0 py-12 md:h-[90vh] bg-primary-700 flex flex-col gap-16 items-center justify-center relative z-10 text-background"
    >
      <div data-aos="fade-in" className="text-center space-y-5">
        <h1 className="text-4xl font-semibold">Import Clearance Process</h1>
        <p className="opacity-70 text-lg">
          Follow these simple steps to get your import clearance approved by
          NESREA.
        </p>
      </div>
      <div className="grid md:grid-cols-2 gap-10 px-10 md:max-w-[60%] w-full">
        {ProcessItems.map(({ title, description }, index) => (
          <div
            key={title}
            data-aos="fade-in"
            data-aos-delay={`${2 * (index * 100)}`}
            data-aos-anchor-placement="top-bottom"
            className="w-full max-w-[80%] h-[101px] flex gap-2 even:ml-auto"
          >
            <div className="w-[30px]">
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15" cy="15" r="15" fill="currentColor" />
                <path
                  d="M13.1117 19.6923C12.87 19.6923 12.6287 19.6016 12.4442 19.4196L8 15.039L9.33504 13.7226L13.1117 17.4452L20.665 10L22 11.3164L13.7792 19.4196C13.5946 19.6016 13.3534 19.6923 13.1117 19.6923Z"
                  fill="#005C00"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <h1 className="font-semibold text-xl">{title}</h1>
              <p className="opacity-70 text-xs">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
