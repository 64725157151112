import DashboardModal from "@/components/modals/DashboardModal";
import { useStore } from "@/contexts/userStore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WaiversView from "./components/WaiversView";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

export default function WaiversPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userStore = useStore();
  const { registration_status } = userStore.user;

  return registration_status == "verified" ? (
    <div>
      {showModal && (
        <DashboardModal
          title=""
          description="You have no record of any application for import clearance certificate"
          cta={{
            txt: "Apply for Import Clearance Certificate",
            onClick: () => {
              //   navigate("/dashboard/application/add-application");
              setShowModal(false);
            },
          }}
        />
      )}
      <WaiversView />
    </div>
  ) : (
    <></>
  );
}
