import React from "react";
import { DataTable } from "./data-table";
import { ImportDetailsColumns } from "./columns";
import { ImportItem } from "@/lib/interfaces";

export default function ImporterImportDetailsTable({
  importDetails,
  handleImportDetails,
}: {
  importDetails: ImportItem[];
  handleImportDetails: (data: ImportItem) => void;
}) {
  return (
    <div>
      <DataTable
        columns={ImportDetailsColumns}
        data={importDetails}
        importItems={importDetails}
        handleSubmit={handleImportDetails}
      />
    </div>
  );
}
