import SignInForm from "@/components/forms/SigninForm";
import OTP from "@/components/forms/SignupForm/OTP";
import { ChevronLeft } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

export default function SignIn() {
  const [isOTP, setIsOTP] = React.useState(false);
  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 h-screen">
      <div className="relative">
        <div
          className="md:flex hidden absolute top-0 left-0 w-full h-full z-10 brightness-50 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/signup-backdrop.png')` }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col items-center justify-center text-center">
          <h1 className="text-9xl font-bold text-primary-foreground">NESCAP</h1>
          <p className="text-lg font-medium text-primary-foreground">
            NESREA CONFORMITY ASSESSMENT PORTAL
          </p>
          <p className="bg-white rounded-lg p-3 max-w-md text-primary-700 font-medium border-primary-500 border-2 uppercase">
            For further enquiries, visit us at... No. 56 lome crescent, wuse
            zone 7, Abuja, Nigeria. <br />
            Contact us at: <br />
            dg@nesrea.gov.ng, info@nesrea.gov.ng, +2349153993191
          </p>
        </div>
      </div>
      <div className="flex flex-col relative">
        {isOTP ? (
          <div className="w-full h-full max-w-lg m-auto p-12 flex flex-col items-center justify-center gap-7 text-center">
            <img src="/images/Logo.png" className="w-[200px] mb-5" alt="" />
            <div className="w-full flex items-center justify-between">
              <Link
                to="/user"
                onClick={() => setIsOTP(false)}
                className="text-primary underline text-sm flex items-center"
              >
                <ChevronLeft size={15} /> Back to Sign in
              </Link>
              <h1 className="font-medium text-sm">Verify User OTP</h1>
            </div>
            <OTP />
          </div>
        ) : (
          <SignInForm setIsOTP={setIsOTP} />
        )}
      </div>
    </div>
  );
}
