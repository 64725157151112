import React, { useEffect } from "react";
import { government_form_schema_page_2 } from "../_schema";
import { government_form_schema_page_2_type } from "../_schema";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

export const GovernmentSecondForm = ({
  handleSubmit,
}: {
  handleSubmit: (values: government_form_schema_page_2_type) => void;
}) => {
  const form = useForm<government_form_schema_page_2_type>({
    resolver: zodResolver(government_form_schema_page_2),
    defaultValues: {
      department: "",
      association: "",
    },
  });

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);
  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="department"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Department/unit <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="Enter association" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="association"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Association/Nature of Operations
              </FormLabel>
              <FormControl>
                <Input type="text" placeholder="Enter Association" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          className="w-full max-w-[164px]"
          disabled={!form.formState.isValid}
        >
          Next
        </Button>
      </form>
    </Form>
  );
};
