import { z } from "zod";

export const company_form_schema_page_1 = z.object({
  companyName: z.string().min(2, {
    message: "Company Name must be at least 2 characters.",
  }),
  companyRepresentative: z.string().min(2, {
    message: "Company Representative must be at least 2 characters.",
  }),
  companyAddress: z.string().min(2, {
    message: "Company Address must be at least 2 characters.",
  }),
  tin: z.string().min(2, {
    message: "TIN must be at least 2 characters.",
  }),
  association: z.string().optional(),
});

export const company_form_schema_page_2 = z.object({
  association: z.string().optional(),
});

export type company_form_schema_page_1_type = z.infer<
  typeof company_form_schema_page_1
>;
export type company_form_schema_page_2_type = z.infer<
  typeof company_form_schema_page_2
>;
