"use client";

import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChevronLeft, ChevronRight, PlusCircle, Search } from "lucide-react";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { arrayToCSV, cn, getBackendUrl } from "@/lib/utils";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Link } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";
import CSVExport from "@/components/ui/csv-export";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";
import { userTypes } from "@/lib/site-data";
import {
  AdminPermissions,
  AGENT_PERMISSIONS,
  COMPANY_PERMISSIONS,
  GOVERNMENT_PERMISSIONS,
  INDIVIDUAL_PERMISSIONS,
  INITIAL_STATE_PERMISSIONS,
  PERMISSIONS,
} from "@/lib/permissions";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { PasswordInput } from "@/components/ui/password-input";
import { ChartContainer } from "@/components/ui/chart";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { sections } from "@/routes/admin/routes";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { Switch } from "@/components/ui/switch";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

interface Department {
  id: number;
  department_id: string;
  department_name: string;
  department_description: string;
  department_roles: string;
  created_date: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const formSchema = z.object({
    name: z.string().min(1),
    staffid: z.string().min(1),
    department: z.string().min(1),
    password: z.string().min(1),
    email: z.string().email(),
    ops_level: z.string(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      staffid: "",
      department: "",
      email: "",
      password: "",
      ops_level: "1",
    },
  });

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 20,
      },
    },
  });
  const { data: departments, isLoading } = useQuery<Department[]>({
    queryKey: ["all-departments"],
    queryFn: async () => {
      const { data }: { data?: { departments: Department[] } } =
        await axios.get(`${getBackendUrl()}/get-all-department`);

      return data?.departments || [];
    },
  });

  React.useEffect(() => {
    const perms = JSON.parse(
      departments?.find(
        (dept) =>
          dept.department_name.toLowerCase() ===
          form.watch("department").toLowerCase()
      )?.department_roles || "[]"
    );
    if (perms.length) {
      setSelectedPermissions(perms);
    }
  }, [form.watch("department")]);

  const [selectedPermissions, setSelectedPermissions] = React.useState<
    AdminPermissions[]
  >(
    sections.map((s) => ({
      ...s,
      permissions: s.permissions.map((p) => ({
        ...p,
        permissions: [] as string[],
      })),
    }))
    // sections
  );

  const [selectedSection, setSelectedSection] = React.useState(sections[0]);

  const [isActionLoading, setIsActionLoading] = React.useState(false);

  return (
    <div className="rounded-xl border border-neutral-300 overflow-hidden flex flex-col gap-3">
      <div className="">
        <div className="flex items-center justify-between px-6 py-7 pb-0">
          <h4 className="text-lg text-primary-700 font-medium">Manage Role</h4>
          <div className="flex items-center gap-2">
            <CSVExport data={[]} fileName="CSV Data" />
            <Dialog>
              <DialogTrigger
                className={cn(buttonVariants({ variant: "default" }))}
              >
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0013 4.66699V16.3337M4.16797 10.5003H15.8346"
                    stroke="white"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Add
              </DialogTrigger>
              <DialogContent className="w-full min-w-[700px] max-h-[85vh] overflow-y-auto">
                <Form {...form}>
                  <form
                    className="flex flex-col gap-4 pt-7"
                    onSubmit={form.handleSubmit(async (values) => {
                      try {
                        setIsActionLoading(true);
                        await axios.post(`${getBackendUrl()}/store-new-admin`, {
                          name: values.name,
                          staffid: values.staffid,
                          department: values.department,
                          password: values.password,
                          roles: selectedPermissions,
                          ops_level: values.ops_level,
                          email: values.email,
                        });
                        toast.success("Role created successfully");
                        setIsActionLoading(false);
                        form.reset();
                        window.location.reload();
                      } catch (error) {
                        setIsActionLoading(false);
                        toast.error("An error occurred");
                      }
                    })}
                  >
                    <div className="flex w-full justify-between">
                      <h1 className="text-lg font-medium text-primary-700">
                        Create New Admin Role
                      </h1>
                      <Button
                        type="submit"
                        disabled={isActionLoading || isLoading}
                      >
                        Create Admin
                      </Button>
                    </div>
                    <div className="w-full grid grid-cols-2 gap-3">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-2">
                            <FormLabel>Admin Email</FormLabel>
                            <Input {...field} placeholder="Enter Email" />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="ops_level"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Operations Level</FormLabel>
                            <Select
                              value={`${field.value}`}
                              disabled={!departments?.length}
                              onValueChange={(val) => {
                                form.setValue("ops_level", val);
                                field.onBlur();
                              }}
                            >
                              <SelectTrigger className="w-full">
                                <SelectValue placeholder="Select operations level" />
                              </SelectTrigger>
                              <SelectContent>
                                {[1, 2, 3]?.map((level) => (
                                  <SelectItem
                                    key={`${level}_ops_level`}
                                    value={`${level}`}
                                  >
                                    {`Operations Level ${level}`}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="w-full grid grid-cols-2 gap-3">
                      <FormField
                        control={form.control}
                        name="name"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-2">
                            <FormLabel>Admin Name</FormLabel>
                            <Input {...field} placeholder="Enter name" />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="staffid"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-2">
                            <FormLabel>Staff ID</FormLabel>
                            <Input {...field} placeholder="Enter ID" />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="w-full grid grid-cols-2 gap-3">
                      <FormField
                        control={form.control}
                        name="department"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-2">
                            <FormLabel>Department</FormLabel>
                            <FormControl>
                              <Select
                                //   {...field}
                                value={field.value}
                                disabled={!departments?.length}
                                name={field.name}
                                onValueChange={(val) => {
                                  form.setValue("department", val);
                                  field.onBlur();
                                }}
                              >
                                <SelectTrigger className="w-full">
                                  <SelectValue placeholder="Select department" />
                                </SelectTrigger>
                                <SelectContent>
                                  {departments?.map((department) => (
                                    <SelectItem
                                      key={`${department.department_name}department`}
                                      value={department.department_name}
                                    >
                                      {department.department_name}
                                    </SelectItem>
                                  ))}
                                </SelectContent>
                              </Select>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem className="flex flex-col gap-2">
                            <FormLabel>Password</FormLabel>
                            <PasswordInput
                              {...field}
                              placeholder="Enter password"
                            />
                          </FormItem>
                        )}
                      />
                    </div>

                    <Carousel
                      opts={{
                        align: "center",
                        // loop: true,
                        slidesToScroll: 2,
                      }}
                      className="w-full max-w-[630px] h-full flex items-center"
                    >
                      <CarouselPrevious className="border-none p-0 w-fit my-auto h-fit !bottom-0 !left-0 !flex !relative !right-0 !top-0 translate-y-0" />

                      <CarouselContent>
                        {selectedPermissions.map((section, sectionIndex) => (
                          <CarouselItem
                            key={sectionIndex}
                            className="basis-1/4"
                          >
                            <Button
                              variant={
                                selectedSection.name === section.name
                                  ? "default"
                                  : "outline"
                              }
                              className="text-xs w-full text-center border-none"
                              onClick={() => {
                                setSelectedSection(section);
                              }}
                              type="button"
                            >
                              {section.name}
                            </Button>
                          </CarouselItem>
                        ))}
                      </CarouselContent>

                      <CarouselNext className="border-none p-0 w-fit my-auto h-fit !bottom-0 !left-0 !flex !relative !right-0 !top-0 translate-y-0" />
                    </Carousel>

                    <div className="flex flex-col gap-2">
                      <div className="flex items-center gap-10">
                        <h3 className="font-medium text-primary-700">
                          Assign project related task to roles
                        </h3>
                        <div className="flex items-center gap-3">
                          Allow Section
                          <Switch
                            checked={
                              selectedPermissions.find(
                                (p) => p.name === selectedSection.name
                              )?.isAllowed
                            }
                            onCheckedChange={(isChecked) => {
                              sections
                                .find((s) => s.name === selectedSection.name)
                                ?.permissions.map((permission) => {
                                  setSelectedPermissions((prev) => {
                                    return prev.map((p) => {
                                      if (p.name == selectedSection.name) {
                                        return {
                                          ...p,
                                          isAllowed: isChecked as boolean,
                                          permissions: p.permissions.map(
                                            (pitem) => {
                                              if (
                                                pitem.module ===
                                                permission.module
                                              ) {
                                                return {
                                                  ...pitem,
                                                  isAllowed:
                                                    isChecked as boolean,
                                                  permissions: isChecked
                                                    ? permission.permissions
                                                    : [],
                                                };
                                              }
                                              return pitem;
                                            }
                                          ),
                                        };
                                      }
                                      return p;
                                    });
                                  });
                                });

                              setSelectedSection((prev) => {
                                return {
                                  ...prev,
                                  isAllowed: isChecked as boolean,
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-full p-2 bg-secondary-50 grid grid-cols-3">
                        <h1 className="uppercase text-xl font-medium">
                          module
                        </h1>
                        <h1 className="uppercase text-xl font-medium">
                          permissions
                        </h1>
                      </div>
                      {sections
                        .find((s) => s.name === selectedSection.name)
                        ?.permissions.map((permission) => (
                          <div className="w-full p-3 bg-[#F1F1F1] grid grid-cols-3">
                            <div className="flex items-start gap-2 w-full">
                              <Checkbox
                                id={`${permission.module}-${selectedSection.name}`}
                                disabled={
                                  !selectedPermissions.find(
                                    (p) => p.name === selectedSection.name
                                  )?.isAllowed
                                }
                                checked={
                                  selectedPermissions
                                    .find(
                                      (p) => p.name === selectedSection.name
                                    )
                                    ?.permissions.find(
                                      (p) => p.module === permission.module
                                    )?.isAllowed
                                }
                                onCheckedChange={(isChecked) => {
                                  setSelectedPermissions((prev) => {
                                    return prev.map((p) => {
                                      if (p.name === selectedSection.name) {
                                        return {
                                          ...p,
                                          permissions: p.permissions.map(
                                            (pitem) => {
                                              if (
                                                pitem.module ===
                                                permission.module
                                              ) {
                                                return {
                                                  ...pitem,
                                                  isAllowed:
                                                    isChecked as boolean,
                                                  permissions: isChecked
                                                    ? permission.permissions
                                                    : [],
                                                };
                                              }
                                              return pitem;
                                            }
                                          ),
                                        };
                                      }
                                      return p;
                                    });
                                  });
                                }}
                              />
                              <Label
                                className="capitalize cursor-pointer font-medium"
                                htmlFor={`${permission.module}-${selectedSection.name}`}
                              >
                                {permission.module.split("_").join(" ")}
                              </Label>
                            </div>
                            <div className="w-full grid grid-cols-3 gap-3 col-span-2">
                              {permission.permissions.map((perm, index) => (
                                <div className="flex items-start gap-2">
                                  <Checkbox
                                    id={`${perm}-${index}-${permission.module}`}
                                    disabled={
                                      !selectedPermissions
                                        .find(
                                          (p) => p.name === selectedSection.name
                                        )
                                        ?.permissions.find(
                                          (pitem) =>
                                            pitem.module === permission.module
                                        )?.isAllowed
                                    }
                                    checked={selectedPermissions
                                      .find(
                                        (p) => p.name === selectedSection.name
                                      )
                                      ?.permissions.find(
                                        (pitem) =>
                                          pitem.module === permission.module
                                      )
                                      ?.permissions.includes(perm)}
                                    onCheckedChange={(isChecked) => {
                                      setSelectedPermissions((prev) => {
                                        return prev.map((p) => {
                                          if (p.name == selectedSection.name) {
                                            return {
                                              ...p,
                                              permissions: p.permissions.map(
                                                (p2) => {
                                                  if (
                                                    p2.module ===
                                                    permission.module
                                                  ) {
                                                    return {
                                                      ...p2,
                                                      permissions: isChecked
                                                        ? [
                                                            ...p2.permissions,
                                                            perm,
                                                          ]
                                                        : p2.permissions.filter(
                                                            (p) => p !== perm
                                                          ),
                                                    };
                                                  }
                                                  return p2;
                                                }
                                              ),
                                            };
                                          }
                                          return p;
                                        });
                                      });
                                    }}
                                  />
                                  <Label
                                    className="capitalize cursor-pointer text-xs font-normal"
                                    htmlFor={`${perm}-${index}-${permission.module}`}
                                  >
                                    {perm
                                      .split("-")
                                      .join(" ")
                                      .split(":")
                                      .join(" ")}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                    </div>
                  </form>
                </Form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <p className="text-sm text-neutral-500 px-6">Add or remove roles</p>
      </div>
      <div className="border-y border-neutral-300">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow
                className="bg-secondary-50 hover:bg-secondary-50/80"
                key={headerGroup.id}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="text-neutral-600 px-6"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className="border-neutral-300 border-none hover:bg-secondary-50"
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className="border-none px-6 text-sm text-neutral-800 align-top"
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <ChevronLeft size={15} />
        </Button>
        <span className="text-xs">
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </span>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <ChevronRight size={15} />
        </Button>
      </div>
    </div>
  );
}
