import React from 'react'

export default function Road() {
  return (
    <div className="w-full flex relative">
      <div className="w-full gap-2 h-full absolute bottom-0 left-0 z-10 flex flex-col">
        <div className="flex-1 relative animate-bus">
          {/* Animated Bus */}
          <div
            className="aspect-[3.7/1] w-[20%] bg-contain bg-center bg-no-repeat absolute left-20 bottom-5"
            style={{
              backgroundImage: `url('/images/animated-elements/bus-body.png')`,
            }}
          >
            <img
              src="/images/animated-elements/bus-wheel.png"
              className="absolute bottom-0 left-[27%] h-[40%] reverse-spin-wheel"
              alt=""
            />
            <img
              src="/images/animated-elements/bus-wheel.png"
              className="absolute bottom-0 right-[25%] h-[40%] reverse-spin-wheel"
              alt=""
            />
          </div>
        </div>
        <div className="flex-1 relative animate-bus-alt">
          <div
            className="aspect-[3.7/1] w-[15%] bg-contain bg-center bg-no-repeat absolute left-32 bottom-5"
            style={{
              backgroundImage: `url('/images/animated-elements/car-body.png')`,
            }}
          >
            <img
              src="/images/animated-elements/tire.png"
              className="absolute bottom-0 left-[14%] h-[60%] spin-wheel"
              alt=""
            />
            <img
              src="/images/animated-elements/tire.png"
              className="absolute bottom-0 right-[14%] h-[60%] spin-wheel"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="flex w-fit animate-roadb">
        <img
          src="/images/animated-elements/road.png"
          className="min-w-[100vw]"
          alt=""
        />
        <img
          src="/images/animated-elements/road.png"
          className="min-w-[100vw]"
          alt=""
        />
        <img
          src="/images/animated-elements/road.png"
          className="min-w-[100vw]"
          alt=""
        />
        <img
          src="/images/animated-elements/road.png"
          className="min-w-[100vw]"
          alt=""
        />
      </div>
    </div>
  );
}
