import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Check } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "./loader-wrapper";
import { cn, getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { useStore } from "@/contexts/userStore";
import { format } from "fecha";
import { ScrollArea } from "./scroll-area";
import { CheckCircle, MessageCircle, UserCheck } from "lucide-react";
import { Link } from "react-router-dom";

interface Notification {
  id: number;
  sender: string;
  sent_status: string | null;
  receiver: string;
  receive_status: string;
  notification:
    | "CLEARANCE APPROVAL"
    | "REPLY FROM ADMIN"
    | "REGISTRATION APPROVAL";
  created_at: string;
  updated_at: string;
  activities: string | null;
}

interface NotificationsResponse {
  success: boolean;
  notification_count: number;
  all_notifications: Notification[];
}

const notificationIconMap: Record<Notification["notification"], JSX.Element> = {
  "CLEARANCE APPROVAL": <CheckCircle />,
  "REPLY FROM ADMIN": <MessageCircle />,
  "REGISTRATION APPROVAL": <UserCheck />,
};

export default function Notifications() {
  const user = useStore((state) => state.user);
  const isAdmin = user.account_type == "admin";
  const { data, isLoading } = useQuery({
    queryKey: ["notifications"],
    queryFn: async (): Promise<NotificationsResponse["all_notifications"]> => {
      // Fetch Notifications
      const { data }: { data?: NotificationsResponse } = await axios.get(
        isAdmin
          ? `${getBackendUrl()}/admin-notifications`
          : `${getBackendUrl()}/get-notifications/${user.email}`
      );
      return data?.all_notifications || [];
    },
  });
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(isAdmin ? "text-primary-foreground" : "text-primary")}
      >
        <BellIcon hasUnreadMessages={!!data?.length} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-10 mt-5 min-w-[400px] py-5 px-3 pb-0">
        <DropdownMenuLabel className="w-full flex justify-between items-center">
          <h5 className="text-primary-700">Notifications</h5>
          <p className="text-primary-600/50 font-medium text-xs">
            Unread Notifications (0)
          </p>
        </DropdownMenuLabel>
        <hr className="w-full border-t border-slate-500 mt-3" />
        <LoaderWrapper isLoading={isLoading} page={false}>
          <ScrollArea className="h-[40vh]">
            <div className="w-full flex flex-col gap-1 min-h-[50vh]">
              {data?.length ? (
                <>
                  {data?.map((notification) => (
                    <DropdownMenuItem
                      key={`${notification.id} my notif`}
                      className="!hover:bg-transparent"
                    >
                      {isAdmin ? (
                        <Link
                          to={"#"}
                          className="w-full flex items-center gap-2 p-3 hover:bg-muted-100/70 duration-300"
                        >
                          <div className="flex-[0.1] aspect-square rounded-full flex items-center justify-center text-primary-600">
                            {notificationIconMap[notification.notification]}
                          </div>
                          <div className="flex-1 flex-col gap-2">
                            <h4 className="text-sm capitalize">
                              {notification.notification?.toLowerCase()}
                            </h4>
                            <p className="text-xs font-light text-muted-500">
                              {notification.created_at
                                ? format(
                                    new Date(notification.created_at),
                                    "mediumDate"
                                  )
                                : ""}
                            </p>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          to={
                            notification.notification === "REPLY FROM ADMIN"
                              ? `/user/support`
                              : notification.notification ===
                                "CLEARANCE APPROVAL"
                              ? "/user/application"
                              : notification.notification ===
                                "REGISTRATION APPROVAL"
                              ? "/user"
                              : "/user"
                          }
                          className="w-full flex items-center gap-2 p-3 hover:bg-muted-100/70 duration-300"
                        >
                          <div className="flex-[0.1] aspect-square rounded-full flex items-center justify-center text-primary-600">
                            {notificationIconMap[notification.notification]}
                          </div>
                          <div className="flex-1 flex-col gap-2">
                            <h4 className="text-sm capitalize">
                              {notification.notification?.toLowerCase()}
                            </h4>
                            <p className="text-xs font-light text-muted-500">
                              {notification.created_at
                                ? format(
                                    new Date(notification.created_at),
                                    "mediumDate"
                                  )
                                : ""}
                            </p>
                          </div>
                        </Link>
                      )}
                    </DropdownMenuItem>
                  ))}
                </>
              ) : (
                <div className="m-auto text-center opacity-55">
                  <p>No notifications available.</p>
                </div>
              )}
            </div>
          </ScrollArea>
          <div className="w-full mt-auto flex items-center justify-between border-t py-3 border-slate-500">
            <h4 className="font-medium text-primary flex items-center text-xs cursor-pointer">
              <Check size={15} />
              <span>Mark All as Read</span>
            </h4>
          </div>
        </LoaderWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const BellIcon = ({ hasUnreadMessages }: { hasUnreadMessages?: boolean }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0267 17.0997C8.08668 17.0997 6.14663 16.7917 4.3065 16.1755C3.60708 15.9341 3.07419 15.4428 2.84105 14.8017C2.59959 14.1605 2.68285 13.4528 3.06587 12.82L4.0234 11.2296C4.22323 10.8966 4.40641 10.2305 4.40641 9.83914V7.43281C4.40641 4.3354 6.92931 1.8125 10.0267 1.8125C13.1241 1.8125 15.647 4.3354 15.647 7.43281V9.83914C15.647 10.2222 15.8302 10.8966 16.0301 11.238L16.9793 12.82C17.3373 13.4195 17.4039 14.1439 17.1624 14.8017C16.921 15.4595 16.3964 15.959 15.7386 16.1755C13.9068 16.7917 11.9668 17.0997 10.0267 17.0997ZM10.0267 3.06146C7.6204 3.06146 5.65537 5.01816 5.65537 7.43281V9.83914C5.65537 10.447 5.40558 11.3462 5.0975 11.8708L4.13997 13.4611C3.95679 13.7692 3.90683 14.0939 4.01507 14.3687C4.11499 14.6518 4.36478 14.8683 4.70616 14.9848C8.18659 16.1505 11.8752 16.1505 15.3556 14.9848C15.6554 14.8849 15.8885 14.6601 15.9967 14.3604C16.105 14.0606 16.08 13.7359 15.9135 13.4611L14.9559 11.8708C14.6395 11.3296 14.3981 10.4386 14.3981 9.83081V7.43281C14.3981 5.01816 12.4414 3.06146 10.0267 3.06146Z"
        fill="currentColor"
      />
      <path
        d="M11.5745 3.29027C11.5162 3.29027 11.4579 3.28194 11.3996 3.26529C11.1582 3.19868 10.925 3.14872 10.7002 3.11542C9.99248 3.02383 9.30971 3.07378 8.66858 3.26529C8.43544 3.34023 8.18565 3.26529 8.02745 3.09044C7.86925 2.91558 7.81929 2.66579 7.91088 2.44098C8.25226 1.56671 9.0849 0.992188 10.0341 0.992187C10.9833 0.992187 11.816 1.55838 12.1573 2.44098C12.2406 2.66579 12.199 2.91558 12.0408 3.09044C11.9159 3.22366 11.741 3.29027 11.5745 3.29027Z"
        fill="currentColor"
      />
      <path
        d="M10.0267 18.9974C9.20238 18.9974 8.40305 18.6643 7.8202 18.0815C7.23735 17.4986 6.9043 16.6993 6.9043 15.875H8.15326C8.15326 16.3663 8.35309 16.8492 8.7028 17.1989C9.05251 17.5486 9.53544 17.7484 10.0267 17.7484C11.0592 17.7484 11.9001 16.9075 11.9001 15.875H13.1491C13.1491 17.5986 11.7503 18.9974 10.0267 18.9974Z"
        fill="currentColor"
      />
      {hasUnreadMessages && (
        <circle cx="14.1717" cy="4.16979" r="2.49792" fill="#E40101" />
      )}
    </svg>
  );
};
