import React from "react";
import { DataTable } from "./data-table";
import { RegistrationDocumentationColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { FilterData } from "@/components/ui/filter";
import { ImportDocumentationData } from "@/lib/interfaces";

export default function RegistrationDocumentationTable() {
  const { data } = useQuery({
    queryKey: ["registration-documentation"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-all-kyc-document`
      );
      const processedData: ImportDocumentationData = data.kycdocumets.map(
        (item: any) => ({
          id: item.id.toString(),
          category: item.importer_category,
          documents: [
            {
              id: item.id.toString(),
              documentName: item.document_name,
              documentStatus: item.document_status,
              documentCode: item.document_code,
            },
          ],
        })
      );
      return processedData;
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat: any) => filteredIDs.includes(stat.id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item: any) => ({
      id: item.id,
      name: item.name,
      title: item.title,
      date: item.date,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <div>
      <DataTable
        columns={RegistrationDocumentationColumns}
        data={updatedData || []}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
      />
    </div>
  );
}
