import GenerateInvoiceForm from "@/components/forms/GenerateInvoiceForm";
import { useStore } from "@/contexts/userStore";
import React from "react";

export default function GenerateInvoicePage() {
  const SECTION_NAME = "Accounting";
  const MODULE_NAME = "Invoicing";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );
  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (
    !permission_data ||
    !permission_data.isAllowed ||
    !perm?.permissions.includes("action:generate-invoice")
  ) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  return (
    <div>
      <GenerateInvoiceForm />
    </div>
  );
}
