import React from "react";
import Admins from "./_components/Admins";
import UserLoginHeatMap from "./_components/UserLoginHeatMap";
import { useStore } from "@/contexts/userStore";
import AuditTrail from "./_components/audit-trail";

export default function AdminPanelPage() {
  const SECTION_NAME = "Admin Panel";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms.find((perm) => perm.module === SECTION_NAME);
  return (
    <div className="w-full flex flex-col gap-5">
      <Admins />
      <UserLoginHeatMap />
      <AuditTrail />
    </div>
  );
}
