import React, { useEffect, useState } from "react";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ChevronDown, ChevronRight } from "lucide-react";
import { HSCodes } from "@/lib/dummy-data/hsCodes";
import { cn, getBackendUrl } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  countries,
  importationPurposes,
  portsData,
  units,
} from "@/lib/site-data";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import { useAddImportItemForm } from "./_hooks";
import { ImportItem } from "@/lib/interfaces";
import { DialogClose } from "@/components/ui/dialog";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Skeleton } from "@/components/ui/skeleton";
import { DateInput } from "@/components/ui/date-input";
import { ScrollArea } from "@/components/ui/scroll-area";

const ItemSkeleton = () => <Skeleton className="w-full h-[20vh]" />;

const ImportDetailItemForm = ({
  handleSubmit,
  importItems,
  formData,
}: {
  handleSubmit: (data: ImportItem) => void;
  importItems: ImportItem[];
  formData: ReturnType<typeof useAddImportItemForm>;
}) => {
  const { form, hsCode, setHSCode, showDropdown, setShowDropdown } = formData;

  const queryClient = useQueryClient();

  // useEffect(() => {
  //   form.setValue("hsCode", hsCode.map((item) => item.code).join("."));
  // });

  const [availableUnits, setAvailableUnits] = useState<typeof units>([]);
  const [costing, setCosting] = useState<{
    [key in (typeof units)[number]["id"]]?: string;
  }>({});

  const [selectedClassificationId, setSelectedClassificationId] = useState("");
  const { data: classificationsData, isLoading: isClassificationsDataLoading } =
    useQuery({
      queryKey: ["get-classifications"],
      queryFn: async () => {
        const {
          data,
        }: {
          data: {
            importitemsclassifications: {
              id: number;
              classification_name: string;
              hscode: string;
              status: string;
              created_at: string;
              updated_at: string;
            }[];
          };
        } = await axios.get(
          `${getBackendUrl()}/get-import-item-classifications`
        );
        return data.importitemsclassifications;
      },
    });

  // const [selectedCategoryId, setSelectedCategoryId] = useState("");
  // const { data: categoriesData, isLoading: isCategoriesDataLoading } = useQuery(
  //   {
  //     queryKey: ["get-categories"],
  //     queryFn: async () => {
  //       const {
  //         data,
  //       }: {
  //         data: {
  //           category?: {
  //             id: number;
  //             categoryId: string;
  //             categoryLabel: string;
  //             categoryCode: string;
  //             categoryCost: string;
  //             created_at: string;
  //             updated_at: string;
  //             status: null;
  //           }[];
  //         };
  //       } = await axios.get(`${getBackendUrl()}/get-categories`);
  //       const processedData: {
  //         id: number;
  //         categoryId: string;
  //         categoryLabel: string;
  //         categoryCode: string;
  //         categoryCost: {
  //           [key in (typeof units)[number]["id"]]?: string;
  //         };
  //         created_at: string;
  //         updated_at: string;
  //         status: null;
  //       }[] =
  //         data.category?.map((item) => ({
  //           ...item,
  //           categoryCost: JSON.parse(item.categoryCost || "{}"),
  //         })) || [];
  //       return processedData;
  //     },
  //   }
  // );
  // const [categoryData, setCategoryData] = useState(
  //   categoriesData?.find((d) => d.categoryId === selectedCategoryId)
  // );

  // const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  // const {
  //   data: subCategoriesData,
  //   isLoading: isSubCategoriesDataLoading,
  //   isFetching: isSubCategoriesDataFetching,
  // } = useQuery({
  //   queryKey: ["get-sub-categories"],
  //   queryFn: async () => {
  //     if (categoryData?.categoryId) {
  //       const {
  //         data,
  //       }: {
  //         data: {
  //           id: number;
  //           categoryId: string;
  //           subCategoryId: string;
  //           subCategoryLabel: string;
  //           subCategoryCode: string;
  //           subCategoryCost: string;
  //           created_at: string;
  //           updated_at: string;
  //           status: null;
  //         }[];
  //       } = await axios.get(
  //         `${getBackendUrl()}/subcategories/${categoryData.categoryId}`
  //       );
  //       const processedData: {
  //         id: number;
  //         categoryId: string;
  //         subCategoryId: string;
  //         subCategoryLabel: string;
  //         subCategoryCode: string;
  //         subCategoryCost: {
  //           [key in (typeof units)[number]["id"]]?: string;
  //         };
  //         created_at: string;
  //         updated_at: string;
  //         status: null;
  //       }[] =
  //         data?.map((item) => ({
  //           ...item,
  //           subCategoryCost: JSON.parse(item.subCategoryCost || "{}"),
  //         })) || [];
  //       return processedData;
  //     }
  //     return [];
  //   },
  // });
  // const [subCategoryData, setSubCategoryData] = useState(
  //   subCategoriesData?.find((d) => d.subCategoryId === selectedSubCategoryId)
  // );

  // const [selectedItemCategoryId, setSelectedItemCategoryId] = useState("");
  // const {
  //   data: itemCategoriesData,
  //   isLoading: isItemsCategoriesDataLoading,
  //   isFetching: isItemsCategoriesFetching,
  // } = useQuery({
  //   queryKey: ["get-item-categories"],
  //   queryFn: async () => {
  //     if (subCategoryData?.subCategoryId) {
  //       const {
  //         data,
  //       }: {
  //         data: {
  //           id: number;
  //           subCategoryId: string;
  //           itemCategoryId: string;
  //           itemCategoryLabel: string;
  //           itemCategoryCode: string;
  //           itemCategoryCost: string;
  //           created_at: string;
  //           updated_at: string;
  //           status: null;
  //         }[];
  //       } = await axios.get(
  //         `${getBackendUrl()}/itemcategories/${subCategoryData.subCategoryId}`
  //       );
  //       const processedData: {
  //         id: number;
  //         subCategoryId: string;
  //         itemCategoryId: string;
  //         itemCategoryLabel: string;
  //         itemCategoryCode: string;
  //         itemCategoryCost: {
  //           [key in (typeof units)[number]["id"]]?: string;
  //         };
  //         created_at: string;
  //         updated_at: string;
  //         status: null;
  //       }[] =
  //         data?.map((item) => ({
  //           ...item,
  //           itemCategoryCost: JSON.parse(item.itemCategoryCost || "{}"),
  //         })) || [];
  //       return processedData;
  //     }
  //     return [];
  //   },
  // });
  // const [itemCategoryData, setItemCategoryData] = useState(
  //   itemCategoriesData?.find((d) => d.itemCategoryId === selectedItemCategoryId)
  // );

  // const {
  //   data: itemsData,
  //   isLoading: isItemsLoading,
  //   isFetching: isItemsFetching,
  // } = useQuery({
  //   queryKey: ["get-items"],
  //   queryFn: async () => {
  //     if (itemCategoryData?.itemCategoryId) {
  //       const {
  //         data,
  //       }: {
  //         data: {
  //           id: number;
  //           itemCategoryId: string;
  //           itemLabel: string;
  //           itemId: string;
  //           itemCode: string;
  //           itemCost: string;
  //           created_at: string;
  //           updated_at: string;
  //           status: null;
  //         }[];
  //       } = await axios.get(
  //         `${getBackendUrl()}/items/${itemCategoryData.itemCategoryId}`
  //       );

  //       const processedData: {
  //         id: number;
  //         itemCategoryId: string;
  //         itemLabel: string;
  //         itemId: string;
  //         itemCode: string;
  //         itemCost: {
  //           [key in (typeof units)[number]["id"]]?: string;
  //         };
  //         created_at: string;
  //         updated_at: string;
  //         status: null;
  //       }[] =
  //         data.map((item) => ({
  //           ...item,
  //           itemCost: JSON.parse(item.itemCost || "{}"),
  //         })) || [];

  //       return processedData;
  //     }
  //     return [];
  //   },
  // });
  // const [selectedItemID, setSelectedItemID] = useState("");
  // const [itemData, setItemData] = useState(
  //   itemsData?.find((d) => d.itemCategoryId === selectedItemID)
  // );

  // useEffect(() => {
  //   // handleCost(itemData?.itemCost || {});
  //   let queryKey = ["get-sub-categories"];
  //   queryClient.setQueryData(queryKey, []);
  //   queryClient.invalidateQueries({
  //     queryKey,
  //   });
  //   handleCost(categoryData?.categoryCost || {});
  // }, [categoryData]);

  // useEffect(() => {
  //   handleCost(subCategoryData?.subCategoryCost || {});
  //   let queryKey = ["get-item-categories"];
  //   queryClient.setQueryData(queryKey, []);
  //   queryClient.invalidateQueries({
  //     queryKey,
  //   });
  //   handleCost(subCategoryData?.subCategoryCost || {});
  // }, [subCategoryData]);

  // useEffect(() => {
  //   handleCost(itemCategoryData?.itemCategoryCost || {});
  //   let queryKey = ["get-items"];
  //   queryClient.setQueryData(queryKey, []);
  //   queryClient.invalidateQueries({
  //     queryKey,
  //   });
  //   handleCost(itemCategoryData?.itemCategoryCost || {});
  // }, [itemCategoryData]);

  // useEffect(() => {
  //   handleCost(itemData?.itemCost || {});
  // }, [itemData]);

  const handleCost = (itemCost: {
    [key in (typeof units)[number]["id"]]?: string;
  }) => {
    const itemCostKeys = Object.keys(itemCost || {});
    if (itemCostKeys.length == 0) return;
    const newAvailableUnits = units.filter((unit) =>
      itemCostKeys.includes(unit.id)
    );
    // if (itemCost && itemCostKeys.length > 0) {
    // }
    setAvailableUnits(newAvailableUnits || []);
    setCosting(itemCost);
  };

  const getCostValue = (): string => {
    const { unit, quantity }: { unit: any; quantity: any } = form.watch();
    let unitValue: (typeof units)[number]["id"] = unit;
    const costPerUnit = costing?.[unitValue] || "0";
    const totalCost = Number(costPerUnit) * Number(quantity);
    return totalCost.toString();
  };

  useEffect(() => {
    console.log(form.getValues());
  }, [form.getValues()]);

  const selectedClassification = classificationsData?.find(
    (item) => item.classification_name === selectedClassificationId
  );

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-10"
        onSubmit={form.handleSubmit((data) => {
          const hs_codes = importItems.map((item) => item.hscode);
          if (hs_codes.includes(data.hsCode)) {
            toast.error(`${data.hsCode} has already been added.`);
            return;
          }
          const importItem: ImportItem = {
            itemName: data.item,
            hscode: data.hsCode,
            proforma_invoice: data.proformaInvoice,
            unit: data.unit,
            quantity: data.quantity.toString(),
            cargo_number: data.cargoNumber || "",
            country_of_origin: data.countryOfOrigin,
            port_of_entry: data.portOfEntry,
            purpose_of_importation: data.purposeOfImportation,
            final_destination_address: data.finalDestinationAddress,
            expected_date_of_arrival: data.expectedDateofArrival,
            cost: getCostValue(),
            classification: data.classification,
          };
          handleSubmit(importItem);
        })}
      >
        <FormField
          control={form.control}
          name="classification"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3] flex">
                Classification <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("classification", value);
                      setSelectedClassificationId(value);
                      field.onBlur();
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select classification" />
                    </SelectTrigger>
                    <SelectContent>
                      {classificationsData?.map((item) => (
                        <SelectItem
                          key={item.id}
                          value={item.classification_name}
                        >
                          {item.classification_name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="hsCode"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                HS Code <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="text"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    form.setValue("item", e.target.value);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="unit"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Unit <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("unit", value);
                      field.onBlur();
                    }}
                    // disabled={availableUnits.length == 0}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder={"Select unit"} />
                    </SelectTrigger>
                    <SelectContent>
                      {[
                        {
                          id: "kg",
                          label: "Kilograms",
                        },
                        {
                          id: "pcs",
                          label: "Pieces",
                        },
                        {
                          id: "litres",
                          label: "Litres",
                        },
                        {
                          id: "ton",
                          label: "Tonnes",
                        },
                      ].map((unit) => (
                        <SelectItem
                          key={unit.id}
                          className="hover:bg-muted-100 duration-300 cursor-pointer"
                          value={unit.id}
                        >
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="quantity"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Quantity <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="number"
                  {...field}
                  onChange={(e) => {
                    let v = parseInt(e.target.value);
                    if (v < 1 || Number.isNaN(v)) {
                      form.setValue("quantity", 1);
                      return;
                    }

                    form.setValue("quantity", v);
                  }}
                  min={1}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="proformaInvoice"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Proforma Invoice <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="cargoNumber"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Container / Cargo No.
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input type="text" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="countryOfOrigin"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Country of Origin <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("countryOfOrigin", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select country" />
                    </SelectTrigger>
                    <SelectContent>
                      {countries
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem
                            className="hover:bg-muted-100 duration-300 cursor-pointer"
                            key={unit.id}
                            value={unit.label}
                          >
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="portOfEntry"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Port of Entry <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("portOfEntry", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select port of entry" />
                    </SelectTrigger>
                    <SelectContent>
                      {portsData
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map((unit) => (
                          <SelectItem
                            className="hover:bg-muted-100 duration-300 cursor-pointer"
                            key={unit.id}
                            value={unit.label}
                          >
                            {unit.label}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="purposeOfImportation"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Purpose of Importation{" "}
                <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <div>
                  <Select
                    name={field.name}
                    onValueChange={(value) => {
                      form.setValue("purposeOfImportation", value);
                      field.onBlur();
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select purpose of importation" />
                    </SelectTrigger>
                    <SelectContent>
                      {importationPurposes.map((unit) => (
                        <SelectItem
                          className="hover:bg-muted-100 duration-300 cursor-pointer"
                          key={unit.id}
                          value={unit.label}
                        >
                          {unit.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="finalDestinationAddress"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Final Dest. Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                <Input
                  type="text"
                  {...field}
                  placeholder="Enter full address"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="expectedDateofArrival"
          render={({ field }) => (
            <FormItem className="w-full flex gap-2">
              <FormLabel className="flex-[0.3]">
                Expected Date of Arr.{" "}
                <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl className="flex-1 !m-0">
                {/* <Input type="date" {...field} placeholder="DD/MM/YY" /> */}
                <DateInput id="expected-date-of-arr" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="relative w-full pt-7 pb-3 px-5 bg-gradient-to-t from-white via-white/50 to-transparent flex items-center justify-between ">
          <DialogClose
            disabled={!form.formState.isValid}
            type="submit"
            className={cn(
              buttonVariants({ variant: "default" }),
              "w-fit disabled:!cursor-not-allowed",
              "w-full max-w-[164px] disabled:opacity-100 disabled:bg-primary-200 disabled:!cursor-not-allowed"
            )}
          >
            Add Item
          </DialogClose>
          <div className=" h-full flex items-center">
            {/* <h3 className="ml-auto text-xl font-medium">
              Import Item Cost: ₦{Number(getCostValue()).toLocaleString()}
            </h3> */}
          </div>
        </div>
      </form>
    </Form>
  );
};

export default ImportDetailItemForm;
