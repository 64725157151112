import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format } from "fecha";
import { useStore } from "@/contexts/userStore";
import { Link } from "react-router-dom";

export interface Activities {
  title: string;
  name: string;
  date: string;
  status: "NEW" | "Approved" | "Disapproved";
}

export const RecentActivityColumns: ColumnDef<any>[] = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Title
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { title } = row.original;
      return <p className="capitalize">{title.toLowerCase()}</p>;
    },
  },
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { date } = row.original;
      let formattedDate = format(new Date(date), "MMM DD, YYYY");
      return <p>{formattedDate}</p>;
    },
  },
  {
    accessorKey: "comment",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Comment
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },

  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPending = status.toLowerCase() == "Pending".toLowerCase();
      let isApproved = status.toLowerCase() == "Approved".toLowerCase();
      let isRejected = status.toLowerCase() == "Disapproved".toLowerCase();

      const status_dict: { [key: string]: string } = {
        pending: "Pending",
        approved: "Approved",
        disapproved: "Rejected",
      };
      return (
        <p
          className={cn(
            "capitalize",
            isPending && "text-orange-400",
            isApproved && "text-primary-300",
            isRejected && "text-destructive"
          )}
        >
          {status_dict[status.toLowerCase()]}
        </p>
      );
    },
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const userStore = useStore((state) => state.user);
      const data = row.original
      let url = ''

      switch (data.activities) {
        case "Clearance Application":
          url = `/user/application/import-clearance?id=${data?.clearance_id}`
          break;
        default:
          break;
      }
      return !url.trim() ? (
        <Dialog>
          <DialogTrigger className="underline text-primary-700 text-sm">
            View
          </DialogTrigger>
          <DialogContent>
            <div className="flex flex-col gap-10">
              <h4 className="text-lg font-semibold text-neutral-900 text-center">
                View your profile details
              </h4>
              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex items-center justify-between">
                  <h5 className="font-medium text-primary-700">Full Name</h5>
                  <p className="text-neutral-500 font-medium">
                    {userStore.name || "-"}
                  </p>
                </div>
                {userStore.phone_number && (
                  <div className="w-full flex items-center justify-between">
                    <h5 className="font-medium text-primary-700">
                      Phone Number
                    </h5>
                    <p className="text-neutral-500 font-medium">
                      {userStore.phone_number}
                    </p>
                  </div>
                )}
                {userStore.state && (
                  <div className="w-full flex items-center justify-between">
                    <h5 className="font-medium text-primary-700">State</h5>
                    <p className="text-neutral-500 font-medium">
                      {userStore.state}
                    </p>
                  </div>
                )}
                {userStore.street && (
                  <div className="w-full flex items-center justify-between">
                    <h5 className="font-medium text-primary-700">Street</h5>
                    <p className="text-neutral-500 font-medium">
                      {userStore.street}
                    </p>
                  </div>
                )}
                {userStore.association && (
                  <div className="w-full flex items-center justify-between">
                    <h5 className="font-medium text-primary-700">
                      Association
                    </h5>
                    <p className="text-neutral-500 font-medium">
                      {userStore.association}
                    </p>
                  </div>
                )}
                {userStore.nin && (
                  <div className="w-full flex items-center justify-between">
                    <h5 className="font-medium text-primary-700">NIN</h5>
                    <p className="text-neutral-500 font-medium">
                      {userStore.nin}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <Link to={url} className="underline text-primary-700 text-sm">
          View
        </Link>
      );
    },
  },
];
