import React from "react";
import { getBackendUrl } from "@/lib/utils";

import { useStore } from "@/contexts/userStore";

import { useState } from "react";
import { Label } from "./label";
import { cn } from "@/lib/utils";
import { Button, buttonVariants } from "./button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./dialog";
import { PaystackButton } from "react-paystack";
import axios from "axios";
import { toast } from "sonner";

export const PaymentForm = ({
  onSuccess,
  amount,
  email,
  endpoint,
}: {
  onSuccess: () => Promise<void>;
  amount: number;
  email?: string;
  endpoint?: string;
}) => {
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    reference: new Date().getTime().toString(),
    email: userStore.user.email,
    amount: amount * 100, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    publicKey: "pk_test_8a7b7066803d17caed0fc52c5f94b2c0ad4b48ac",
  };
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <Label>
          Registration Fee <span className="text-destructive">*</span>
        </Label>
        <span className="w-full rounded-xl border p-2 text-neutral-500">
          ₦115,000.00
        </span>
      </div>

      <Dialog>
        <DialogTrigger
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-full max-w-[164px]"
          )}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Proceed to Payment"}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Proceed to Payment</DialogTitle>
          </DialogHeader>

          <div className="w-full flex items-center gap-5">
            <DialogClose className="w-fit">
              <PaystackButton
                {...config}
                text="Pay with Paystack"
                className={cn(
                  buttonVariants({ variant: "outline" }),
                  "w-full max-w-[164px]"
                )}
                onSuccess={async (reference) => {
                  try {
                    setIsLoading(true);
                    await axios.post(
                      `${getBackendUrl()}/${
                        endpoint || "importer-registration-payment-response"
                      }`,
                      {
                        reference: `${reference.reference}`,
                        email: email || userStore.user.email,
                        amount: `${115000}`,
                        status: "success",
                        payment_gateway: "Paystack",
                      }
                    );
                    await onSuccess();
                  } catch (error) {
                    toast.error("An error occurred");
                  } finally {
                    setIsLoading(false);
                  }
                }}
              />
            </DialogClose>
            <Button
              disabled
              variant={"outline"}
              className="w-full max-w-[164px]"
            >
              Cheddaz
            </Button>
            <Button
              disabled
              variant={"outline"}
              className="w-full max-w-[164px]"
            >
              Remita
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
