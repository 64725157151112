import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import React from "react";
import InactiveNotifications from "./Notifications";
import { useStore } from "@/contexts/userStore";
import { Link, useLocation } from "react-router-dom";
import { Label } from "@/components/ui/label";
import Notifications from "@/components/ui/Notifications";

export default function DashboardHeader() {
  const userStore = useStore();
  const location = useLocation();
  const isVerified = userStore.user.registration_status == "verified";

  return userStore.user.profile_updated ? (
    <>
      {isVerified ? (
        <></>
      ) : (
        <div className="w-full bg-secondary-50 p-6 flex items-center justify-between">
          <div className="">
            <h1 className="text-2xl text-primary-700 font-medium">
              Profile Update
            </h1>
          </div>
          <div className="flex items-center gap-5">
            <div className="w-[300px] h-11 bg-primary-50 text-primary-300 px-5 py-2 flex items-center rounded-[16px]">
              <Label className="cursor-pointer" htmlFor="searchBar">
                <Search size={20} />
              </Label>
              <Input
                id="searchBar"
                className="bg-transparent placeholder:text-primary-200 border-none outline-none"
                placeholder="search"
              />
            </div>
            <Notifications />
          </div>
        </div>
      )}
    </>
  ) : (
    <div className="w-full bg-secondary-50 p-6 flex items-center justify-between">
      <div className="">
        {location.pathname === "/user/profile" ? (
          <>
            <h1 className="text-2xl text-primary-700 font-medium">
              Profile Update
            </h1>
            <p className="text-primary-300">
              Please update your profile to continue
            </p>
          </>
        ) : (
          <>
            <h1 className="text-2xl text-primary-700 font-medium">
              Profile Update Required
            </h1>
            <Link to="/user/profile" className="text-primary-300 underline">
              Update Profile
            </Link>
          </>
        )}
      </div>
      <div className="flex items-center gap-5">
        <div className="w-[300px] h-11 bg-primary-50 text-primary-300 px-5 py-2 flex items-center rounded-[16px]">
          <Label className="cursor-pointer" htmlFor="searchBar">
            <Search size={20} />
          </Label>
          <Input
            id="searchBar"
            className="bg-transparent placeholder:text-primary-200 border-none outline-none"
            placeholder="search"
          />
        </div>
        <InactiveNotifications />
      </div>
    </div>
  );
}
