import { Button } from "@/components/ui/button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Form,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useStore } from "@/contexts/userStore";
import { logout } from "@/lib/auth";
import { getBackendUrl } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { Key } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const passwordUpdateSchema = z
  .object({
    currentPassword: z
      .string()
      .min(8, "Current password must be at least 8 characters long"),
    newPassword: z
      .string()
      .min(8, "New password must be at least 8 characters long"),
    confirmPassword: z
      .string()
      .min(8, "Confirm password must be at least 8 characters long"),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "New password and confirm password must match",
    path: ["confirmPassword"],
  });

export default function PasswordUpdateForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  type passwordUpdateSchemaType = z.infer<typeof passwordUpdateSchema>;

  const form = useForm<passwordUpdateSchemaType>({
    resolver: zodResolver(passwordUpdateSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const userStore = useStore((state) => state);

  const handlePasswordReset = async (values: passwordUpdateSchemaType) => {
    setIsLoading(true);
    if (values.currentPassword == values.newPassword) {
      setIsLoading(false);
      form.setError("newPassword", {
        message: "New password cannot be the same as the current password",
        type: "manual",
      });
      return;
    }
    try {
      await axios.post(`${getBackendUrl()}/user-login`, {
        email: userStore.user.email,
        password: values.currentPassword,
      });
    } catch (error) {
      setIsLoading(false);
      toast.error("Current password is incorrect");
    }
    try {
      await axios.post(`${getBackendUrl()}/reset-user-password`, {
        email: userStore.user.email,
        newpassword: values.newPassword,
      });

      setIsLoading(false);
      toast.success("User password reset successfully");
      await logout(userStore.clearUser, navigate);
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while resetting user password");
    }
  };
  return (
    <div className="">
      <Form {...form}>
        <form
          className="w-full max-w-xs flex flex-col gap-5"
          onSubmit={form.handleSubmit(handlePasswordReset)}
        >
          <FormField
            control={form.control}
            name="currentPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Current Password</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter your current password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="newPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>New Password</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Enter your new password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Confirm Password</FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Confirm your new password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            type="submit"
            className="bg-primary-500 text-white w-fit"
            disabled={!form.formState.isValid || isLoading}
          >
            {isLoading ? "Updating Password..." : "Update Password"}
          </Button>
        </form>
      </Form>
    </div>
  );
}
