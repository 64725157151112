import React from "react";
import { DataTable } from "./data-table";
import { ApplicationsColumns } from "./columns";
import { useStore } from "@/contexts/userStore";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { AllClearanceApplicationsTableData } from "./_interfaces";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";

export default function ApplicationsTable({ email }: { email?: string }) {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["clearance-applications"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-clearance-applications/${
          email || userStore.email
        }`
      );
      const formattedData: AllClearanceApplicationsTableData[] =
        data?.UserAppList.map((item: any) => ({
          id: item.clearance_id,
          name: item.importer_name,
          category: item.importer_category,
          payment_status: item.payment_status,
          dateCreated: item.created_at,
          status: item.status,
        }));
      return formattedData;
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
    const [filteredData, setFilteredData] = React.useState<FilterData>([]);
    const filteredIDs = filteredData.map((d) => d.id);
  
    const updatedData = React.useMemo(() => {
      return data?.filter((stat) => filteredIDs.includes(stat.id)) || [];
    }, [data, filteredIDs]);
  
    React.useEffect(() => {
      const newData = data?.map((item) => ({
        id: item.id,
        name: item.name,
        date: item.dateCreated,
        status: item.status,
      }));
      setData_(newData || []);
      if (!filteredData.length) {
        setFilteredData(newData || []);
      }
    }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={ApplicationsColumns(email || "")}
        isAdmin={(email?.length || 0) > 0}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
