import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import {
  processClearanceData,
  useClearanceAnalytics,
} from "@/hooks/analytics/useClearanceAnalytics";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { ImportClearanceByUserCategoryChart } from "./ImportClearanceByUserCategoryChart";
import DataFilter, { FilterData } from "@/components/ui/filter";
import { userTypes } from "@/lib/site-data";

export default function ImportClearanceApplicationsByUserCategoryReport() {
  const { data, clearanceData, isLoading } = useClearanceAnalytics([
    "clearanceByUserCategory",
  ]);

  const [data_, setData_] = useState<FilterData>([]);
  const [filteredData, setFilteredData] = useState<FilterData>([]);

  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return processClearanceData(
      clearanceData?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || []
    );
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = clearanceData?.map((data) => ({
      id: `${data.id}`,
      category: data.importer_category.toLowerCase(),
      date: data.created_at || new Date().toISOString(),
      status: data.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);

  const CardStats = [
    // {
    //   title: "Agents",
    //   value: 0,
    // },

    {
      title: "Company",
      value: updatedData?.companyApplications,
    },

    {
      title: "Individual",
      value: updatedData?.individualApplications,
    },
    {
      title: "Government",
      value: updatedData?.governmentApplications,
    },
  ];
  return (
    <LoaderWrapper page={false} isLoading={isLoading}>
      <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-primary-700 text-xl font-medium">
            Clearances by User Category
          </h1>
          {data_.length > 0 && (
            <DataFilter
              data={data_}
              setData={setData_}
              filteredData={filteredData}
              setFilteredData={setFilteredData}
              filterOpts={{
                categoryKeys: userTypes,
              }}
            />
          )}
        </div>

        {data && (
          <div className="w-full grid md:grid-cols-2">
            <ImportClearanceByUserCategoryChart data={data} />
            <div className="grid md:grid-cols-2 gap-5 w-full max-w-[60rem] mx-auto">
              {CardStats.map(({ title, value }, index) => (
                <div
                  key={title}
                  className={cn(
                    "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-full h-full justify-between bg-primary-50 text-primary-700",
                    index === 0 && "bg-primary-700 text-primary-foreground"
                  )}
                >
                  <p className={cn("text-xs ")}>{title}</p>
                  <h4 className="font-bold text-2xl">{value}</h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
}
