import React from "react";
import { DataTable } from "./data-table";
import { InvoiceColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";

export default function GeneratedInvoiceTable() {

  const { data, isLoading } = useQuery({
    queryKey: ["generated-invoices"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-all-generated-invoice`
        );
        const invoice_details = data?.allGeneratedInvoice.map((item: any) => ({
          id: item.id,
          importerName: "",
          importerCategory: "",
          invoiceNumber: item?.invoice_id,
          invoiceDate: item.created_at,
          status: item?.payment_status == null ? "unpaid" : "paid",
        }));
        return invoice_details;
      } catch (error) {}
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={InvoiceColumns} data={data || []} />
    </LoaderWrapper>
  );
}
