import { GeneratedWaiver } from "../interfaces";

export const generatedWaivers: GeneratedWaiver[] = [
  {
    id: "ESR/123/001",
    waiverValue: "5000",
    generatedDate: "2023-10-01",
    generatedBy: "User1",
    status: "Applied",
    waiverType: "Import Clearance Application",
  },
  {
    id: "ESR/123/002",
    waiverValue: "10000",
    generatedDate: "2023-10-02",
    generatedBy: "User2",
    status: "Applied",
    waiverType: "Import Clearance Application",
  },
  {
    id: "ESR/123/003",
    waiverValue: "15000",
    generatedDate: "2023-10-03",
    generatedBy: "User3",
    status: "Unapplied",
    waiverType: "Import Clearance Application",
  },
  {
    id: "ESR/123/004",
    waiverValue: "20000",
    generatedDate: "2023-10-04",
    generatedBy: "User4",
    status: "Applied",
    waiverType: "Import Clearance Application",
  },
  {
    id: "ESR/123/005",
    waiverValue: "25000",
    generatedDate: "2023-10-05",
    generatedBy: "User5",
    status: "Unapplied",
    waiverType: "Import Clearance Application",
  },
];
