import React, { useEffect, useState } from "react";
import { CheckCircle, ChevronLeft, XCircle } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PasswordInput } from "@/components/ui/password-input";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { toast } from "sonner";
import { cn, getBackendUrl } from "@/lib/utils";

const passwordUpdateSchema = z.object({
  email: z.string().email("Invalid email address"),
});

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(false);
  const form = useForm<z.infer<typeof passwordUpdateSchema>>({
    resolver: zodResolver(passwordUpdateSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof passwordUpdateSchema>) => {
    setIsLoading(true);
    try {
      await axios.post(`${getBackendUrl()}/reset-user-password`, {
        email: values.email,
      });
      toast.success("Password reset email sent", {
        description: <>Please check your email</>,
      });
      navigate("/user");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred while resetting user password");
    }
  };

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  return (
    <div className="w-full grid md:grid-cols-2 grid-cols-1 min-h-screen">
      <div className="relative">
        <div
          className="md:flex hidden absolute top-0 left-0 w-full h-full z-10 brightness-50 bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url('/images/signup-backdrop.png')` }}
        ></div>
        <div className="w-full h-full z-20 relative flex flex-col items-center justify-center text-center">
          <h1 className="text-9xl font-bold text-primary-foreground">NESCAP</h1>
          <p className="text-lg font-medium text-primary-foreground">
            NESREA CONFORMITY ASSESSMENT PORTAL
          </p>
          <p className="bg-white rounded-lg p-3 max-w-md text-primary-700 font-medium border-primary-500 border-2 uppercase">
            For further enquiries, visit us at... No. 56 lome crescent, wuse
            zone 7, Abuja, Nigeria. <br />
            Contact us at: <br />
            dg@nesrea.gov.ng, info@nesrea.gov.ng, +2349153993191
          </p>
        </div>
      </div>
      <div className="flex flex-col relative">
        <div className="w-full h-full max-w-lg m-auto p-12 flex flex-col items-center justify-center gap-7">
          <img src="/images/Logo.png" className="w-[200px] mb-5" alt="" />
          <div className="w-full flex items-center justify-between">
            <Link
              to="/user"
              className="text-primary underline text-sm flex items-center"
            >
              <ChevronLeft size={15} /> Back to Sign in
            </Link>
            <h1 className="font-medium text-sm">Reset User Password</h1>
          </div>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="w-full flex flex-col gap-4"
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>User Email</FormLabel>
                    <FormControl>
                      <Input placeholder="example@email.com" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                disabled={loading || !form.formState.isValid}
              >
                {loading ? "Updating..." : "Update Password"}
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
