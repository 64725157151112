import UserCategoryTable from "@/components/tables/UserCategoryTable";
import { useStore } from "@/contexts/userStore";
import { useUserAnalytics } from "@/hooks/analytics/useUserAnalytics";
import { cn } from "@/lib/utils";
import { Eye } from "lucide-react";
import React, { useState } from "react";

export default function UserProfilingPage() {
  const SECTION_NAME = "Profile Management";
  const MODULE_NAME = "User Profiling";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );
  
  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (!permission_data || !permission_data.isAllowed || !perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }


  const data = useUserAnalytics();
  const UserCategories: {
    id: "company" | "individual" | "agent" | "government";
    title: string;
    value: number;
  }[] = [
    {
      id: "company",
      title: "All Companies",
      value:
       data?.users_count.TOTAL_COMPANIES -
        data?.registration_status.TOTAL_PENDING_COMPANIES || 0,
    },
    {
      id: "individual",
      title: "All Individuals",
      value:
        data?.users_count.TOTAL_INDIVIDUALS -
        data?.registration_status.TOTAL_PENDING_INDIVIDUALS || 0,
    },
    {
      id: "government",
      title: "All Government Institutions",
      value:
        data?.users_count.TOTAL_GOVERNMENT -
        data?.registration_status.TOTAL_PENDING_GOVERNMENT || 0,
    },
    {
      id: "agent",
      title: "All Agents",
      value:
        data?.users_count.TOTAL_AGENTS -
        data?.registration_status.TOTAL_PENDING_AGENTS || 0,
    },
  ];
  const [activeCategory, setActiveCategory] = useState<
    "agent" | "individual" | "company" | "government"
  >(UserCategories[0].id);
  return (
    <div className="flex flex-col gap-10">
      <div className="w-full grid grid-cols-4 gap-10">
        {UserCategories.map(({ title, value, id }) => (
          <div
            key={id}
            className={cn(
              "flex flex-col gap-3 p-5 rounded-xl flex-1 duration-500 min-w-[200px] max-w-[250px] h-full justify-between bg-primary-50 text-primary-700 shadow-lg border-primary-700 border cursor-pointer",
              activeCategory == id && "bg-primary-700 text-primary-foreground",
              activeCategory != id && "hover:scale-105"
            )}
            onClick={() => setActiveCategory(id)}
          >
            <p className={cn("text-xs flex items-center gap-1")}>
              <Eye size={15} />
              {title}
            </p>
            <h4 className="font-bold text-2xl">{value}</h4>
          </div>
        ))}
      </div>
      <UserCategoryTable category={activeCategory} />
    </div>
  );
}
