import React, { useEffect, useState } from "react";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import { Checkbox } from "@/components/ui/checkbox";

import { Button, buttonVariants } from "./button";
import { cn, getMinMax, getMinMaxDates } from "@/lib/utils";
import { Search, X } from "lucide-react";
import { Input } from "./input";
import { Label } from "./label";
import { ScrollArea } from "./scroll-area";
import { DateInput } from "./date-input";

export type FilterData = {
  id: string;
  date?: string;
  value?: number;
  [key: string]: any;
}[];
interface FilterProps {
  data: FilterData;
  setData: React.Dispatch<React.SetStateAction<FilterData>>;
  filterOpts?: {
    categoryKeys?: string[];
    searchKeys?: string[];
  };
  setFilteredData: React.Dispatch<React.SetStateAction<FilterData>>;
  filteredData: FilterData;
}

export default function DataFilter({
  data,
  setData,
  setFilteredData,
  filteredData,
  filterOpts,
}: FilterProps) {
  const [searchValue, setSearchValue] = useState("");

  //   const [category, setCategory] = useState("");
  const [categories, setCategories] = useState<string[]>(
    filterOpts?.categoryKeys || []
  );

  const { min, max } = getMinMax(data.map((item) => item.value || 0));
  const dates = data
    .map((item) => item.date || "")
    .filter((date) => date.length > 0);
  const { maxDate, minDate } = getMinMaxDates(
    dates.length > 0 ? dates : [new Date().toISOString()]
  );
  const [dateRange, setDateRange] = useState<[string, string]>([
    minDate.toISOString(),
    maxDate.toISOString(),
  ]);
  const [valueRange, setValueRange] = useState<[number, number]>([min, max]);

  const filterData = () => {
    let filtered = data;

    // Filter by search value
    if (searchValue) {
      filtered = filtered.filter((item) => {
        return Object.values(item).some((val) => {
          console.log(
            val,
            "val",
            val?.toString().toLowerCase(),
            val?.toString().toLowerCase().includes(searchValue.toLowerCase()),
            searchValue.toLowerCase(),
          );
          return val
            ?.toString()
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
      });
    }
    console.log(filtered, "filtered_by_search");
    if (categories.length) {
      filtered = filtered.filter((item) =>
        categories.some((category) =>
          Object.values(item).some((val) => {
            return val
              ?.toString()
              .toLowerCase()
              .includes(category.toLowerCase());
          })
        )
      );
    }
    console.log(filtered, "filtered_by_categories");

    // Filter by date range
    if (dateRange[0] && dateRange[1]) {
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item.date?.split("T")[0] || "").getTime();
        const fromDate = new Date(dateRange[0]).getTime();
        const toDate = new Date(dateRange[1]).getTime();
        return fromDate == toDate
          ? itemDate >= fromDate
          : itemDate >= fromDate && itemDate <= toDate;
      });
    }
    console.log(filtered, "filtered_by_date");

    // Filter by value range
    filtered = filtered.filter(
      (item) =>
        (item.value || 0) >= valueRange[0] && (item.value || 0) <= valueRange[1]
    );
    console.log(filtered, "filtered_by_value");
    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setSearchValue("");
    setDateRange([minDate.toISOString(), maxDate.toISOString()]);
    setValueRange([min, max]);
    setFilteredData(data);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          buttonVariants({ variant: "default" }),
          "bg-transparent text-primary-700 hover:text-primary-foreground"
        )}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Filters
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mr-10 min-w-[20vw] relative p-0 border-none shadow-[-2px_2.5px_10px_rgba(0,0,0,0.15)]">
        {/* HEADING */}
        <div className="flex items-center justify-between p-3 border-b border-primary-900/30 relative">
          <div className="absolute top-0 left-0 h-full w-[20%] border-t-2 border-l-2 border-primary-700 rounded-tl-xl"></div>
          <p className="font-xs text-primary-500">Filter</p>
          <DropdownMenuItem className="cursor-pointer hover:!bg-transparent focus:!bg-transparent !p-0">
            <X />
          </DropdownMenuItem>
        </div>
        {/* {minDate.toISOString()}{"minDate"}
        {maxDate.toISOString()}{"maxDate"} */}
        {/* {max} {min} */}
        <ScrollArea className="h-[30vh]">
          <div className="flex flex-col gap-5 p-3 pr-5">
            {/* SEARCH */}
            <KeywordSearch search={searchValue} setSearch={setSearchValue} />

            {/* DATE RANGE */}
            <DateRangeSelector
              setRange={setDateRange}
              range={dateRange}
              maxDate={maxDate.toISOString()}
              minDate={minDate.toISOString()}
            />

            {/* CATEGORY */}
            {/* <CategorySelector /> */}

            {/* CATEGORIES */}
            {filterOpts?.categoryKeys?.length ? (
              <CategoriesSelector
                categories={categories}
                setCategories={setCategories}
                keys={filterOpts.categoryKeys}
              />
            ) : (
              <></>
            )}

            {/* RANGE */}
            {max || min ? (
              <RangeSelector
                valueRange={valueRange}
                setValueRange={setValueRange}
                min={min}
                max={max}
                onReset={() => setValueRange([min, max])}
              />
            ) : (
              <></>
            )}
          </div>
        </ScrollArea>
        <div className="flex items-center justify-between mt-5 p-3">
          <DropdownMenuItem
            onClick={resetFilters}
            tabIndex={-1}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "cursor-pointer"
            )}
          >
            Reset
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={filterData}
            tabIndex={-1}
            className={cn(
              buttonVariants({ variant: "default" }),
              "cursor-pointer hover:!bg-primary-700/90 hover:!text-primary-foreground"
            )}
          >
            Apply Now
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

const DateRangeSelector = ({
  range,
  setRange,
  maxDate,
  minDate,
}: {
  setRange: React.Dispatch<React.SetStateAction<[string, string]>>;
  range: [string, string];
  maxDate: string;
  minDate: string;
}) => {
  const [fromDate, setFromDate] = useState(range[0].split("T")[0]);
  const [toDate, setToDate] = useState(range[1].split("T")[0]);
  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex items-center justify-between text-xs text-muted-400">
        <h5 className="font-medium">Date Range</h5>
        <Button
          variant={"outline"}
          className="border-none hover:!bg-transparent p-0 h-fit text-primary-700 text-xs"
        >
          Reset
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-1">
          <h6 className="text-xs">From:</h6>
          <DateInput
            id="from-filter-date"
            value={fromDate}
            min={minDate}
            max={maxDate}
            onChange={(e) => {
              setFromDate(e.target.value);
              setRange([e.target.value, toDate]);
            }}
          />
        </div>
        <div className="flex flex-col gap-1">
          <h6 className="text-xs">To:</h6>
          <DateInput
            id="to-filter-date"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
              setRange([fromDate, e.target.value]);
            }}
            min={fromDate}
            max={maxDate}
          />
        </div>
      </div>
    </div>
  );
};

const CategorySelector = () => {
  const [category, setCategory] = useState("");
  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex items-center justify-between text-xs text-muted-400">
        <h5 className="font-medium">Category</h5>
        <Button
          variant={"outline"}
          className="border-none hover:!bg-transparent p-0 h-fit text-primary-700 text-xs"
        >
          Reset
        </Button>
      </div>

      <Select
        onValueChange={(value) => {
          setCategory(value);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Category" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="light">Light</SelectItem>
          <SelectItem value="dark">Dark</SelectItem>
          <SelectItem value="system">System</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};

const CategoriesSelector = ({
  categories,
  setCategories,
  keys,
}: {
  categories: string[];
  setCategories: React.Dispatch<React.SetStateAction<string[]>>;
  keys: string[];
}) => {
  const handleReset = () => {
    setCategories([]);
  };
  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex items-center justify-between text-xs text-muted-400">
        <h5 className="font-medium">Categories</h5>
        <Button
          onClick={handleReset}
          variant={"outline"}
          className="border-none hover:!bg-transparent p-0 h-fit text-primary-700 text-xs"
        >
          Reset
        </Button>
      </div>

      <div className="flex gap-3 flex-wrap">
        {keys.map((category, index) => (
          <div
            key={`${category} ${index} filter categories`}
            className="flex items-center gap-1 text-muted-600 font-medium"
          >
            <Checkbox
              checked={categories.includes(category)}
              onCheckedChange={(checked) => {
                if (checked) {
                  setCategories([...categories, category]);
                } else {
                  setCategories(categories.filter((c) => c !== category));
                }
              }}
              key={category}
              id={category}
            />
            <Label htmlFor={category} className="capitalize cursor-pointer">
              {`${category}`.toLowerCase()}
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
};

const RangeSelector = ({
  valueRange,
  setValueRange,
  max: maxVal,
  min: minVal,
  onReset,
}: {
  valueRange: [number, number];
  setValueRange: React.Dispatch<React.SetStateAction<[number, number]>>;
  min: number;
  max: number;
  onReset: () => void;
}) => {
  // const [min, setMin] = useState(minVal);
  // const [max, setMax] = useState(maxVal);

  const [sliderOneValue, setSliderOneValue] = useState(valueRange[0]);
  const [sliderTwoValue, setSliderTwoValue] = useState(valueRange[1]);

  const resetRange = () => {
    // setMin(minVal);
    // setMax(maxVal);
    setSliderOneValue(minVal);
    setSliderTwoValue(maxVal);
    onReset();
  };

  useEffect(() => {
    setSliderOneValue(valueRange[0]);
    setSliderTwoValue(valueRange[1]);
  }, [valueRange]);

  const handleSliderOneChange = (value: number) => {
    if (value > sliderTwoValue) {
      // Prevent sliderOne from crossing sliderTwo
      setSliderOneValue(sliderTwoValue);
      // setMin(sliderTwoValue);
      setValueRange([sliderTwoValue, value]);
    } else {
      setSliderOneValue(value);
      setValueRange([value, sliderTwoValue]);
      // setMin(value);
    }
  };

  const handleSliderTwoChange = (value: number) => {
    if (value < sliderOneValue) {
      // Prevent sliderTwo from crossing sliderOne
      setSliderTwoValue(sliderOneValue);
      setValueRange([value, sliderOneValue]);
      // setMax(sliderOneValue);
    } else {
      setSliderTwoValue(value);
      setValueRange([sliderOneValue, value]);
      // setMax(value);
    }
  };

  const handleInputMinChange = (value: number) => {
    if (value > sliderTwoValue) {
      // Prevent min from exceeding sliderTwo
      setSliderOneValue(sliderTwoValue);
      //   setValueRange([sliderTwoValue, sliderTwoValue]);
      // setMin(sliderTwoValue);
    } else {
      setSliderOneValue(value);
      //   setValueRange([value, sliderTwoValue]);
      // setMin(value);
    }
  };

  const handleInputMaxChange = (value: number) => {
    if (value < sliderOneValue) {
      // Prevent max from dropping below sliderOne
      setSliderTwoValue(sliderOneValue);
      //   setValueRange([sliderOneValue, sliderOneValue]);
      // setMax(sliderOneValue);
    } else {
      setSliderTwoValue(value);
      //   setValueRange([sliderOneValue, value]);
      // setMax(value);
    }
  };

  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex items-center justify-between text-xs text-muted-400">
        <h5 className="font-medium">Value Range</h5>
        <Button
          variant={"outline"}
          onClick={resetRange}
          className="border-none hover:!bg-transparent p-0 h-fit text-primary-700 text-xs"
        >
          Reset
        </Button>
      </div>

      <div className="flex items-center relative">
        <Input
          type="range"
          onChange={
            sliderOneValue <= sliderTwoValue
              ? (e) => handleSliderOneChange(parseInt(e.target.value))
              : (e) => handleSliderTwoChange(parseInt(e.target.value))
          }
          value={sliderOneValue}
          min={minVal}
          max={maxVal}
          className="dual-range dual-range-from z-20 !relative h-0"
        />
        <Input
          type="range"
          onChange={
            sliderTwoValue >= sliderOneValue
              ? (e) => handleSliderTwoChange(parseInt(e.target.value))
              : (e) => handleSliderOneChange(parseInt(e.target.value))
          }
          value={sliderTwoValue}
          min={minVal}
          max={maxVal}
          className="dual-range"
        />
      </div>
      <div className="flex items-center gap-3">
        <Input
          type="number"
          value={Math.min(sliderOneValue, sliderTwoValue)}
          onChange={(e) =>
            handleInputMinChange(parseInt(e.target.value || "0"))
          }
        />
        <span>-</span>
        <Input
          type="number"
          value={Math.max(sliderOneValue, sliderTwoValue)}
          onChange={(e) =>
            handleInputMaxChange(parseInt(e.target.value || "0"))
          }
        />
      </div>
    </div>
  );
};

const KeywordSearch = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className="w-full flex flex-col gap-3">
      <div className="w-full flex items-center justify-between text-xs text-muted-400">
        <h5 className="font-medium">Search</h5>
        <Button
          variant={"outline"}
          onClick={() => setSearch("")}
          className="border-none hover:!bg-transparent p-0 h-fit text-primary-700 text-xs"
        >
          Reset
        </Button>
      </div>

      <div className="border rounded-lg p-2 flex items-center gap-2 relative">
        {/* <Label
          htmlFor="search"
          className="absolute top-0 w-full right-0 h-full cursor-text"
        ></Label> */}
        <Search />
        <Input
          placeholder="Search"
          id="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="bg-transparent border-none active:border-none focus:border-none focus:outline-none p-0 rounded-none h-fit"
        />
      </div>
    </div>
  );
};
