import React from "react";
import { DataTable } from "./data-table";
import { RegistrationsColumns } from "./columns";
import { useStore } from "@/contexts/userStore";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { FilterData } from "@/components/ui/filter";

export default function AgentsRegistrationsTable({
  permissions,
}: {
  permissions?: string[];
}) {
  const user = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["agents-registrations"],
    queryFn: async () => {
      const {
        data,
      }: {
        data: {
          allagent: {
            id: number;
            agent_id: string;
            agent_name: string;
            phone_number: string;
            email: string;
            country: string;
            state: string;
            lga: string;
            street_address: string;
            association: string | null;
            national_identity_number: string;
            payment_id: string | null;
            amount: string | null;
            payment_status: string | null;
            document_url: string | null;
            document_name: string | null;
            actions: string | null;
            comment: string | null;
            confim_by: string | null;
            confirm_date: string | null;
            created_by: string;
            activities: string | null;
            reference: null;
            registration_status: string;
            action_taken_registration: string | null;
            registration_approved_by: string | null;
            registration_approval_date: string | null;
            registration_approval_comment: string | null;
            created_at: string;
            updated_at: string;
            action_taken_payment: string | null;
            payment_approved_by: string | null;
            payment_approval_date: string | null;
            payment_approval_comment: string | null;
          }[];
        };
      } = await axios.get(
        `${getBackendUrl()}/all-registered-agent-level/${user.ops_level}`
      );
      return data.allagent
        .filter((agent) => agent.payment_status !== null)
        .sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const statusMap = {
      approved: "approved",
      disapproved: "rejected",
      new: "pending",
    };
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.agent_name,
      date: item.created_at,
      status:
        statusMap[
          item.registration_status?.toLowerCase() as
            | "approved"
            | "disapproved"
            | "new"
        ],
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);

  return (
    <div>
      <DataTable
        columns={RegistrationsColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
        permissions={permissions}
      />
    </div>
  );
}
