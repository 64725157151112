import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../ui/Footer";
import Navbar from "../ui/Navbar";
import aos from "aos";

export const PublicLayout = () => {
  useEffect(() => {
    aos.init({
      duration: 700,
      once: true,
    });
  }, []);
  return (
    <div className="overflow-x-hidden">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};
