import { z } from "zod";

export const CompanyUpdateFormSchema = z.object({
  name: z.string().min(1),
  representative: z.string().min(1),
  address: z.string().min(1),
  tin_cac: z.string().min(1),
});

export type CompanyUpdateFormType = z.infer<typeof CompanyUpdateFormSchema>;
