import { ChartConfig, ChartTooltipContent } from "@/components/ui/chart";
import { ChartTooltip } from "@/components/ui/chart";
import { ChartContainer } from "@/components/ui/chart";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { cn } from "@/lib/utils";
import React, { useState } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

const filters = [
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
  },
  {
    title: "Monthly",
    value: "monthly",
  },
  {
    title: "Quarterly",
    value: "quarterly",
  },
  {
    title: "Annually",
    value: "annually",
  },
];

const chartConfig = {
  desktop: {
    label: "trend",
    color: "rgb(var(--primary))",
  },
} satisfies ChartConfig;

const tickData = { stroke: "#A1A1A1", strokeWidth: 0.25, fontSize: 11 };
export default function Invoicing() {
  const dataAnalytics = useClearanceAnalytics();
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const monthName = new Date(0, currentMonth).toLocaleString("default", {
    month: "long",
  });
  const activeData =
    dataAnalytics.data?.analyticsByYearMonth[currentYear] ?? {};
  const chartData = Object.keys(activeData).map((month) => ({
    month: month.toUpperCase(),
    issued: activeData[month].approvedApplications,
    paid: activeData[month].successfulPayments,
    fill: "rgb(var(--primary)",
  }));
  // const chartData = [
  //   { month: "January", issued: 150, paid: 120 },
  //   { month: "February", issued: 200, paid: 180 },
  //   { month: "March", issued: 180, paid: 160 },
  //   { month: "April", issued: 90, paid: 70 },
  //   { month: "May", issued: 210, paid: 200 },
  //   { month: "June", issued: 220, paid: 210 },
  //   { month: "July", issued: 160, paid: 150 },
  //   { month: "August", issued: 310, paid: 290 },
  //   { month: "September", issued: 210, paid: 200 },
  //   { month: "October", issued: 280, paid: 250 },
  //   { month: "November", issued: 240, paid: 230 },
  //   { month: "December", issued: 200, paid: 190 },
  // ];
  return (
    <div className="w-full p-5 flex flex-col gap-5 shadow-[0_4px_20px_0px_#0000001A] rounded-xl">
      <div className="flex justify-between items-center">
        <h4 className="font-semibold text-primary-700 flex">Invoicing</h4>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="flex items-center gap-2 text-primary-700 font-semibold">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <p>Filters</p>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex flex-col gap-5 min-w-[250px] p-3">
            <div className="flex items-center gap-3 w-full">
              <h3 className="flex-[0.5]">Year</h3>
              <Select
                value={`${currentYear}`}
                onValueChange={(value) => {
                  setCurrentYear(Number(value));
                }}
              >
                <SelectTrigger className="flex-1">
                  <div className="flex items-center gap-2 text-primary-700 font-semibold">
                    <SelectValue placeholder="Filters" />
                  </div>
                </SelectTrigger>
                <SelectContent className="flex-1">
                  {Object.keys(
                    dataAnalytics.data?.analyticsByYearMonth || {}
                  ).map((filter) => (
                    <SelectItem key={filter} value={filter}>
                      {filter}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {/* <div className="flex items-center gap-3 w-full">
              <h3 className="flex-[0.5]">Month</h3>
              <Select
                value={currentMonth.toString()}
                onValueChange={(value) => {
                  setCurrentMonth(Number(value));
                }}
              >
                <SelectTrigger className="flex-1">
                  <div className="flex items-center gap-2 text-primary-700 font-semibold">
                    <SelectValue placeholder="Filters" />
                  </div>
                </SelectTrigger>
                <SelectContent className="flex-1">
                  {Array.from({ length: 12 }, (_, i) =>
                    new Date(0, i).toLocaleString("default", { month: "long" })
                  ).map((filter, index) => (
                    <SelectItem key={filter} value={`${index}`}>
                      {filter}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div> */}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className="flex gap-5">
        <div className="flex-1 flex flex-col gap-5">
          <ChartContainer config={chartConfig}>
            <LineChart
              accessibilityLayer
              data={chartData}
              margin={{
                top: 10,
                left: 12,
                right: 12,
              }}
            >
              <CartesianGrid strokeWidth={0.25} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tick={tickData}
                axisLine={false}
                label={{
                  value: "Months",
                  position: "insideBottom",
                  offset: 1,
                  stroke: "green",
                  strokeWidth: 0.5,
                }}
                style={{
                  fill: "black",
                }}
                tickFormatter={(value) => value.slice(0, 3)}
              ></XAxis>
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={0}
                tick={tickData}
                label={{
                  value: "Number of Invoices",
                  angle: -90,
                  position: "center",
                  offset: 1,
                  stroke: "green",
                  strokeWidth: 0.5,
                }}
                style={{
                  fill: "black",
                }}
              />

              <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
              <Line
                dataKey="issued"
                type="linear"
                stroke="rgb(var(--primary))"
                strokeWidth={2}
                dot={{
                  fill: "rgb(var(--primary))",
                }}
              />
              <Line
                dataKey="paid"
                type="linear"
                stroke="rgb(var(--secondary))"
                strokeWidth={2}
                dot={{
                  fill: "rgb(var(--secondary))",
                }}
              />
            </LineChart>
          </ChartContainer>
          <div className="flex flex-col gap-2 w-full max-w-xs mx-auto">
            <div className="flex items-center gap-3 w-fit justify-between">
              <div className="rounded-full w-[15px] h-[15px] bg-primary-700"></div>
              <p className="text-primary-700 text-sm">Invoice Issued</p>
            </div>
            <div className="flex items-center gap-3 w-fit justify-between">
              <div className="rounded-full w-[15px] h-[15px] bg-secondary"></div>
              <p className="text-primary-700 text-sm">Invoice Paid</p>
            </div>
          </div>
        </div>
        <div className="flex-[0.7]">
          <div className="flex flex-wrap gap-5 items-center justify-center my-auto h-fit">
            <div
              className={cn(
                "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-700 text-primary-foreground"
              )}
            >
              <p className={cn("text-xs ")}>Total Number of Paid Invoices</p>
              <h4 className="font-bold text-2xl">
                {dataAnalytics.data?.successfulPayments || 0}
              </h4>
            </div>
            <div
              className={cn(
                "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-50 text-primary-700"
              )}
            >
              <p className={cn("text-xs ")}>
                Total Number of Generated Invoices
              </p>
              <h4 className="font-bold text-2xl">
                {dataAnalytics.data?.approvedApplications || 0}
              </h4>
            </div>
            <div
              className={cn(
                "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-fit justify-between bg-primary-700 text-primary-foreground"
              )}
            >
              <p className={cn("text-xs ")}>Total Number of Unpaid Invoices</p>
              <h4 className="font-bold text-2xl">
                {Number(dataAnalytics.data?.approvedApplications || "0") -
                  Number(dataAnalytics.data?.successfulPayments || "0") <
                0
                  ? 0
                  : Number(dataAnalytics.data?.approvedApplications || "0") -
                    Number(dataAnalytics.data?.successfulPayments || "0")}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
