import { userStoreInitialState, useStore } from "@/contexts/userStore";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { getBackendUrl } from "@/lib/utils";
import { company_form_schema_page_1_type } from "./_schema";
import axios from "axios";
import { CompanyFirstForm } from "./_form-pages/first_form_page";
import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { PaymentForm } from "@/components/ui/payment-section";

export const useCompanyForm = () => {
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState({
    companyName: "",
    companyRepresentative: "",
    companyAddress: "",
    tin_cac: "",
    association: "",
    nin: "",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.user.profile_updated) {
      setFormPage(2);
    }
  }, []);

  const firstFormHandleSubmit = async (
    values: company_form_schema_page_1_type
  ) => {
    setIsLoading(true);
    try {
      setFormData((prev) => ({ ...prev, ...values }));
      const company_profile_data = {
        account_type: "company",
        email: userStore.user.email,
        importer_name: values.companyName,
        street: values.companyAddress,
        tin_cac: values.tin,
        created_by: userStore.user.email,
      };
      await axios.post(
        `${getBackendUrl()}/store-importer-information`,
        company_profile_data
      );
      setFormPage((prev) => prev + 1);
      setIsLoading(false);

      const updated_user: userStoreInitialState["user"] = {
        ...userStore.user,
        profile_updated: true,
        name: company_profile_data?.importer_name || "",
        street: company_profile_data?.street || "",
        nin: company_profile_data?.tin_cac || "",
      };
      userStore.setUser(updated_user);
      localStorage.setItem("nesrea_user", JSON.stringify(updated_user));

      toast.success("User Profile Data Stored Successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleDocumentsUpload = async (documents: File[]) => {
    if (documents.length === 0) {
      toast.error("No document selected");
      return;
    }
    let total_mb = 0;
    documents.forEach((file) => {
      total_mb += file.size / 1024 / 1024;
    });
    if (total_mb > 3) {
      toast.error("Total file size must not be more than 3MB");
      return;
    }

    try {
      toast.success("Document Successfully Uploaded");
      setProfileUploaded(true);
      setFiles(documents);
      setFormPage((prev) => prev + 1);
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      const documentsFormData = new FormData();

      files.forEach((file) => {
        documentsFormData.append("files[]", file);
      });
      documentsFormData.append("account_type", "company");
      documentsFormData.append("email", userStore.user.email);
      await axios.post(
        `${getBackendUrl()}/upload-importer-document`,
        documentsFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const new_user: userStoreInitialState["user"] = {
        ...userStore.user,
        registration_payment_status: "paid",
        registration_status: "pending",
        profile_updated: true,
      };
      userStore.setUser(new_user);
      localStorage.setItem("nesrea_user", JSON.stringify(new_user));
      toast.success("Profile updated successfully");
      navigate("/user/dashboard");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <>
          <CompanyFirstForm handleSubmit={firstFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      component: (
        <>
          <DocumentsForm handleSubmit={handleDocumentsUpload} />
        </>
      ),
    },
    {
      key: "3",
      component: (
        <PaymentForm amount={115000} onSuccess={handlePaymentSuccess} />
      ),
    },
  ];

  return {
    company_form: {
      formPage,
      setFormPage,
      formData,
      setFormData,
      formPages,
      firstFormHandleSubmit,
      handleDocumentsUpload,
    },
    company_files: {
      files,
      setFiles,
    },
    utils: {
      isLoading,
      setIsLoading,
      profileUploaded,
      setProfileUploaded,
      handlePaymentSuccess,
    },
  };
};
