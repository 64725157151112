import React from "react";
import { DataTable } from "./data-table";
import { UserAdministrationColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { Permission } from "@/lib/permissions";

interface AccountRoles {
  id: number;
  account_type: string;
  account_role: Permission[];
  created_by: string;
  created_at: string;
  updated_at: string;
  activities: null;
}

export default function UserAdministrationTable() {
  const { data, isLoading } = useQuery({
    queryKey: ["userAdministration"],
    queryFn: async () => {
      const {
        data,
      }: {
        data?: {
          accountroles: {
            id: number;
            account_type: string;
            account_role: string;
            created_by: string;
            created_at: string;
            updated_at: string;
            activities: null;
          }[];
        };
      } = await axios.get(`${getBackendUrl()}/get-alluser-account-role`);
      const processedData: AccountRoles[] =
        data?.accountroles.map((item) => ({
          ...item,
          account_role: JSON.parse(item.account_role),
        })) || [];

      return processedData;
    },
  });
  return (
    <div>
      <DataTable columns={UserAdministrationColumns} data={data || []} />
    </div>
  );
}
