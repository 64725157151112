import LegalOverviewTable from "@/components/tables/LegalOverviewTable";
import React from "react";

export default function LegalOverview() {
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl flex flex-col gap-5">
      <LegalOverviewTable />
    </div>
  );
}
