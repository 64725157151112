import React from "react";
import AccountingOverview from "../dashboard/components/AccountingOverview";
import RevenueTopImportCategories from "../dashboard/components/RevenueTopImportCategories";
import LegalOverview from "../dashboard/components/LegalOverview";
import ClearanceOverview from "../dashboard/components/ClearanceOverview";
import ReportOverview from "../dashboard/components/ReportOverview";
import { useStore } from "@/contexts/userStore";

export default function ReportsPage() {
  const SECTION_NAME = "Reports";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms.find((perm) => perm.module === SECTION_NAME);
  return (
    <div className="flex flex-col gap-10">
      {perm?.permissions.includes("view:accounting-overview") && (
        <AccountingOverview />
      )}
      {/* {perm?.permissions.includes("view:revenue-of-top-import-categories") && (
        <RevenueTopImportCategories />
      )} */}
      {perm?.permissions.includes("view:legal-overview") && <LegalOverview />}
      {perm?.permissions.includes("view:clearance-overview") && (
        <ClearanceOverview />
      )}
      {perm?.permissions.includes("view:user-base-summary") && (
        <ReportOverview />
      )}
    </div>
  );
}
