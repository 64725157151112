import { units } from "../site-data";

export interface HSCodeFlattened {
  categoryId: string;
  categoryLabel: string;
  categoryCode: string;
  subCategoryId: string;
  subCategoryLabel: string;
  subCategoryCode: string;
  subCategoryCost: {
    [key in (typeof units)[number]["id"]]?: string;
  };
  itemCategoryId: string;
  itemCategoryLabel: string;
  itemCategoryCode: string;
  itemCategoryCost: {
    [key in (typeof units)[number]["id"]]?: string;
  };
  itemId: string;
  itemLabel: string;
  itemCode: string;
  itemCost: {
    [key in (typeof units)[number]["id"]]?: string;
  };
}

export interface HSCodeItem {
  categoryId: string;
  label: string;
  code: string;
  items: {
    categoryId: string;
    label: string;
    code: string;
    cost?: {
      [key in (typeof units)[number]["id"]]?: string;
    };
    items: {
      categoryId: string;
      label: string;
      code: string;
      cost?: {
        [key in (typeof units)[number]["id"]]?: string;
      };
      items: {
        itemId: string;
        label: string;
        code: string;
        cost: {
          [key in (typeof units)[number]["id"]]?: string;
        };
      }[];
    }[];
  }[];
}

export const HSCodes: HSCodeItem[] = [
  {
    categoryId: "food-beverages",
    label: "Food & Beverages",
    code: "01",
    items: [
      {
        categoryId: "fruits",
        label: "Fruits",
        code: "10",
        items: [
          {
            categoryId: "citrus-fruits",
            label: "Citrus Fruits",
            code: "101",
            items: [
              {
                itemId: "oranges",
                label: "Oranges",
                code: "01",
                cost: { kg: "100" },
              },
              {
                itemId: "lemons",
                label: "Lemons",
                code: "02",
                cost: { kg: "200" },
              },
            ],
          },
          {
            categoryId: "berries",
            label: "Berries",
            code: "102",
            items: [
              {
                itemId: "strawberries",
                label: "Strawberries",
                code: "01",
                cost: { kg: "300" },
              },
              {
                itemId: "blueberries",
                label: "Blueberries",
                code: "02",
                cost: { kg: "400" },
              },
            ],
          },
        ],
      },
      {
        categoryId: "beverages",
        label: "Beverages",
        code: "20",
        items: [
          {
            categoryId: "soft-drinks",
            label: "Soft Drinks",
            code: "201",
            items: [
              {
                itemId: "cola",
                label: "Cola",
                code: "01",
                cost: { litres: "500" },
              },
              {
                itemId: "lemonade",
                label: "Lemonade",
                code: "02",
                cost: { litres: "600" },
              },
            ],
          },
          {
            categoryId: "alcoholic-drinks",
            label: "Alcoholic Drinks",
            code: "202",
            items: [
              {
                itemId: "beer",
                label: "Beer",
                code: "01",
                cost: { litres: "700" },
              },
              {
                itemId: "wine",
                label: "Wine",
                code: "02",
                cost: { litres: "800" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    categoryId: "textiles",
    label: "Textiles & Fabrics",
    code: "02",
    items: [
      {
        categoryId: "natural-fibers",
        label: "Natural Fibers",
        code: "30",
        items: [
          {
            categoryId: "cotton",
            label: "Cotton",
            code: "301",
            items: [
              {
                itemId: "raw-cotton",
                label: "Raw Cotton",
                code: "01",
                cost: { kg: "900" },
              },
              {
                itemId: "cotton-yarn",
                label: "Cotton Yarn",
                code: "02",
                cost: { kg: "1000" },
              },
            ],
          },
          {
            categoryId: "wool",
            label: "Wool",
            code: "302",
            items: [
              {
                itemId: "raw-wool",
                label: "Raw Wool",
                code: "01",
                cost: { kg: "1100" },
              },
              {
                itemId: "wool-yarn",
                label: "Wool Yarn",
                code: "02",
                cost: { kg: "1200" },
              },
            ],
          },
        ],
      },
      {
        categoryId: "synthetic-fibers",
        label: "Synthetic Fibers",
        code: "40",
        items: [
          {
            categoryId: "polyester",
            label: "Polyester",
            code: "401",
            items: [
              {
                itemId: "polyester-fiber",
                label: "Polyester Fiber",
                code: "01",
                cost: { kg: "1300" },
              },
              {
                itemId: "polyester-yarn",
                label: "Polyester Yarn",
                code: "02",
                cost: { kg: "1400" },
              },
            ],
          },
          {
            categoryId: "nylon",
            label: "Nylon",
            code: "402",
            items: [
              {
                itemId: "nylon-fiber",
                label: "Nylon Fiber",
                code: "01",
                cost: { kg: "1500" },
              },
              {
                itemId: "nylon-yarn",
                label: "Nylon Yarn",
                code: "02",
                cost: { kg: "1600" },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    categoryId: "electronics",
    label: "Electronics",
    code: "85",
    items: [
      {
        categoryId: "computers",
        label: "Computers",
        code: "50",
        items: [
          {
            categoryId: "laptops",
            label: "Laptops",
            code: "501",
            items: [
              {
                itemId: "gaming-laptop",
                label: "Gaming Laptop",
                code: "01",
                cost: { kg: "1700" },
              },
              {
                itemId: "business-laptop",
                label: "Business Laptop",
                code: "02",
                cost: { kg: "1800" },
              },
            ],
          },
          {
            categoryId: "desktops",
            label: "Desktops",
            code: "502",
            items: [
              {
                itemId: "workstation",
                label: "Workstation",
                code: "01",
                cost: { kg: "1900" },
              },
              {
                itemId: "all-in-one",
                label: "All-in-One",
                code: "02",
                cost: { kg: "2000" },
              },
            ],
          },
        ],
      },
      {
        categoryId: "mobile-devices",
        label: "Mobile Devices",
        code: "51",
        items: [
          {
            categoryId: "smartphones",
            label: "Smartphones",
            code: "511",
            items: [
              {
                itemId: "android-phone",
                label: "Android Phone",
                code: "01",
                cost: { kg: "2100" },
              },
              {
                itemId: "ios-phone",
                label: "iOS Phone",
                code: "02",
                cost: { kg: "2200" },
              },
            ],
          },
          {
            categoryId: "tablets",
            label: "Tablets",
            code: "512",
            items: [
              {
                itemId: "android-tablet",
                label: "Android Tablet",
                code: "01",
                cost: { kg: "2300" },
              },
              {
                itemId: "ios-tablet",
                label: "iOS Tablet",
                code: "02",
                cost: { kg: "2400" },
              },
            ],
          },
        ],
      },
    ],
  },
];

export function bundleHScodeArray(inputArray: HSCodeFlattened[]): HSCodeItem[] {
  const output: HSCodeItem[] = [];

  inputArray.forEach((input) => {
    // Find or create the top-level category
    let category = output.find((cat) => cat.categoryId === input.categoryId);
    if (!category) {
      category = {
        categoryId: input.categoryId,
        label: input.categoryLabel,
        code: input.categoryCode,
        items: [],
      };
      output.push(category);
    }

    // Find or create the subcategory
    let subCategory = category.items.find(
      (sub) => sub.categoryId === input.subCategoryId
    );
    if (!subCategory) {
      subCategory = {
        categoryId: input.subCategoryId,
        label: input.subCategoryLabel,
        code: input.subCategoryCode,
        items: [],
      };
      category.items.push(subCategory);
    }

    // Find or create the item category
    let itemCategory = subCategory.items.find(
      (itemCat) => itemCat.categoryId === input.itemCategoryId
    );
    if (!itemCategory) {
      itemCategory = {
        categoryId: input.itemCategoryId,
        label: input.itemCategoryLabel,
        code: input.itemCategoryCode,
        items: [],
      };
      subCategory.items.push(itemCategory);
    }

    // Add the item to the item category
    itemCategory.items.push({
      itemId: input.itemId,
      label: input.itemLabel,
      code: input.itemCode,
      cost: input.itemCost,
    });
  });

  return output;
}

export function flattenHSCodeArray(
  inputArray: HSCodeItem[]
): HSCodeFlattened[] {
  const flatArray: HSCodeFlattened[] = [];

  inputArray.forEach((category) => {
    category.items.forEach((subCategory) => {
      subCategory.items.forEach((itemCategory) => {
        itemCategory.items.forEach((item) => {
          flatArray.push({
            categoryId: category.categoryId,
            categoryLabel: category.label,
            categoryCode: category.code,
            subCategoryId: subCategory.categoryId,
            subCategoryLabel: subCategory.label,
            subCategoryCode: subCategory.code,
            subCategoryCost: {},
            itemCategoryId: itemCategory.categoryId,
            itemCategoryLabel: itemCategory.label,
            itemCategoryCode: itemCategory.code,
            itemCategoryCost: {},
            itemId: item.itemId,
            itemLabel: item.label,
            itemCode: item.code,
            itemCost: item.cost,
          });
        });
      });
    });
  });

  return flatArray;
}
