import AdminSupportTable from "@/components/tables/AdminSupportTable";
import React from "react";

export default function ComplaintsTicketingPage() {
  return (
    <div>
      <AdminSupportTable />
    </div>
  );
}
