import React, { useEffect } from "react";
import ComponentSwitch from "@/components/ui/component-switch";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { PaymentForm } from "@/components/ui/payment-section";
import { useCompanyForm } from "./_hooks";

export const CompanyProfileRegistrationForm = () => {
  const { company_form, company_files, utils } = useCompanyForm();

  return (
    <LoaderWrapper isLoading={utils.isLoading}>
      <div className="w-full flex flex-col gap-5">
        <div className="space-y-3">
          <h4 className="font-medium text-2xl">
            {company_form.formPage == 1 &&
              "Enter the following details to update your profile"}
            {company_form.formPage == 3 &&
              "Make a payment to complete your profile registration"}
          </h4>
        </div>

        {/* If the user has not uploaded a profile, show the Profile Registration Form */}

        <>
          <ComponentSwitch
            activeKey={company_form.formPage.toString()}
            components={company_form.formPages}
          />
        </>
      </div>
    </LoaderWrapper>
  );
};
