import ClassificationDocumentationTable from "@/components/tables/ClassificationDocumentationTable";
import RegistrationDocumentationTable from "@/components/tables/registrationDocumentationTable";
import React from "react";

export default function DocumentationKYCPage() {
  return (
    <div className="flex flex-col gap-7">
      <ClassificationDocumentationTable />
      <RegistrationDocumentationTable />
    </div>
  );
}
