import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { cn } from "@/lib/utils";
import React from "react";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { ImportClearanceByTopImportCategoryChart } from "./ImportClearanceByTopImportCategoryChart";

export default function ImportClearanceApplicationsbByTopItemCategoryReport() {
  const { data, isLoading } = useClearanceAnalytics();
  const CardStats = [
    {
      title: "Chemical (Raw Materials)",
      value: 0,
    },

    {
      title: "Used Electrical and Electronics Equipment",
      value:0,
    },

    {
      title: "Used Auto Parts/Vehicles",
      value: 0,
    },
    {
      title: "Food and Drugs",
      value: 0,
    },
  ];
  return (
    <LoaderWrapper page={false} isLoading={isLoading}>
      <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-primary-700 text-xl font-medium">
            Clearances by Top Item Category
          </h1>
          <Sheet>
            <SheetTrigger
              className={cn(
                buttonVariants({ variant: "default" }),
                "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
              )}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Filters
            </SheetTrigger>
            <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
              {/* Filters Here */}
            </SheetContent>
          </Sheet>
        </div>

        {data && (
          <div className="w-full grid md:grid-cols-5">
            <div className="col-span-3 h-full flex items-center">
              <ImportClearanceByTopImportCategoryChart data={data} />
            </div>
            <div className="grid gap-5 min-w-[200px] col-span-2 w-full mx-auto">
              {CardStats.map(({ title, value }, index) => (
                <div
                  key={title}
                  className={cn(
                    "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-full h-full justify-between bg-primary-50 text-primary-700"
                  )}
                >
                  <p className={cn("text-xs ")}>{title}</p>
                  <h4 className="font-bold text-2xl">{value}</h4>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </LoaderWrapper>
  );
}
