import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useStore as useStore_ } from "@/contexts/userStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";

export interface ImporterCertificate {
  id: number;
  nesrea_id: string;
  importer_name: string;
  importer_email: string;
  clearance_id: string;
  issuerance_date: string;
  certificate_number: string;
  expiry_date: string;
  created_by: string;
  status: string;
  created_at: string;
  updated_at: string;
  activities: string;
}

export default function CertificatesTable() {
  const agentStore = useStore((state) => state);
  const userStore = useStore_((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["importer-certificates"],
    queryFn: async () => {
      const {
        data,
      }: {
        data?: {
          Importer_Certificate_List: ImporterCertificate[];
        };
      } = await axios.get(
        `${getBackendUrl()}/get-importer-certificates/${userStore.email}`
      );

      return data?.Importer_Certificate_List || [];
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.importer_name,
      certificate_numbe: item.certificate_number,
      date: item.created_at,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={ClientsColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
