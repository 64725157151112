import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useMemo } from "react";
import { useStore } from "@/contexts/userStore";

interface ClearanceApplication {
  id: number;
  clearance_id: string;
  nesrea_id: string;
  importer_email: string;
  importer_name: string;
  importer_category: string;
  item: string;
  document_url: string;
  document_name: string;
  amount: string | null;
  reference: string | null;
  payment_id: string | null;
  payment_status: string | null;
  payment_date: string | null;
  clearance_status: string;
  status: string;
  waiver_usage_status: string | null;
  created_at: string;
  action_taken_clearance_application: string | null;
  clearance_approved_by: string | null;
  clearance_approval_date: string | null;
  clearance_approval_comment: string | null;
  action_taken_payment: string | null;
  clearance_payment_approved_by: string | null;
  clearance_payment_approval_date: string | null;
  clearance_payment_approval_comment: string | null;
  activities: string;
  created_by: string;
  updated_at: string;
  notification: string | null;
  notificaton_status: string | null;
}

const defaultMetrics = {
  totalApplications: 0,
  approvedApplications: 0,
  disapprovedApplications: 0,
  pendingApplications: 0,
  totalAmount: 0,
  successfulPayments: 0,
  pendingPayments: 0,
  companyApplications: 0,
  individualApplications: 0,
  governmentApplications: 0,
  companyRevenue: 0,
  individualRevenue: 0,
  governmentRevenue: 0,
};

const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const initializeYearData = () => {
  const yearData: Record<string, typeof defaultMetrics> = {};
  monthNames.forEach(month => {
    yearData[month] = { ...defaultMetrics };
  });
  return yearData;
};

export const processClearanceData = (data: ClearanceApplication[]) => {
  const totalApplications = data.length;
  const approvedApplications = data.filter(
    (app) => app.status === "Approved"
  ).length;
  const disapprovedApplications = data.filter(
    (app) => app.status === "Disapproved"
  ).length;
  const pendingApplications = data.filter((app) => app.status === "NEW").length;
  const totalAmount = data.reduce(
    (sum, app) => sum + (app.amount ? parseFloat(app.amount) : 0),
    0
  );
  const successfulPayments = data.filter(
    (app) => app.payment_status === "success"
  ).length;
  const pendingPayments = data.filter(
    (app) => app.payment_status === null
  ).length;
  const companyApplications = data.filter(
    (app) => app.importer_category === "company"
  ).length;
  const individualApplications = data.filter(
    (app) => app.importer_category === "individual"
  ).length;
  const governmentApplications = data.filter(
    (app) => app.importer_category === "government"
  ).length;
  const companyRevenue = data.reduce(
    (sum, app) =>
      sum +
      (app.importer_category === "company" && app.amount
        ? parseFloat(app.amount)
        : 0),
    0
  );
  const individualRevenue = data.reduce(
    (sum, app) =>
      sum +
      (app.importer_category === "individual" && app.amount
        ? parseFloat(app.amount)
        : 0),
    0
  );
  const governmentRevenue = data.reduce(
    (sum, app) =>
      sum +
      (app.importer_category === "government" && app.amount
        ? parseFloat(app.amount)
        : 0),
    0
  );

  const analyticsByYearMonth = data.reduce((acc, app) => {
    const date = new Date(app.created_at);
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()]; // getMonth() returns 0-11

    if (!acc[year]) {
      acc[year] = initializeYearData();
    }

    const monthData = acc[year][month];
    monthData.totalApplications += 1;
    if (app.status === "Approved") monthData.approvedApplications += 1;
    if (app.status === "Disapproved") monthData.disapprovedApplications += 1;
    if (app.status === "NEW") monthData.pendingApplications += 1;
    if (app.amount) monthData.totalAmount += parseFloat(app.amount);
    if (app.payment_status === "success") monthData.successfulPayments += 1;
    if (app.payment_status === null) monthData.pendingPayments += 1;
    if (app.importer_category === "company") {
      monthData.companyApplications += 1;
      if (app.amount) monthData.companyRevenue += parseFloat(app.amount);
    }
    if (app.importer_category === "individual") {
      monthData.individualApplications += 1;
      if (app.amount) monthData.individualRevenue += parseFloat(app.amount);
    }
    if (app.importer_category === "government") {
      monthData.governmentApplications += 1;
      if (app.amount) monthData.governmentRevenue += parseFloat(app.amount);
    }

    return acc;
  }, {} as Record<number, Record<string, typeof defaultMetrics>>);

  return {
    totalApplications,
    approvedApplications,
    disapprovedApplications,
    pendingApplications,
    totalAmount,
    successfulPayments,
    pendingPayments,
    companyApplications,
    individualApplications,
    governmentApplications,
    companyRevenue,
    individualRevenue,
    governmentRevenue,
    analyticsByYearMonth,
  };
};

export const useClearanceAnalytics = (keys?: string[]) => {
  const user = useStore((state) => state.user);
  const { data: dataResponse, isLoading } = useQuery({
    queryKey: keys ? ["clearance-analytics", ...keys] : ["clearance-analytics"],
    queryFn: async () => {
      const { data: res } = await axios.get(
        `${getBackendUrl()}/all-clearance-level/${user.ops_level}`
      );

      const data: ClearanceApplication[] = res.allclearanceapps;

      const analytics = processClearanceData(data);
      return { analytics, data };
    },
  });
  const { analytics, data } = dataResponse || {};
  return { data: analytics, clearanceData: data, isLoading };
};
