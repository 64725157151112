import CertificatesTable from "@/components/tables/CertificatesTable";
import DashboardHeader from "@/components/ui/dashboard-header";
import React from "react";

export default function CertificatesView() {
  return (
    <div>
      <DashboardHeader
        title="Certificates"
        caption="View all import clearance certificates"
      />
      <CertificatesTable />
    </div>
  );
}
