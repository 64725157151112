import React, { useEffect } from "react";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import axios from "axios";
import { useState } from "react";
import { toast } from "sonner";

import { getBackendUrl } from "@/lib/utils";
import {
  agent_form_schema_page_1_type,
  agent_form_schema_page_2_type,
} from "./_schema";
import { AgentFirstFormPage } from "./_form-pages/first_form_page";
import { AgentSecondFormPage } from "./_form-pages/second_form_page";
import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { useNavigate } from "react-router-dom";
import { PaymentForm } from "@/components/ui/payment-section";
import { stateNamesMap } from "@/lib/site-data";
import { QueryClient } from "@tanstack/react-query";

export const useAgentForm = () => {
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    state: "",
    lga: "",
    street: "",
    association: "",
    nin: "",
  });

  const [files, setFiles] = useState<File[]>([]);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userStore = useStore((state) => state);
  const navigate = useNavigate();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (userStore.user.profile_updated) {
      setFormPage(3);
    }
  }, []);

  const firstFormHandleSubmit = (values: agent_form_schema_page_1_type) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setFormPage((prev) => prev + 1);
  };

  const secondFormHandleSubmit = async (
    values: agent_form_schema_page_2_type
  ) => {
    setIsLoading(true);
    try {
      // Store the form data
      setFormData((prev) => {
        return { ...prev, ...values };
      });

      const agent_profile_data = {
        account_type: userStore.user.account_type,
        name: formData.fullName,
        phone_number: formData.phoneNumber,
        email: userStore.user.email,
        state: stateNamesMap[formData.state] || formData.state,
        lga: formData.lga,
        street_address: formData.street,
        association: values.association,
        national_identity_number: values.nin,
      };
      // store profile data
      await axios.post(
        `${getBackendUrl()}/store-agent-information`,
        agent_profile_data
      );

      const updated_user: userStoreInitialState["user"] = {
        ...userStore.user,
        profile_updated: true,
        phone_number: agent_profile_data?.phone_number || "",
        state: agent_profile_data?.state || "",
        association: agent_profile_data?.association || "",
        name: agent_profile_data?.name || "",
        street: agent_profile_data?.street_address || "",
        nin: agent_profile_data?.national_identity_number || "",
      };
      userStore.setUser(updated_user);
      localStorage.setItem("nesrea_user", JSON.stringify(updated_user));

      toast.success("User profile stored successfully");
      setFormPage((prev) => prev + 1);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleDocumentsUpload = async (documents: File[]) => {
    if (documents.length === 0) {
      toast.error("No document selected");
      return;
    }
    let total_mb = 0;
    documents.forEach((file) => {
      total_mb += file.size / 1024 / 1024;
    });
    if (total_mb > 3) {
      toast.error("Total file size must not be more than 3MB");
      return;
    }

    try {
      toast.success("Document Successfully Uploaded");
      setProfileUploaded(true);
      setFiles(documents);
      setFormPage((prev) => prev + 1);
    } catch (error) {
      toast.error("An error occurred");
    }
  };
  const handlePaymentSuccess = async () => {
    setIsLoading(true);
    try {
      // upload documents
      const documentsFormData = new FormData();

      files.forEach((file) => {
        documentsFormData.append("files[]", file);
      });
      documentsFormData.append("account_type", userStore.user.account_type);
      documentsFormData.append("email", userStore.user.email);
      await axios.post(
        `${getBackendUrl()}/upload-agent-document`,
        documentsFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const new_user: userStoreInitialState["user"] = {
        ...userStore.user,
        registration_payment_status: "paid",
        registration_status: "pending",
        profile_updated: true,
      };
      localStorage.setItem("nesrea_user", JSON.stringify(new_user));
      userStore.setUser(new_user);
      navigate("/user/dashboard");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <>
          <AgentFirstFormPage handleSubmit={firstFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      component: (
        <>
          <AgentSecondFormPage handleSubmit={secondFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      component: <DocumentsForm handleSubmit={handleDocumentsUpload} />,
    },
    {
      key: "4",
      component: (
        <PaymentForm
          amount={115000}
          onSuccess={handlePaymentSuccess}
          endpoint="agent-registration-payment-response"
        />
      ),
    },
  ];

  return {
    agent_page: {
      formPage,
      setFormPage,
    },
    agent_form: {
      formData,
      setFormData,
      firstFormHandleSubmit,
      secondFormHandleSubmit,
      handleDocumentsUpload,
      formPages,
    },
    agent_files: {
      files,
      setFiles,
    },
    utils: {
      isLoading,
      setIsLoading,
      profileUploaded,
      setProfileUploaded,
      handlePaymentSuccess,
    },
  };
};
