import React from "react";
import WaiverRequestsTableAdmin from "@/components/tables/WaiverRequestsTableAdmin";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import GeneratedWaiversTable from "@/components/tables/GeneratedWaiversTable";
import { useStore } from "@/contexts/userStore";

export default function WaiverPage() {
  const SECTION_NAME = "Accounting";
  const MODULE_NAME = "Waiver";
  
    const user = useStore((state) => state.user);
    const permission_data = user.admin_role?.find(
      (role) => role.name === SECTION_NAME
    );
  
    if (!permission_data || !permission_data.isAllowed) {
      return (
        <div>
          <h1 className="text-2xl font-bold">
            You do not have access to this page
          </h1>
        </div>
      );
    }
  
    const module_perms = permission_data?.permissions;
    const perm = module_perms.find((perm) => perm.module === MODULE_NAME);
  return (
    <div className="flex flex-col gap-7">
      {perm?.permissions.includes("action:generate-waiver") && (
        <div className="w-full flex justify-end">
          <Link
            to={"/admin/accounting/waiver/generate-waiver"}
            className={cn(buttonVariants({ variant: "default" }))}
          >
            Generate Waiver
          </Link>
        </div>
      )}
      {perm?.permissions.includes("view:waiver-requests") && (
        <WaiverRequestsTableAdmin />
      )}
      {perm?.permissions.includes("view:generate-waivers") && (
        <GeneratedWaiversTable />
      )}
    </div>
  );
}
