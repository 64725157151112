import React from "react";
import { DataTable } from "./data-table";
import { RecentActivityColumns } from "./columns";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";

interface ClassificationResponse {
  importitemsclassifications: {
    id: number;
    classification_name: string;
    hscode: string;
    status: string;
    created_at: string;
    updated_at: string;
  }[];
}

export default function AllHsCodes() {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["hscodes"],
    queryFn: async () => {
      // const { data }: { data: ClassificationResponse } = await axios.get(
      //   `${getBackendUrl()}/get-import-item-classifications`
      // );
      return [] as any[];
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(stat.id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: item.id,
      name: item.name,
      title: item.title,
      date: item.date,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);

  return (
    <LoaderWrapper isLoading={false}>
      <DataTable
        columns={RecentActivityColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
