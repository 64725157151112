import PaymentsTable from "@/components/tables/PaymentsTable";
import { buttonVariants } from "@/components/ui/button";
import DashboardHeader from "@/components/ui/dashboard-header";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";

export default function PaymentsView() {
  return (
    <>
      <DashboardHeader
        title="Payments"
        caption="Keep track of all your payments"
      />
      <div className="flex flex-col gap-7">
        <div className="w-full rounded-2xl py-5 px-8 flex items-center text-primary-foreground justify-between banner-gradient">
          <h4 className="text-xl font-medium">
            Apply for an Import Clearance Certificate
          </h4>

          <Link
            to={"/user/application"}
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-white text-primary-700 hover:bg-white/90 w-full max-w-[175px]"
            )}
          >
            Apply Here
          </Link>
        </div>
        <PaymentsTable />
      </div>
    </>
  );
}
