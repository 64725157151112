import { create } from "zustand";

export interface agentStoreInitialState {
  clients: {
    name: string;
    category: string;
    dateCreated: string;
  }[];
  filteredClients: agentStoreInitialState["clients"];
  selectedClients: agentStoreInitialState["clients"];

  applications: {
    id: string;
    name: string;
    category: string;
    dateCreated: string;
    status: "Pending" | "Approved";
  }[];
  filteredApplications: agentStoreInitialState["applications"];

  importDetails: {
    item: string;
    hsCodes: string;
    unit: string;
    quantity: number;
    cargoNumber: string;
    countryOfOrigin: string;
  }[];

  waiverRequests: {
    id: string;
    item: string;
    amount: string;
    dateCreated: string;
    status: "Pending" | "Approved";
  }[];

  activeApplicationClient?: string;

  setClients: (clients: agentStoreInitialState["clients"]) => void;
  setFilteredClients: (clients: agentStoreInitialState["clients"]) => void;
  addClient: (client: agentStoreInitialState["clients"][0]) => void;
  addWaiverRequest: (
    request: agentStoreInitialState["waiverRequests"][0]
  ) => void;
  selectClients: (client: agentStoreInitialState["clients"]) => void;
  deselectClient: (client: agentStoreInitialState["clients"][0]) => void;
  clearSelectedClients: () => void;
  deleteClient: (client: agentStoreInitialState["clients"][0]) => void;
  addApplication: (
    application: agentStoreInitialState["applications"][0]
  ) => void;
  setApplications: (
    applications: agentStoreInitialState["applications"]
  ) => void;
  setFilteredApplications: (
    applications: agentStoreInitialState["applications"]
  ) => void;
  addImportDetail: (
    importDetail: agentStoreInitialState["importDetails"][0]
  ) => void;
  setImportDetails: (
    importDetails: agentStoreInitialState["importDetails"]
  ) => void;
  clearImportDetails: () => void;
  clearApplicationClient: () => void;
  setActiveApplicationClient: (client: string) => void;
}

export const useStore = create<agentStoreInitialState>((set) => ({
  clients: [],
  filteredClients: [],
  selectedClients: [],
  applications: [],
  filteredApplications: [],
  importDetails: [],
  waiverRequests: [],

  setClients(clients) {
    set((state) => {
      return { ...state, clients, filteredClients: clients };
    });
  },
  setFilteredClients(clients) {
    set((state) => {
      return { ...state, filteredClients: clients };
    });
  },
  setApplications(applications) {
    set((state) => {
      return { ...state, applications, filteredApplications: applications };
    });
  },
  setFilteredApplications(applications) {
    set((state) => {
      return { ...state, filteredApplications: applications };
    });
  },
  addClient(client) {
    set((state) => {
      return { ...state, clients: [...state.clients, client] };
    });
  },
  addWaiverRequest(request) {
    set((state) => {
      return { ...state, waiverRequests: [...state.waiverRequests, request] };
    });
  },
  selectClients(client) {
    set((state) => {
      return {
        ...state,
        selectedClients: [...state.selectedClients, ...client],
      };
    });
  },
  clearSelectedClients() {
    set((state) => {
      return { ...state, selectedClients: [] };
    });
  },
  deselectClient(client) {
    set((state) => {
      return {
        ...state,
        selectedClients: state.selectedClients.filter(
          (clientItem) => clientItem.name != client.name
        ),
      };
    });
  },
  deleteClient(client) {
    set((state) => {
      return {
        ...state,
        clients: state.clients.filter((clientItem) => clientItem != client),
      };
    });
  },

  addApplication(application) {
    set((state) => {
      return { ...state, applications: [...state.applications, application] };
    });
  },

  setImportDetails(importDetails) {
    set((state) => {
      return { ...state, importDetails };
    });
  },

  addImportDetail(importDetail) {
    set((state) => {
      return {
        ...state,
        importDetails: [...state.importDetails, importDetail],
      };
    });
  },
  clearImportDetails() {
    set((state) => {
      return { ...state, importDetails: [] };
    });
  },
  clearApplicationClient() {
    set((state) => {
      return { ...state, activeApplicationClient: "" };
    });
  },
  setActiveApplicationClient(client) {
    set((state) => {
      return { ...state, activeApplicationClient: client };
    });
  },
}));
