import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil, Trash } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate, getBackendUrl } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { ImportDocumentationData } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormControl,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { HSCodeFlattened, HSCodes } from "@/lib/dummy-data/hsCodes";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { unitIds } from "@/lib/site-data";
import slugify from "slugify";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { toast } from "sonner";
import axios from "axios";
import { ClassificationPricingResponseData } from ".";
import { format } from "fecha";
import { AddPricingForm } from "./pricing-form";

export const ClassificationPricingColumns: ({
  permissions,
}: {
  permissions: string[];
}) => ColumnDef<
  ClassificationPricingResponseData["classificationquantityrange"][0]
>[] = ({ permissions }) => [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          SN
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "classification",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Classification
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "quantity_range",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Quantity Range
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "unit_of_measurement",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Unit of Measurement
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "range_cost",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Range Cost
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "updated_at",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Last Updated
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{format(new Date(row.original.updated_at), "mediumDate")}</p>;
    },
  },

  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const [isActionLoading, setIsActionLoading] = React.useState(false);
      const {} = row.original;
      const formSchema = z.object({});

      const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {},
      });

      const handleDelete = async () => {
        setIsActionLoading(true);
        try {
          await axios.post(
            `${getBackendUrl()}/delete-classification-price-range`,
            {
              classification: row.original.classification,
            }
          );
          setIsActionLoading(false);
          toast.success("deleted successfully");
          window.location.reload();
        } catch (error) {
          setIsActionLoading(false);
          toast.error("An Error Occurred");
        }
      };
      return (
        <div className="grid grid-cols-2 gap-4">
          {permissions.includes("action:manage-pricing") && (
            <>
              <Dialog>
                <DialogTrigger className="underline text-primary-700 text-sm p-0">
                  Edit
                </DialogTrigger>
                <DialogContent>
                  <AddPricingForm
                    classifications={[
                      {
                        id: row.original.id,
                        name: row.original.classification,
                      },
                    ]}
                    defaultValues={{
                      classification: row.original.classification,
                      unit: row.original.unit_of_measurement,
                      rangeStart: parseInt(
                        row.original.quantity_range.split("-")[0]
                      ),
                      rangeEnd: parseInt(
                        row.original.quantity_range.split("-")[1]
                      ),
                      cost: parseFloat(row.original.range_cost),
                    }}
                  />
                </DialogContent>
              </Dialog>
              <Dialog>
                <DialogTrigger className="underline text-destructive text-sm p-0">
                  Delete
                </DialogTrigger>
                <DialogContent>
                  <h3 className="text-primary-500 font-medium text-lg text-center">
                    {"You're"} about to delete an import item. Do you want to
                    proceed?
                  </h3>
                  <div className="flex items-center gap-4">
                    <Button
                      className="w-full py-7"
                      onClick={handleDelete}
                      disabled={isActionLoading}
                    >
                      Yes, proceed
                    </Button>
                    <Button
                      variant="outline"
                      disabled={isActionLoading}
                      className="w-full py-7 bg-transparent border-destructive text-destructive"
                    >
                      No, cancel
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      );
    },
  },
  // {
  //   accessorKey: "documentCode",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Document Code
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <div className="grid gap-4">
  //         {row.original.documents.map((doc) => (
  //           <p key={doc.documentCode} className="text-sm">
  //             {doc.documentCode}
  //           </p>
  //         ))}
  //       </div>
  //     );
  //   },
  // },

  // {
  //   accessorKey: "action",
  //   header: ({ column }) => {
  //     return (
  //       <div className="flex items-center">
  //         Action
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const formSchema = z.object({
  //       comment: z.string().min(2, { message: "" }),
  //     });
  //     type formSchemaType = z.infer<typeof formSchema>;
  //     const form = useForm<formSchemaType>({
  //       resolver: zodResolver(formSchema),
  //       defaultValues: {
  //         comment: "",
  //       },
  //     });
  //     return (
  //       <div className="grid gap-4">
  //         {row.original.documents.map((doc) => (
  //           <Dialog>
  //             <DialogTrigger className="underline text-primary-700 text-sm p-0">
  //               Delete
  //             </DialogTrigger>
  //             <DialogContent>
  //               <h3 className="text-primary-500 font-medium text-lg text-center">
  //                 {"You're"} about to delete a document. Do you want to
  //                 proceed?
  //               </h3>
  //               <Form {...form}>
  //                 <form
  //                   className="flex flex-col gap-7"
  //                   onSubmit={form.handleSubmit(async (data) => {})}
  //                 >
  //                   <FormField
  //                     control={form.control}
  //                     name="comment"
  //                     render={({ field }) => (
  //                       <FormItem>
  //                         <FormLabel className="text-primary-700 font-semibold text-lg">
  //                           Add comment/reason for deletion{" "}
  //                           <span className="text-red-500">*</span>
  //                         </FormLabel>
  //                         <FormControl>
  //                           <Input {...field} />
  //                         </FormControl>
  //                         <FormMessage />
  //                       </FormItem>
  //                     )}
  //                   />
  //                   <div className="flex w-full gap-5">
  //                     <Button type="submit" className="w-full py-7">
  //                       Yes, proceed
  //                     </Button>
  //                     <DialogClose asChild>
  //                       <Button
  //                         type="button"
  //                         variant="outline"
  //                         className="w-full py-7 bg-transparent border-destructive text-destructive"
  //                       >
  //                         No, cancel
  //                       </Button>
  //                     </DialogClose>
  //                   </div>
  //                 </form>
  //               </Form>
  //             </DialogContent>
  //           </Dialog>
  //         ))}
  //       </div>
  //     );
  //   },
  // },
];
