import { Button } from "@/components/ui/button";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useStore } from "@/contexts/userStore";
import { ScrollArea } from "@/components/ui/scroll-area";
import { logout } from "@/lib/auth";
import { user_routes } from "@/routes/user/routes";

export default function Sidebar() {
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const isVerified = userStore.user?.registration_status == "verified";
  const navigate = useNavigate();

  const routes = user_routes.filter(
    (route) =>
      route.allowed_roles == "all" ||
      route.allowed_roles.includes(userStore.user.account_type)
  );
  return (
    <ScrollArea className="w-full h-screen bg-[#EEF8FA] p-10">
      <div className="w-full h-full flex flex-col items-center gap-7">
        <div className="w-[110px] aspect-square rounded-full p-5 bg-primary-100 flex items-center justify-center">
          <img src="/images/Logo.png" className="w-full" alt="" />
        </div>
        <hr className="w-full border" />
        <div className="flex flex-col gap-5">
          {routes?.map((item) => (
            <NavItem
              key={item.href}
              {...item}
              disabled={item.requires_user_verification && !isVerified}
            />
          ))}
        </div>
        <Button
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            await logout(userStore.clearUser, navigate);
            setIsLoading(false);
          }}
          className="flex items-center gap-2 text-destructive w-full mt-auto bg-primary-100 hover:bg-primary-100/80"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_957_17400"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="25"
              height="25"
            >
              <rect
                x="0.173828"
                y="0.5"
                width="24"
                height="24"
                fill="#D9D9D9"
              />
            </mask>
            <g mask="url(#mask0_957_17400)">
              <path
                fill="currentColor"
                d="M5.17383 21.5C4.62383 21.5 4.15299 21.3042 3.76133 20.9125C3.36966 20.5208 3.17383 20.05 3.17383 19.5V5.5C3.17383 4.95 3.36966 4.47917 3.76133 4.0875C4.15299 3.69583 4.62383 3.5 5.17383 3.5H12.1738V5.5H5.17383V19.5H12.1738V21.5H5.17383ZM16.1738 17.5L14.7988 16.05L17.3488 13.5H9.17383V11.5H17.3488L14.7988 8.95L16.1738 7.5L21.1738 12.5L16.1738 17.5Z"
              />
            </g>
          </svg>
          {isLoading ? "Logging out..." : "Log Out"}
        </Button>
      </div>
    </ScrollArea>
  );
}

const NavItem = ({
  icon: Icon,
  text,
  href,
  disabled,
}: {
  icon?: () => JSX.Element;
  text: string;
  href: string;
  disabled?: boolean;
}) => {
  const { pathname } = useLocation();
  const isActive =
    (pathname === "/user" && href === "/user") ||
    pathname === href ||
    (pathname?.startsWith(`/user${href}`) && href !== "/user");

  return (
    <Link
      className={cn(
        "flex items-center gap-3 text-primary-700 text-lg px-8 py-3 rounded-[16px] hover:bg-primary-700 hover:text-primary-foreground duration-500 w-full",
        isActive && "bg-primary-700 text-primary-foreground",
        disabled &&
          "opacity-50 cursor-not-allowed hover:bg-transparent hover:text-primary-700"
      )}
      to={disabled ? "#" : href == "/user" ? href : `/user${href}`}
    >
      {Icon && <Icon />}
      {text}
    </Link>
  );
};
