import React from "react";
import {
  AgentProfileRegistrationForm,
  AgentProfileUpdateForm,
  CompanyProfileRegistrationForm,
  CompanyProfileUpdateForm,
  GovernmentProfileRegistrationForm,
  GovernmentProfileUpdateForm,
  IndividualProfileRegistrationForm,
  IndividualProfileUpdateForm,
} from "@/pages/dashboard/profile/_forms";
import { useStore } from "@/contexts/userStore";
import ComponentSwitch from "@/components/ui/component-switch";
import DashboardHeader from "@/components/ui/dashboard-header";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import PasswordUpdateForm from "./_forms/PasswordUpdateForm";

export default function ProfilePage() {
  const userStore = useStore((state) => state);
  const { registration_status, registration_payment_status } = userStore.user;

  const ProfileRegistrationForms = [
    {
      key: "agent",
      component: <AgentProfileRegistrationForm />,
    },
    {
      key: "company",
      component: <CompanyProfileRegistrationForm />,
    },
    {
      key: "individual",
      component: <IndividualProfileRegistrationForm />,
    },
    {
      key: "government",
      component: <GovernmentProfileRegistrationForm />,
    },
  ];

  const ProfileUpdateForms = [
    {
      key: "agent",
      component: (
        <>
          <AgentProfileUpdateForm />
        </>
      ),
    },
    {
      key: "company",
      component: (
        <>
          <CompanyProfileUpdateForm />
        </>
      ),
    },
    {
      key: "individual",
      component: (
        <>
          <IndividualProfileUpdateForm />
        </>
      ),
    },
    {
      key: "government",
      component: (
        <>
          <GovernmentProfileUpdateForm />
        </>
      ),
    },
  ];

  return (
    <>
      {(registration_status == "unverified" ||
        registration_status == "pending") &&
        registration_payment_status == "unpaid" && (
          <>
            <ComponentSwitch
              components={ProfileRegistrationForms}
              activeKey={userStore.user.account_type}
            />
          </>
        )}
      {registration_status == "verified" &&
        registration_payment_status == "paid" && (
          <>
            <DashboardHeader
              title="Profile Update"
              caption="Update to your dashboard"
            />
            <Tabs defaultValue="profile" className="w-full">
              <TabsList className="mb-4">
                <TabsTrigger value="profile" className="text-primary-800">
                  Profile
                </TabsTrigger>
                <TabsTrigger value="password" className="text-primary-800">
                  Password Update
                </TabsTrigger>
              </TabsList>
              <TabsContent value="profile">
                <ComponentSwitch
                  components={ProfileUpdateForms}
                  activeKey={userStore.user.account_type}
                />
              </TabsContent>
              <TabsContent value="password">
                <PasswordUpdateForm />
              </TabsContent>
            </Tabs>
          </>
        )}

      {registration_status == "pending" &&
        registration_payment_status == "paid" && (
          <div className="w-full h-full flex items-center justify-center">
            <h1 className="text-2xl text-primary-700 font-medium">
              Awaiting Admin Approval
            </h1>
          </div>
        )}

      {/* <ComponentSwitch
        components={ProfileRegistrationForms}
        activeKey={userStore.user.account_type}
      /> */}
    </>
  );
}
