import DashboardAlert from "@/components/alerts/DashboardAlert";
import { useStore } from "@/contexts/userStore";
import React from "react";
import DashboardView from "./components/DashboardView";
import DashboardHeader from "@/components/ui/dashboard-header";

export default function Dashboard() {
  const userStore = useStore((state) => state);
  return (
    <div className="w-full min-h-[70vh] ">
      {userStore.user.registration_status == "verified" && (
        <DashboardHeader
          title={`Hello ${userStore.user.name}`}
          caption="Welcome to your dashboard"
        />
      )}

      {userStore.user.profile_updated && <DashboardView />}
    </div>
  );
}
