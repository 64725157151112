import GeneralRegistrationPaymentHistoryTable from "@/components/tables/generalRegistrationPaymentHistoryTable";
import RegistrationPaymentHistoryTable from "@/components/tables/registrationPaymentHistoryTable";
import { useStore } from "@/contexts/userStore";
import React from "react";

export default function PaymentPage() {
  const SECTION_NAME = "Profile Management";
  const MODULE_NAME = "Registrations Payments";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms.find((perm) => perm.module === SECTION_NAME);
  return (
    <div>
      <GeneralRegistrationPaymentHistoryTable />
    </div>
  );
}
