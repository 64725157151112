import { ClearanceApplicationData } from "@/lib/interfaces";

export const clearanceApplications: ClearanceApplicationData[] = [
  {
    id: "1",
    name: "John Doe",
    importerCategory: "individual",
    applicationDate: "2023-01-15",
    importerEmail:"",
    status: "NEW",
  },
  {
    id: "2",
    name: "XYZ Corp",
    importerCategory: "company",
    applicationDate: "2023-02-20",
    importerEmail:"",
    status: "Approved",
  },
  {
    id: "3",
    name: "Government Agency",
    importerCategory: "government",
    applicationDate: "2023-03-10",
    importerEmail:"",
    status: "Disapproved",
  },
  // ... add more entries as needed ...
];
