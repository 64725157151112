import ClearanceInvoice from "@/components/docs/invoice";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import {
  Select,
  SelectItem,
  SelectValue,
  SelectTrigger,
  SelectContent,
} from "@/components/ui/select";
import { useStore } from "@/contexts/userStore";
import { cn, getBackendUrl } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { pdf, PDFViewer } from "@react-pdf/renderer";
import axios from "axios";
import { format } from "fecha";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";
import { saveAs } from "file-saver";

const PAYMENT_CYCLES = [
  "1 week",
  "2 weeks",
  "1 month",
  "2 months",
  "3 months",
  "6 months",
  "1 year",
  "2 years",
];

export default function GenerateInvoiceForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const generateInvoiceSchema = z.object({
    id: z.string().min(1),
    email: z.string().email(),
    amount: z.number().min(1),
    description: z.string().min(1),
    name: z.string().min(1),
    address: z.string().min(1),
    // paymentCycle: z.enum([
    //   "1 week",
    //   "2 weeks",
    //   "1 month",
    //   "2 months",
    //   "3 months",
    //   "6 months",
    //   "1 year",
    //   "2 years",
    // ]),
  });
  const form = useForm<z.infer<typeof generateInvoiceSchema>>({
    resolver: zodResolver(generateInvoiceSchema),
    defaultValues: {
      id: "",
      email: "",
      amount: 0,
      description: "",
      name: "",
      address: "",
      // paymentCycle: "1 week",
    },
  });

  type formType = z.infer<typeof generateInvoiceSchema>;

  const user = useStore((state) => state.user);

  const handleSendInvoice = async (data: formType) => {
    setIsActionLoading(true);
    form.trigger();
    if (form.formState.isValid) {
      try {
        await axios.post(`${getBackendUrl()}/generate-new-invoice`, {
          nesrea_id: data.id,
          email: data.email,
          amount: `${data.amount}`,
          description: data.description,
          created_by: user.email,
          name: data.name,
        });
        setIsActionLoading(false);
        toast.success("Invoice generated successfully.");
        form.reset();
      } catch (error) {
        console.error(error);
        setIsActionLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  const Generated_Invoice = () => (
    <ClearanceInvoice
      hasPaid={false}
      import_items={[]}
      invoiceNumber={``}
      invoiceDate={format(new Date(), "mediumDate")}
      name={form.getValues("name")}
      address={form.getValues("address")}
      waiverReduction={0}
      costOverride={form.getValues("amount")}
    />
  );
  return (
    <LoaderWrapper isLoading={isLoading}>
      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(() => {})}
        >
          <FormField
            control={form.control}
            name="id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  NESREA ID <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="ID" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Name <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Name" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Email <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Email" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Address <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Address" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Amount <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    onChange={(e) => field.onChange(Number(e.target.value))}
                    placeholder="Amount"
                    type="number"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Description <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Input {...field} placeholder="Description" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <FormField
            control={form.control}
            name="paymentCycle"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Payment Cycle <span className="text-red-500">*</span>
                </FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    onValueChange={(val: any) => {
                      form.setValue("paymentCycle", val);
                      field.onBlur();
                      form.trigger("paymentCycle");
                    }}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Payment Cycle" />
                    </SelectTrigger>
                    <SelectContent>
                      {PAYMENT_CYCLES.map((cycle) => (
                        <SelectItem key={cycle} value={cycle}>
                          {cycle}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          /> */}
          <Dialog>
            <DialogTrigger disabled={!form.formState.isValid} asChild>
              <Button className="w-full max-w-[200px]" type="button">
                Generate Invoice
              </Button>
            </DialogTrigger>
            <DialogContent>
              <PDFViewer className="w-full aspect-[1/1.414]">
                <Generated_Invoice />
              </PDFViewer>
              <Button
                type="submit"
                disabled={isActionLoading}
                onClick={() => handleSendInvoice(form.getValues())}
                className="w-full max-w-[80%] mx-auto"
              >
                Send
              </Button>
              <Button
                variant={"outline"}
                className={cn("w-full max-w-[80%] mx-auto border-none")}
                onClick={async () => {
                  try {
                    const blob = await pdf(<Generated_Invoice />).toBlob();
                    saveAs(blob, "Environmental_Import_Clearance_Invoice.pdf");
                    toast.success("Saved Successfully.");
                  } catch (error) {
                    toast.error("An error occurred.");
                  }
                }}
              >
                Download
              </Button>
            </DialogContent>
          </Dialog>
        </form>
      </Form>
    </LoaderWrapper>
  );
}
