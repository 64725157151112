import React, { useEffect } from "react";
import { FormControl, FormMessage } from "@/components/ui/form";

import { FormItem, FormLabel } from "@/components/ui/form";

import { FormField } from "@/components/ui/form";

import { useForm } from "react-hook-form";
import {
  company_form_schema_page_1,
  company_form_schema_page_1_type,
} from "../_schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

export const CompanyFirstForm = ({
  handleSubmit,
}: {
  handleSubmit: (values: company_form_schema_page_1_type) => void;
}) => {
  const form = useForm<company_form_schema_page_1_type>({
    resolver: zodResolver(company_form_schema_page_1),
    defaultValues: {
      companyName: "",
      companyRepresentative: "",
      companyAddress: "",
      tin: "",
      association: "",
    },
  });

  useEffect(() => {
    const subscription = form.watch((value, { name }) => {
      if (name) {
        form.trigger(name);
      }
    });
    return () => subscription.unsubscribe();
  }, [form]);

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <FormField
          control={form.control}
          name="companyName"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Company Name <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your company name"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="companyRepresentative"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Company Representative{" "}
                <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your company representative phone number"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="companyAddress"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                Company Address <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your company address"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="association"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>Association</FormLabel>
              <FormControl>
                <Input
                  type="text"
                  placeholder="Enter your association"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="w-full py-3 border-b mb-2">
          <h3 className="font-medium">Government ID</h3>
          <p className="text-xs font-light text-neutral-600">
            Please enter your <strong>TIN</strong> or <strong>CAC</strong> for
            Identification.
          </p>
        </div>
        <FormField
          control={form.control}
          name="tin"
          render={({ field }) => (
            <FormItem className="w-full flex flex-col gap-2">
              <FormLabel>
                TIN <span className="text-destructive">*</span>
              </FormLabel>
              <FormControl>
                <Input type="number" placeholder="Enter your TIN" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          className="w-full max-w-[164px]"
          disabled={!form.formState.isValid}
        >
          Next
        </Button>
      </form>
    </Form>
  );
};
