import ApplicationsForm from "@/components/forms/ApplicationsForm";
import { useStore } from "@/contexts/agentStore";
import React, { useEffect } from "react";

export default function AgentAddApplicationPage() {
  const agentStore = useStore((state) => state)
  return (
    <div className="w-full">
      <ApplicationsForm />
    </div>
  );
}
