import React, { useEffect, useState } from "react";
import { UserCategoryDataTable } from "./data-table";
import { UserCategoryColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { Switch } from "@/components/ui/switch";
import { userCategoryData } from "@/lib/dummy-data/userCategoryData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { Toggle } from "@/components/ui/toggle";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { FilterData } from "@/components/ui/filter";

export type UserCategories = "agent" | "individual" | "company" | "government";

export default function UserCategoryTable({
  category,
}: {
  category: UserCategories;
}) {
  const agentStore = useStore((state) => state);
  const [showInactive, setShowInactive] = useState(false);
  const [activeStatus, setActiveStatus] = useState("approved");
  const { data, isLoading } = useQuery({
    queryKey: ["account-registrations"],
    queryFn: async () => {
      try {
        const registered_accounts: {
          name: string;
          id: string;
          dateCreated: string;
          lastUpdated: string;
          lastActive: string;
          category: string;
          status: string;
          email: string;
        }[] = [];
        const { data } = await axios.get(
          `${getBackendUrl()}/all-registered-account`
        );
        data.allagent.map((user: any) => {
          registered_accounts.push({
            name: user.agent_name,
            id: user.agent_id,
            dateCreated: user.created_at,
            lastUpdated: user.updated_at,
            lastActive: "",
            category: "agent",
            status: user.registration_status,
            email: user.email,
          });
        });
        data.alluser.map((user: any) => {
          registered_accounts.push({
            name: user.importer_name,
            id: user.nesrea_id,
            dateCreated: user.created_at,
            lastUpdated: user.updated_at,
            lastActive: "",
            category: user.importer_category,
            status: user.status,
            email: user.email,
          });
        });
        return registered_accounts;
      } catch (error) {}
    },
  });

  const filteredData = data
    ?.filter((item) => item.category == category)
    .filter((item) => {
      if (activeStatus !== "inactive") {
        return (
          item?.status?.toLowerCase() === activeStatus
        );
      }
      const lastUpdatedDate = new Date(item.lastUpdated);
      const currentDate = new Date();
      const differenceInDays =
        (currentDate.getTime() - lastUpdatedDate.getTime()) /
        (1000 * 3600 * 24);
      return differenceInDays > 730;
    });

    const [data_, setData_] = React.useState<FilterData>([]);
    const [filteredData_, setFilteredData] = React.useState<FilterData>([]);
    const filteredIDs = filteredData_.map((d) => d.id);

    const updatedData = React.useMemo(() => {
      return filteredData?.filter((stat: any) => filteredIDs.includes(stat.id)) || [];
    }, [filteredData, filteredIDs]);

    React.useEffect(() => {
      const newData = data?.map((item: any) => ({
        id: item.id,
        name: item.name,
        title: item.title,
        date: item.date,
        status: item.status,
      }));
      setData_(newData || []);
      if (!filteredData_.length) {
        setFilteredData(newData || []);
      }
    }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      {/* <div className="flex items-center mb-4">
        <Switch checked={showInactive} onCheckedChange={setShowInactive} />
        <span className="ml-2">
          {showInactive
            ? "Show Only Active Accounts"
            : "Show Only Inactive Accounts"}
        </span>
      </div> */}
      <RadioGroup
        className="flex items-center gap-5"
        defaultValue={activeStatus}
        onValueChange={(e) => {
          setActiveStatus(e);
        }}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="approved" id="r1" />
          <Label className="cursor-pointer" htmlFor="r1">
            Active Accounts
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="deactivated" id="r2" />
          <Label className="cursor-pointer" htmlFor="r2">
            Deactivated Accounts
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="inactive" id="r3" />
          <Label className="cursor-pointer" htmlFor="r3">
            Inactive Accounts
          </Label>
        </div>
      </RadioGroup>
      <UserCategoryDataTable
        category={category}
        showInactive={showInactive}
        columns={UserCategoryColumns}
        data={updatedData || []}
        setData_={setData_}
        filteredData={filteredData_}
        setFilteredData={setFilteredData}
        data_={data_}
      />
    </LoaderWrapper>
  );
}
