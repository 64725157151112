import React from "react";
import RevenueTopImportCategories from "../../dashboard/components/RevenueTopImportCategories";
import WaiverRecordsChart from "../../accounting/components/WaiverRecordsChart";
import RevenueByCategory from "../../accounting/components/RevenueByCategory";
import RevenueByState from "../../accounting/components/RevenueByState";
import Invoicing from "../../accounting/components/Invoicing";
import RevenueByImporterCategoryReport from "@/components/reports/RevenueByImporterCategory";
import { useStore } from "@/contexts/userStore";

export default function AccountingAnalyticsPage() {
  const SECTION_NAME = "Reports";
  const MODULE_NAME = "Accounting";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === MODULE_NAME);

  if (!permission_data || !permission_data.isAllowed || !perm?.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-10">
      {perm?.permissions.includes("view:revenue-of-top-import-categories") && (
        <RevenueTopImportCategories />
      )}
      {perm?.permissions.includes("view:waiver-records") && (
        <WaiverRecordsChart />
      )}
      {perm?.permissions.includes("view:revenue-by-category") && (
        <RevenueByCategory />
      )}
      {perm?.permissions.includes("view:revenue-by-state") && (
        <RevenueByState />
      )}
      {perm?.permissions.includes("view:invoicing") && <Invoicing />}

      {/* // TODO: REVENUE BY IMPORTER CATEGORY */}
      {perm?.permissions.includes("view:revenue-by-importer-category") && (
        <RevenueByImporterCategoryReport />
      )}
      {/* // TODO: CLEARANCE PAYMENTS CHART */}
    </div>
  );
}
