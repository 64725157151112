import React from "react";
import { DataTable } from "./data-table";
import { FalsifiedRecordsColumn } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { falsifiedRecords } from "@/lib/dummy-data/falsifiedRecordsData";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import { FilterData } from "@/components/ui/filter";

export default function FalsifiedRecordsTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["falsified-records"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-all-report-certificates`
      );
      return data?.reportcerts || [];
    },
  });
    const [data_, setData_] = React.useState<FilterData>([]);
    const [filteredData, setFilteredData] = React.useState<FilterData>([]);
    const filteredIDs = filteredData.map((d) => d.id);

    const updatedData = React.useMemo(() => {
      return (
        data?.filter((stat: any) => filteredIDs.includes(stat.report_id)) || []
      );
    }, [data, filteredIDs]);

    React.useEffect(() => {
      const newData = data?.map((item: any) => ({
        id: item.report_id,
        nesrea_id: item.nesrea_id,
        date: item.created_at,
        status: item.status,
      }));
      setData_(newData || []);
      if (!filteredData.length) {
        setFilteredData(newData || []);
      }
    }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={FalsifiedRecordsColumn}
        data={data || []}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
      />
    </LoaderWrapper>
  );
}
