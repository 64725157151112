import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React, { forwardRef } from "react";
import { Link } from "react-router-dom";

const ProcessItems = [
  {
    title: "Visit the Verification Page",
    description: `Click on the "Verify Certificate" button below to navigate to the verification page.`,
  },
  {
    title: "Enter Certificate Details",
    description:
      "In the provided form, enter the certificate number and any other required details, such as the Certificate number.",
  },
  {
    title: "Submit for Verification",
    description: `Click "Verify" to initiate the verification process. The system will check the certificate details against the NESREA database.`,
  },
  {
    title: "Review Verification Status",
    description: `After submission, view the verification result on your screen. It will display either "Verified" (authentic) or "Unverified" (not recognized by NESREA).`,
  },
  {
    title:
      "Report unverified Certificates",
    description: `If the certificate is unverified, please reach out to NESREA's support team for assistance and Report unverified Certificates in the report section`,
  },
];

const VerificationProcess = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div id="verifycertificate" className="w-full">
      <div
        ref={ref}
        className="w-full h-fit py-32 bg-primary-50 flex flex-col gap-5 items-center text-center justify-center relative z-10"
      >
          <h1 className="text-5xl text-primary-700 font-semibold">
            Verify Your Certificates with Ease
          </h1>
          <p className="opacity-70 text-lg max-w-lg text-primary-700">
            Ensure the validity of your import clearance certificates in just a
            few clicks
          </p>
        <Link
          to={"/verify-certificate"}
          className={cn(
            buttonVariants({ variant: "default" }),
            "w-fit text-lg px-10 font-normal rounded-full"
          )}
        >
          Verify Now
        </Link>
      </div>
    </div>
  );
});

export default VerificationProcess;
