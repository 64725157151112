import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { Invoice, WaiverRequest } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { format } from "fecha";

export const InvoiceColumns: ColumnDef<Invoice>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          SN
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  // {
  //   accessorKey: "importerName",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Importer Name
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "importerCategory",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Import Category
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const { importerCategory } = row.original;
  //     return <p className="capitalize">{importerCategory}</p>;
  //   },
  // },
  {
    accessorKey: "invoiceNumber",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Invoice Number
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "invoiceDate",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { invoiceDate } = row.original;
      let formattedDate = format(new Date(invoiceDate), "mediumDate");
      return <p>{formattedDate}</p>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPaid = status.toLowerCase() == "paid";
      let isUnpaid = status.toLowerCase() == "unpaid";
      return (
        <p
          className={cn(
            isPaid && "text-primary-300",
            isUnpaid && "text-orange-400",
            "font-semibold capitalize"
          )}
        >
          {status}
        </p>
      );
    },
  },
  // {
  //   accessorKey: "action",
  //   header: ({ column }) => {
  //     return (
  //       <div className="flex items-center">
  //         Action
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <div className="grid gap-7">
  //         <Link
  //           className="underline text-primary-500 text-sm"
  //           to={`/admin/accounting/invoicing/details?id=${row.original.id}`}
  //         >
  //           View
  //         </Link>
  //       </div>
  //     );
  //   },
  // },
];
