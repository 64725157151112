import React from "react";
import ApplicationsTable from "./ApplicationsTable";
import DashboardHeader from "@/components/ui/dashboard-header";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { AllClearanceApplicationsTableData } from "../ImporterView/all-clearance-applications-table/_interfaces";
import { useStore } from "@/contexts/userStore";

export default function AgentApplicationsView() {
  const userStore = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["clearance-applications"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/get-clearance-applications/${userStore.email}`
      );
      const formattedData: AllClearanceApplicationsTableData[] =
        data?.UserAppList.map((item: any) => ({
          id: item.clearance_id,
          name: item.importer_name,
          category: item.importer_category,
          dateCreated: item.created_at,
          status: item.status,
          payment_status: item.payment_status,
        }));
      return formattedData;
    },
  });
  return (
    <>
      <DashboardHeader
        title="Import Clearance Certificate"
        caption="Welcome to the application process"
      />
      <div className="flex flex-col gap-4">
        <ApplicationsTable data={data || []} />
      </div>
    </>
  );
}
