import ClientsForm from "@/components/forms/ClientsForm";
import DashboardModal from "@/components/modals/DashboardModal";
import React, { useEffect, useState } from "react";
import ClientsView from "./components/ClientsView";
import { useNavigate } from "react-router-dom";
import { useStore } from "@/contexts/agentStore";
import { useStore as useUserStore } from "@/contexts/userStore";
import { clientsData } from "@/lib/dummy-data/clientsData";
import DashboardHeader from "@/components/ui/dashboard-header";

export default function ClientsPage() {
  const [showModal, setShowModal] = useState(false);
  const agentStore = useStore((state) => state);
  useEffect(() => {
    agentStore.clearSelectedClients();
  }, []);
  const userStore = useUserStore();
  const { registration_status } = userStore.user;
  return registration_status == "verified" ? (
    <div>
      {showModal && (
        <DashboardModal
          title="Profile Update Successful"
          description="It looks like you haven't added any client details yet. This information is required to proceed with your application."
          cta={{
            txt: "Add Client Details",
            onClick: () => {
              const navigate = useNavigate();
              navigate("/user/clients/add-clients");
              setShowModal(false);
            },
          }}
        />
      )}

      <DashboardHeader
        title="Agent Clients"
        caption="View all clients created."
      />

      <ClientsView />
    </div>
  ) : (
    <></>
  );
}
