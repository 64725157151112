import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import { buttonVariants } from "../ui/button";

export default function DashboardAlert({
  title,
  description,
  cta,
}: {
  title: string;
  description: string;
  cta?: { txt: string; href: string };
}) {
  return (
    <div className="w-full max-w-xl bg-secondary-50 rounded-xl p-5 text-center flex flex-col items-center gap-9">
      <h4 className="font-medium text-lg">{title}</h4>
      <p>{description}</p>
      {cta && (
        <Link
          to={cta.href}
          className={cn(buttonVariants({ variant: "default" }), "max-w-[200px] text-xs w-full")}
        >
          {cta.txt}
        </Link>
      )}
    </div>
  );
}
