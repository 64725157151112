import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface WaiverRequest {
  id: number;
  waiver_id: string;
  clearance_id: string;
  nesrea_id: string;
  importer_name: string;
  importer_email: string;
  importer_category: string;
  amount: number | null;
  item: string;
  waiver_percentage: string;
  waiver_request_comment: string;
  waiver_application_letter: string;
  document_name: string;
  document_url: string;
  waiver_code: string;
  validity_period: string;
  status: string;
  approved_percentage: string;
  new_amount: string;
  action_taken_waiver: string;
  waiver_approved_by: string;
  waiver_approval_comment: string;
  waiver_approval_date: string;
  activities: string;
  created_by: string;
  updated_at: string;
  created_at: string;
}

interface WaiverAnalytics {
  totalWaiverValue: number;
  totalIssuedWaivers: number;
  totalRejectedWaivers: number;
}

const processWaiverData = (data: WaiverRequest[]): WaiverAnalytics => {
  let totalWaiverValue = 0;
  let totalIssuedWaivers = 0;
  let totalRejectedWaivers = 0;

  data.forEach((waiver) => {
    if (waiver.status === "APPROVED") {
      totalIssuedWaivers++;
      totalWaiverValue += parseFloat(waiver.new_amount || '0');
    } else if (waiver.status === "REJECTED") {
      totalRejectedWaivers++;
    }
  });

  return {
    totalWaiverValue,
    totalIssuedWaivers,
    totalRejectedWaivers,
  };
};

export const useWaiverAnalytics = () => {
  const { data, isLoading } = useQuery<WaiverRequest[]>({
    queryKey: ["waiver-analytics"],
    queryFn: async () => {
      const { data } = await axios.get(
        `${getBackendUrl()}/all-waiver-application`
      );
      return data.allWaiverrequest;
    },
  });

  return {
    data: data ? processWaiverData(data) : null,
    isLoading,
  };
};
