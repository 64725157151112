import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import RecentActivities from "./RecentActivities.tsx";
import { useStore } from "@/contexts/userStore.js";

export default function DashboardView() {
  const userStore = useStore();
  const { registration_status } = userStore.user;
  return (
    <div className="w-full flex flex-col gap-9">
      {registration_status == "verified" && (
        <div className="w-full rounded-2xl py-5 px-8 flex items-center text-primary-foreground justify-between banner-gradient">
          <h4 className="text-xl font-medium">
            Apply for an Import Clearance Certificate
          </h4>

          <Link
            to={"/user/application"}
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-white text-primary-700 hover:bg-white/90 w-full max-w-[175px]"
            )}
          >
            Apply Here
          </Link>
        </div>
      )}
      <RecentActivities />
    </div>
  );
}
