import React, { useState } from "react";
import ImporterApplicationForm from "./_forms/application-form";

export default function ApplyImporterPage() {
  return (
    <div>
      <ImporterApplicationForm />
    </div>
  );
}
