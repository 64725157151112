import { useStore } from "@/contexts/userStore";
import { isLoggedIn, logout } from "@/lib/auth";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export const ProtectedLayout = () => {
  const userStore = useStore((state) => state);
  const navigate = useNavigate();
  const [isMounted, setIsMounted] = useState(false);
  const userCacheString = localStorage.getItem("nesrea_user");
  const timeCacheString = localStorage.getItem("nesrea_session_expiry");
  const pathname = window.location.pathname;

  let isAdminRoute = pathname.includes("/admin");
  let isUserRoute = pathname.includes("/user");

  useEffect(() => {
    if (!userCacheString) {
      if (isAdminRoute) navigate("/admin");
      if (isUserRoute) navigate("/user");
      return;
    }

    if (!timeCacheString) {
      if (isAdminRoute) navigate("/admin");
      if (isUserRoute) navigate("/user");
      return;
    }

    const hasExpired = Number(timeCacheString) < new Date().getTime();
    const userCache = userCacheString ? JSON.parse(userCacheString) : undefined;

    if (hasExpired) {
      if (userCache) {
        logout(userStore.clearUser, navigate);
        return;
      }
      if (isAdminRoute) navigate("/admin");
      if (isUserRoute) navigate("/user");
      return;
    }

    isLoggedIn().then((isValid) => {
      if (!isValid) {
      }
      if (userCache) {
        userStore.setUser(userCache);
      }
      setIsMounted(true);
    });
  }, []);
  return isMounted ? <Outlet /> : <></>;
};
