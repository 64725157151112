import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { format } from "fecha";
import { toast } from "sonner";

export interface WaiverRequestsTableData {
  id: string;
  item: string;
  percentage: number;
  date: string;
  status: "NEW" | "Approved" | "Disapproved";
  waiver_code?: string;
  applied: boolean;
}

export const ClientsColumns: ColumnDef<WaiverRequestsTableData>[] = [
  {
    accessorKey: "sn",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          SN
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      return <p>{row.index + 1}</p>;
    },
  },
  {
    accessorKey: "id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Application ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "clearance_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Clearance ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "item",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Item
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "percentage",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount (%)
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "date",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { date } = row.original;
      let formattedDate = formatDate(date);
      return <p>{format(new Date(date), "mediumDate")}</p>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPending = status.toLowerCase() == "NEW".toLowerCase();
      let isApproved = status.toLowerCase() == "Approved".toLowerCase();
      let isRejected = status.toLowerCase() == "Disapproved".toLowerCase();

      const status_dict: { [key: string]: string } = {
        new: "Pending",
        approved: "Approved",
        disapproved: "Rejected",
      };
      return (
        <p
          className={cn(
            "capitalize",
            isPending && "text-orange-400",
            isApproved && "text-primary-300",
            isRejected && "text-destructive"
          )}
        >
          {status_dict[status.toLowerCase()]}
        </p>
      );
    },
  },
  {
    accessorKey: "action",
    header: ({ column }) => {
      return (
        <div className="flex items-center">
          Action
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { waiver_code } = row.original;
      const isPending = row.original.status == "NEW";
      return (
        <Dialog>
          <DialogTrigger
            disabled={isPending}
            className={cn(
              "underline text-primary-700 text-sm",
              isPending && "cursor-not-allowed text-muted-500"
            )}
          >
            View
          </DialogTrigger>
          <DialogContent>
            <div className="flex flex-col items-center text-center gap-5">
              <p className="text-lg text-primary-700">
                {"Here's"} your waiver code for Application 1
              </p>
              <h4 className="text-lg font-semibold text-primary-700 text-center">
                {waiver_code || "N/A"}
              </h4>
              <DialogClose className="w-full">
                <Button
                  disabled={!Boolean(waiver_code)}
                  onClick={
                    Boolean(waiver_code)
                      ? async () => {
                          try {
                            await navigator.clipboard.writeText(
                              waiver_code || ""
                            ); // Use Clipboard API
                            toast.success("Text copied to clipboard!");
                          } catch (err) {
                            console.error("Failed to copy text: ", err);
                          }
                        }
                      : () => {}
                  }
                  className="w-full gap-2 flex items-center"
                >
                  <svg
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.5 13.3333H3.83333C2.9496 13.3323 2.10237 12.9807 1.47748 12.3559C0.852588 11.731 0.501059 10.8837 0.5 10L0.5 3.33333C0.501059 2.4496 0.852588 1.60237 1.47748 0.97748C2.10237 0.352588 2.9496 0.00105857 3.83333 0L10.5 0C11.3837 0.00105857 12.231 0.352588 12.8559 0.97748C13.4807 1.60237 13.8323 2.4496 13.8333 3.33333V10C13.8323 10.8837 13.4807 11.731 12.8559 12.3559C12.231 12.9807 11.3837 13.3323 10.5 13.3333ZM3.83333 1.33333C3.3029 1.33333 2.79419 1.54405 2.41912 1.91912C2.04405 2.29419 1.83333 2.8029 1.83333 3.33333V10C1.83333 10.5304 2.04405 11.0391 2.41912 11.4142C2.79419 11.7893 3.3029 12 3.83333 12H10.5C11.0304 12 11.5391 11.7893 11.9142 11.4142C12.2893 11.0391 12.5 10.5304 12.5 10V3.33333C12.5 2.8029 12.2893 2.29419 11.9142 1.91912C11.5391 1.54405 11.0304 1.33333 10.5 1.33333H3.83333ZM16.5 12.6667V4C16.5 3.82319 16.4298 3.65362 16.3047 3.5286C16.1797 3.40357 16.0101 3.33333 15.8333 3.33333C15.6565 3.33333 15.487 3.40357 15.3619 3.5286C15.2369 3.65362 15.1667 3.82319 15.1667 4V12.6667C15.1667 13.1971 14.956 13.7058 14.5809 14.0809C14.2058 14.456 13.6971 14.6667 13.1667 14.6667H4.5C4.32319 14.6667 4.15362 14.7369 4.0286 14.8619C3.90357 14.987 3.83333 15.1565 3.83333 15.3333C3.83333 15.5101 3.90357 15.6797 4.0286 15.8047C4.15362 15.9298 4.32319 16 4.5 16H13.1667C14.0504 15.9989 14.8976 15.6474 15.5225 15.0225C16.1474 14.3976 16.4989 13.5504 16.5 12.6667Z"
                      fill="white"
                    />
                  </svg>
                  Copy
                </Button>
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      );
    },
  },
];
