import { userStoreInitialState } from "@/contexts/userStore";
import { stateLabels } from "@/lib/site-data";
import { getBackendUrl } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "fecha";
import { useState } from "react";

const days = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export const usePaymentAnalytics = () => {
  let payments_data: {
    amount: number;
    email: string;
    type: "registration" | "clearance";
    payment_date: string;
    state: string;
    account_type: userStoreInitialState["user"]["account_type"];
  }[] = [];
  const currentYear = new Date().getFullYear();

  const organizeDailyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const timeSlots = [
      { tag: "00:00-05:59", trend: 0 },
      { tag: "06:00-11:59", trend: 0 },
      { tag: "12:00-17:59", trend: 0 },
      { tag: "18:00-23:59", trend: 0 },
      { tag: "24:00", trend: 0 },
    ];

    payments
      .filter((item) =>
        state
          ? item.state == state &&
            format(new Date(), "mediumDate") ==
              format(new Date(item.payment_date), "mediumDate")
          : item.account_type == account_type &&
            format(new Date(), "mediumDate") ==
              format(new Date(item.payment_date), "mediumDate")
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;

        const date = new Date(payment.payment_date);
        const hours = date.getHours();

        if (hours >= 0 && hours < 6) {
          timeSlots[0].trend += payment.amount;
        } else if (hours >= 6 && hours < 12) {
          timeSlots[1].trend += payment.amount;
        } else if (hours >= 12 && hours < 18) {
          timeSlots[2].trend += payment.amount;
        } else if (hours >= 18 && hours < 24) {
          timeSlots[3].trend += payment.amount;
        } else if (hours === 24) {
          timeSlots[4].trend += payment.amount;
        }
      });

    return timeSlots;
  };

  const organizeMonthlyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const monthlyTrends = Array.from({ length: 12 }, (_, i) => ({
      tag: new Date(0, i).toLocaleString("default", { month: "long" }),
      trend: 0,
    }));

    payments
      .filter((item) =>
        state ? item.state == state : item.account_type == account_type
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;
        const date = new Date(payment.payment_date);
        const year = date.getFullYear();
        const monthIndex = date.getMonth();

        if (year === currentYear) {
          monthlyTrends[monthIndex].trend += payment.amount;
        }
      });

    return monthlyTrends;
  };

  const organizeYearlyPayments = (
    payments: typeof payments_data,
    account_type: userStoreInitialState["user"]["account_type"],
    state?: string
  ) => {
    const yearlyTrends: { [key: string]: number } = {};

    payments
      .filter((item) =>
        state ? item.state == state : item.account_type == account_type
      )
      .forEach((payment) => {
        if (!payment.payment_date) return;

        const date = new Date(payment.payment_date);
        const year = date.getFullYear();

        if (!yearlyTrends[year]) {
          yearlyTrends[year] = 0;
        }
        yearlyTrends[year] += Number(payment.amount);
      });

    const trendsFormatted = Object.keys(yearlyTrends).map((year) => ({
      tag: year,
      trend: yearlyTrends[year],
    }));
    return trendsFormatted.length > 0
      ? trendsFormatted
      : [
          {
            tag: `${new Date().getFullYear()}`,
            trend: 0,
          },
        ];
  };

  const getTotalRevenuePerState = (payments: typeof payments_data) => {
    const states = stateLabels;
    const revenuePerState: {
      [year: string]: {
        [month: string]: {
          [state: string]: number;
        };
      };
    } = {
      [currentYear]: Object.fromEntries(
        Array.from({ length: 12 }, (_, i: number) => [
          new Date(0, i).toLocaleString("default", { month: "long" }),
          Object.fromEntries(states.map((state) => [state.toLowerCase(), 0])),
        ])
      ),
    };

    const registrationRevenuePerState: typeof revenuePerState = JSON.parse(
      JSON.stringify(revenuePerState)
    );
    const clearanceRevenuePerState: typeof revenuePerState = JSON.parse(
      JSON.stringify(revenuePerState)
    );

    payments.forEach((payment) => {
      if (!payment.state || !payment.payment_date) return;

      const normalizedState = payment.state.toLowerCase();
      const date = new Date(payment.payment_date);
      const year = date.getFullYear();
      const month = new Date(0, date.getMonth()).toLocaleString("default", {
        month: "long",
      });

      if (!revenuePerState[year]) {
        revenuePerState[year] = {};
        registrationRevenuePerState[year] = {};
        clearanceRevenuePerState[year] = {};
        // Initialize all months for the year
        Array.from({ length: 12 }, (_, i) =>
          new Date(0, i).toLocaleString("default", { month: "long" })
        ).forEach((monthName) => {
          revenuePerState[year][monthName] = {};
          registrationRevenuePerState[year][monthName] = {};
          clearanceRevenuePerState[year][monthName] = {};
          // Initialize all states for each month
          states.forEach((state) => {
            revenuePerState[year][monthName][state.toLowerCase()] = 0;
            registrationRevenuePerState[year][monthName][
              state.toLowerCase()
            ] = 0;
            clearanceRevenuePerState[year][monthName][state.toLowerCase()] = 0;
          });
        });
      }

      if (!revenuePerState[year][month]) {
        revenuePerState[year][month] = {};
        registrationRevenuePerState[year][month] = {};
        clearanceRevenuePerState[year][month] = {};
        // Initialize all states for the month
        states.forEach((state) => {
          revenuePerState[year][month][state.toLowerCase()] = 0;
          registrationRevenuePerState[year][month][state.toLowerCase()] = 0;
          clearanceRevenuePerState[year][month][state.toLowerCase()] = 0;
        });
      }

      revenuePerState[year][month][normalizedState] += payment.amount;

      if (payment.type === "registration") {
        registrationRevenuePerState[year][month][normalizedState] +=
          payment.amount;
      } else if (payment.type === "clearance") {
        clearanceRevenuePerState[year][month][normalizedState] +=
          payment.amount;
      }
    });

    return {
      totalRevenuePerState: revenuePerState,
      totalRegistrationRevenuePerState: registrationRevenuePerState,
      totalClearanceRevenuePerState: clearanceRevenuePerState,
    };
  };

  const { data: all_payments, isLoading } = useQuery({
    queryKey: ["payment-analytics"],
    queryFn: async () => {
      const { data } = await axios.get(`${getBackendUrl()}/all-payment`);

      payments_data = [];

      data?.allagent.map((agent_item: any) => {
        payments_data.push({
          amount: Number(agent_item.amount),
          email: agent_item.email,
          type: "clearance",
          account_type: "agent",
          state: agent_item.state,
          payment_date: agent_item.payment_date,
        });
      });
      data?.alluser.map((importer_item: any) => {
        payments_data.push({
          amount: Number(importer_item.amount),
          email: importer_item.email,
          type: "clearance",
          payment_date: importer_item.payment_date,
          state: importer_item.state,
          account_type: importer_item.importer_category,
        });
      });

      const monthlyTrends: {
        [key: string]: { month: string; trend: number }[];
      } = {
        [currentYear]: Array.from({ length: 12 }, (_, i) => ({
          month: new Date(0, i).toLocaleString("default", { month: "long" }),
          trend: 0,
        })),
      };

      const yearlyTrends: { [key: string]: number } = {
        [currentYear]: 0,
      };

      const dailyTrends: { [key: string]: { tag: string; trend: number }[] } = {
        [currentYear]: days.map((day) => ({ tag: day, trend: 0 })),
      };

      const clearanceTrends: {
        daily: typeof dailyTrends;
        monthly: typeof monthlyTrends;
        yearlyData: typeof yearlyTrends;
        yearly: { year: string; trend: number }[];
      } = {
        daily: JSON.parse(JSON.stringify(dailyTrends)),
        monthly: JSON.parse(JSON.stringify(monthlyTrends)),
        yearlyData: JSON.parse(JSON.stringify(yearlyTrends)),
        yearly: [],
      };

      const registrationTrends: {
        daily: typeof dailyTrends;
        monthly: typeof monthlyTrends;
        yearlyData: typeof yearlyTrends;
        yearly: { year: string; trend: number }[];
      } = {
        daily: JSON.parse(JSON.stringify(dailyTrends)),
        monthly: JSON.parse(JSON.stringify(monthlyTrends)),
        yearlyData: JSON.parse(JSON.stringify(yearlyTrends)),
        yearly: [],
      };

      payments_data.forEach((payment) => {
        if (!payment.payment_date) {
          return;
        }

        const date = new Date(payment.payment_date);
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        const dayIndex = date.getDay();
        const monthName = new Date(0, monthIndex).toLocaleString("default", {
          month: "long",
        });

        // Update daily trends
        if (!dailyTrends[year]) {
          dailyTrends[year] = days.map((day) => ({ tag: day, trend: 0 }));
        }
        dailyTrends[year][dayIndex].trend += payment.amount;

        // Update monthly trends
        if (!monthlyTrends[year]) {
          monthlyTrends[year] = Array.from({ length: 12 }, (_, i) => ({
            month: new Date(0, i).toLocaleString("default", { month: "long" }),
            trend: 0,
          }));
        }
        monthlyTrends[year][monthIndex].trend += payment.amount;

        // Update yearly trends
        if (!yearlyTrends[year]) {
          yearlyTrends[year] = 0;
        }
        yearlyTrends[year] += payment.amount;

        // Update clearance and registration trends
        if (payment.type === "clearance") {
          clearanceTrends.daily[year][dayIndex].trend += payment.amount;
          clearanceTrends.monthly[year][monthIndex].trend += payment.amount;
          clearanceTrends.yearlyData[year] += payment.amount;
        } else if (payment.type === "registration") {
          registrationTrends.daily[year][dayIndex].trend += payment.amount;
          registrationTrends.monthly[year][monthIndex].trend += payment.amount;
          registrationTrends.yearlyData[year] += payment.amount;
        }
      });

      const yearlyTrendsData = Object.keys(yearlyTrends).map((year) => ({
        year,
        trend: yearlyTrends[year],
      }));

      clearanceTrends.yearly = Object.keys(clearanceTrends.yearlyData).map(
        (year) => ({
          year,
          trend: clearanceTrends.yearlyData[year],
        })
      );

      registrationTrends.yearly = Object.keys(
        registrationTrends.yearlyData
      ).map((year) => ({
        year,
        trend: registrationTrends.yearlyData[year],
      }));

      return {
        dailyTrends,
        monthlyTrends,
        yearlyTrendsData,
        clearanceTrends,
        registrationTrends,
        payments_data,
        totalRevenuePerState: getTotalRevenuePerState(payments_data),
      };
    },
  });

  if (all_payments) {
    const filterPaymentsByType = (type: "registration" | "clearance") =>
      all_payments.payments_data.filter((payment) => payment.type === type);

    return {
      charts: {
        all: {
          monthly: all_payments.monthlyTrends,
          yearly: all_payments.yearlyTrendsData,
          state: all_payments.totalRevenuePerState,
        },
        agent: {
          daily: {
            total: organizeDailyPayments(all_payments.payments_data, "agent"),
            clearance: organizeDailyPayments(
              filterPaymentsByType("clearance"),
              "agent"
            ),
            registration: organizeDailyPayments(
              filterPaymentsByType("registration"),
              "agent"
            ),
          },
          monthly: {
            total: organizeMonthlyPayments(all_payments.payments_data, "agent"),
            clearance: organizeMonthlyPayments(
              filterPaymentsByType("clearance"),
              "agent"
            ),
            registration: organizeMonthlyPayments(
              filterPaymentsByType("registration"),
              "agent"
            ),
          },
          yearly: {
            total: organizeYearlyPayments(all_payments.payments_data, "agent"),
            clearance: organizeYearlyPayments(
              filterPaymentsByType("clearance"),
              "agent"
            ),
            registration: organizeYearlyPayments(
              filterPaymentsByType("registration"),
              "agent"
            ),
          },
        },
        company: {
          daily: {
            total: organizeDailyPayments(all_payments.payments_data, "company"),
            clearance: organizeDailyPayments(
              filterPaymentsByType("clearance"),
              "company"
            ),
            registration: organizeDailyPayments(
              filterPaymentsByType("registration"),
              "company"
            ),
          },
          monthly: {
            total: organizeMonthlyPayments(
              all_payments.payments_data,
              "company"
            ),
            clearance: organizeMonthlyPayments(
              filterPaymentsByType("clearance"),
              "company"
            ),
            registration: organizeMonthlyPayments(
              filterPaymentsByType("registration"),
              "company"
            ),
          },
          yearly: {
            total: organizeYearlyPayments(
              all_payments.payments_data,
              "company"
            ),
            clearance: organizeYearlyPayments(
              filterPaymentsByType("clearance"),
              "company"
            ),
            registration: organizeYearlyPayments(
              filterPaymentsByType("registration"),
              "company"
            ),
          },
        },
        government: {
          daily: {
            total: organizeDailyPayments(
              all_payments.payments_data,
              "government"
            ),
            clearance: organizeDailyPayments(
              filterPaymentsByType("clearance"),
              "government"
            ),
            registration: organizeDailyPayments(
              filterPaymentsByType("registration"),
              "government"
            ),
          },
          monthly: {
            total: organizeMonthlyPayments(
              all_payments.payments_data,
              "government"
            ),
            clearance: organizeMonthlyPayments(
              filterPaymentsByType("clearance"),
              "government"
            ),
            registration: organizeMonthlyPayments(
              filterPaymentsByType("registration"),
              "government"
            ),
          },
          yearly: {
            total: organizeYearlyPayments(
              all_payments.payments_data,
              "government"
            ),
            clearance: organizeYearlyPayments(
              filterPaymentsByType("clearance"),
              "government"
            ),
            registration: organizeYearlyPayments(
              filterPaymentsByType("registration"),
              "government"
            ),
          },
        },
        individual: {
          daily: {
            total: organizeDailyPayments(
              all_payments.payments_data,
              "individual"
            ),
            clearance: organizeDailyPayments(
              filterPaymentsByType("clearance"),
              "individual"
            ),
            registration: organizeDailyPayments(
              filterPaymentsByType("registration"),
              "individual"
            ),
          },
          monthly: {
            total: organizeMonthlyPayments(
              all_payments.payments_data,
              "individual"
            ),
            clearance: organizeMonthlyPayments(
              filterPaymentsByType("clearance"),
              "individual"
            ),
            registration: organizeMonthlyPayments(
              filterPaymentsByType("registration"),
              "individual"
            ),
          },
          yearly: {
            total: organizeYearlyPayments(
              all_payments.payments_data,
              "individual"
            ),
            clearance: organizeYearlyPayments(
              filterPaymentsByType("clearance"),
              "individual"
            ),
            registration: organizeYearlyPayments(
              filterPaymentsByType("registration"),
              "individual"
            ),
          },
        },
        registration: {
          monthly: all_payments.registrationTrends.monthly,
          daily: all_payments.registrationTrends.daily,
          yearly: all_payments.registrationTrends.yearly,
        },
        clearance: {
          monthly: all_payments.clearanceTrends.monthly,
          daily: all_payments.clearanceTrends.daily,
          yearly: all_payments.clearanceTrends.yearly,
        },
      },
      metrics: {
        total: {
          agent: all_payments.payments_data
            .filter((item) => item.account_type == "agent")
            .reduce((acc, item) => acc + item.amount, 0),
          company: all_payments.payments_data
            .filter((item) => item.account_type == "company")
            .reduce((acc, item) => acc + item.amount, 0),
          government: all_payments.payments_data
            .filter((item) => item.account_type == "government")
            .reduce((acc, item) => acc + item.amount, 0),
          individual: all_payments.payments_data
            .filter((item) => item.account_type == "individual")
            .reduce((acc, item) => acc + item.amount, 0),
        },
      },
      utils: {
        isLoading,
      },
    };
  }

  return {
    charts: null,
    utils: {
      isLoading,
    },
  };
};
