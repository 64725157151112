import React from "react";
import { DataTable } from "./data-table";
import { RegistrationsColumns } from "./columns";
import { useStore } from "@/contexts/userStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { registrationsData } from "@/lib/dummy-data/registrationsData";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { FilterData } from "@/components/ui/filter";

export default function RegistrationsTable({
  permissions,
}: {
  permissions?: string[];
}) {
  const user = useStore((state) => state.user);
  const { data, isLoading } = useQuery({
    queryKey: ["registrations"],
    queryFn: async () => {
      const {
        data,
      }: {
        data: {
          alluser: {
            id: number;
            nesrea_id: string;
            importer_category: string;
            importer_name: string;
            importer_representative: string | null;
            email: string;
            phone_number: string;
            tin_nin_cac: string;
            country: string;
            state: string;
            lga: string;
            street_address: string;
            association: string;
            department: string | null;
            payment_id: string | null;
            reference: string | null;
            amount: number | null;
            payment_status: string | null;
            created_by: string | null;
            status: string;
            created_at: string;
            updated_at: string;
            comments: string | null;
            activities: string | null;
            document_url: string | null;
            document_name: string | null;
            action_taken: string | null;
            confim_by_name: string | null;
            confirm_date: string | null;
            action_taken_registration: string | null;
            registration_approved_by: string | null;
            registration_approval_date: string | null;
            registration_approval_comment: string | null;
            action_taken_payment: string | null;
            payment_approved_by: string | null;
            payment_approval_date: string | null;
            payment_approval_comment: string | null;
          }[];
        };
      } = await axios.get(
        `${getBackendUrl()}/all-registered-importer-level/${user?.ops_level}`
      );
      return (
        data.alluser
          // .filter((user) => user.payment_status !== null)
          .sort(
            (a, b) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
      );
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat: any) => filteredIDs.includes(stat.id)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item: any) => ({
      id: item.id,
      value: item.value,
      category: item.importer_category,
      name: item.importer_name,
      date: item.created_at,
      created_by: item.created_by,
      status: item.status,
    }));
    setData_(newData || []);
    setFilteredData(newData || []);
  }, [data]);
  return (
    <div>
      <DataTable
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
        columns={RegistrationsColumns}
        permissions={permissions}
      />
    </div>
  );
}
