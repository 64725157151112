import React from "react";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";
import MonthlyRevenueTrends from "./MonthlyRevenueTrends";
import YearlyRevenueTrends from "./YearlyRevenueTrends";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export default function AccountingOverview() {
  const [activePaymentType, setActivePaymentType] = React.useState<
    "all" | "clearance" | "registration"
  >("all");
  return (
    <div className="w-full min-h-[40vh] shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">
          Accounting Overview
        </h1>
        <div className="flex items-center gap-5">
          <Select
            value={activePaymentType}
            onValueChange={(e) =>
              setActivePaymentType(e as "all" | "clearance" | "registration")
            }
          >
            <SelectTrigger className="w-fit capitalize">
              <SelectValue placeholder="Select payment type" />
            </SelectTrigger>
            <SelectContent>
              {["all", "clearance", "registration"].map((item) => (
                <SelectItem value={item} className="capitalize">
                  {item}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Link
            to={"/admin/reports/accounting"}
            className={cn(
              buttonVariants({ variant: "link" }),
              "p-0 underline text-xs"
            )}
          >
            View All
          </Link>
        </div>
      </div>
      <h5 className="text-primary-700 font-bold text-sm">Revenue Trends</h5>
      <div className="w-full grid grid-cols-2 gap-10">
        <div className="flex flex-col gap-5">
          <MonthlyRevenueTrends payment_type={activePaymentType} />
        </div>
        <div className="">
          <YearlyRevenueTrends payment_type={activePaymentType} />
        </div>
      </div>
    </div>
  );
}
