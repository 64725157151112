import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import React, { useEffect, useState } from "react";
import {
  individual_form_schema_page_1_type,
  individual_form_schema_page_2_type,
} from "./_schema";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { DocumentsForm } from "@/components/forms/DocumentsForm";
import { IndividualSecondForm } from "./_forms-pages/second_form_page";
import { IndividualFirstForm } from "./_forms-pages/first_form_page";
import { PaymentForm } from "@/components/ui/payment-section";
import { stateNamesMap } from "@/lib/site-data";

export const useIndividualProfileRegistrationForm = () => {
  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    state: "",
    lga: "",
    street: "",
    association: "",
    nin: "",
  });
  const [files, setFiles] = useState<File[]>([]);
  const [profileUploaded, setProfileUploaded] = useState(false);
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore.user.profile_updated) {
      setFormPage(3);
    }
  }, []);

  const firstFormHandleSubmit = (
    values: individual_form_schema_page_1_type
  ) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setFormPage((prev) => prev + 1);
  };
  const secondFormHandleSubmit = async (
    values: individual_form_schema_page_2_type
  ) => {
    setIsLoading(true);
    setFormData((prev) => {
      return { ...prev, ...values };
    });
    try {
      const individual_profile_data = {
        account_type: userStore.user.account_type,
        email: userStore.user.email,
        importer_name: formData.fullName,
        phone_number: formData.phoneNumber,
        state: stateNamesMap[formData.state] || formData.state,
        lga: formData.lga,
        street: formData.street,
        association: values.association,
        national_identity_number: Number(values.nin || "0"),
        created_by: userStore.user.email,
      };
      await axios.post(
        `${getBackendUrl()}/store-importer-information`,
        individual_profile_data
      );
      setFormPage((prev) => prev + 1);
      setIsLoading(false);

      const updated_user: userStoreInitialState["user"] = {
        ...userStore.user,
        profile_updated: true,
        phone_number: individual_profile_data?.phone_number || "",
        state: individual_profile_data?.state || "",
        association: individual_profile_data?.association || "",
        name: individual_profile_data?.importer_name || "",
        street: individual_profile_data?.street || "",
        nin: `${individual_profile_data?.national_identity_number}`,
      };
      userStore.setUser(updated_user);
      localStorage.setItem("nesrea_user", JSON.stringify(updated_user));

      toast.success("Profile data stored successfully");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const handleDocumentsUpload = async (documents: File[]) => {
    if (documents.length === 0) {
      toast.error("No document selected");
      return;
    }
    let total_mb = 0;
    documents.forEach((file) => {
      total_mb += file.size / 1024 / 1024;
    });
    if (total_mb > 3) {
      toast.error("Total file size must not be more than 3MB");
      return;
    }

    try {
      toast.success("Documents have been saved.");
      setProfileUploaded(true);
      setFiles(documents);
      setFormPage((prev) => prev + 1);
    } catch (error) {
      toast.error("An error occurred");
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      setIsLoading(true);
      const documentsFormData = new FormData();

      files.forEach((file) => {
        documentsFormData.append("files[]", file);
      });
      documentsFormData.append("account_type", userStore.user.account_type);
      documentsFormData.append("email", userStore.user.email);
      await axios.post(
        `${getBackendUrl()}/upload-importer-document`,
        documentsFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const new_user: userStoreInitialState["user"] = {
        ...userStore.user,
        registration_payment_status: "paid",
        registration_status: "pending",
        profile_updated: true,
      };
      userStore.setUser(new_user);
      localStorage.setItem("nesrea_user", JSON.stringify(new_user));
      toast.success("Profile updated successfully");
      navigate("/user/dashboard");
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <>
          <IndividualFirstForm handleSubmit={firstFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      component: (
        <>
          <IndividualSecondForm handleSubmit={secondFormHandleSubmit} />
          <div className="w-full flex justify-end text-primary-700 text-xs">
            <p>Page {formPage} of 2</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      component: (
        <div>
          <p className="text-neutral-400 text-xl mb-3">
            Mandatory documents: NIN
          </p>{" "}
          <DocumentsForm handleSubmit={handleDocumentsUpload} />
        </div>
      ),
    },
    {
      key: "4",
      component: (
        <PaymentForm amount={115000} onSuccess={handlePaymentSuccess} />
      ),
    },
  ];

  return {
    company_form: {
      formPage,
      setFormPage,
      formData,
      setFormData,
      formPages,
      firstFormHandleSubmit,
      handleDocumentsUpload,
    },
    company_files: {
      files,
      setFiles,
    },
    utils: {
      isLoading,
      setIsLoading,
      profileUploaded,
      setProfileUploaded,
      handlePaymentSuccess,
    },
  };
};
