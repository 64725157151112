import React from "react";
import { DataTable } from "./data-table";
import { AllWaiverRequestsAdmin, WaiverRequestsColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { waiverRequests } from "@/lib/dummy-data/waiverRequests";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function WaiverRequestsTableAdmin() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["all-waiver-requests"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/all-waiver-application`
        );
        const waiver_requests: AllWaiverRequestsAdmin[] =
          data?.allWaiverrequest.map((application: any) => ({
            id: application.waiver_id,
            importer_name: application.importer_name,
            importer_category: application.importer_category,
            status: application.status,
            date: application.created_at,
          }));
        return waiver_requests || [];
      } catch (error) {
        toast.error("An error occurred");
      }
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading} page={false}>
      <DataTable columns={WaiverRequestsColumns} data={data || []} />
    </LoaderWrapper>
  );
}
