import { FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  FormControl,
  FormLabel,
  FormItem,
  FormField,
} from "@/components/ui/form";

import React from "react";
import { getBackendUrl } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import axios from "axios";
import { Form } from "@/components/ui/form";
import {
  Select,
  SelectItem,
  SelectValue,
  SelectTrigger,
  SelectContent,
} from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import { DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const formSchema = z
  .object({
    classification: z.string({
      required_error: "Please select a classification",
    }),
    unit: z.string({
      required_error: "Please select a unit",
    }),
    rangeStart: z
      .number({
        required_error: "Please select a range start value",
      })
      .min(0, "Minimum value is 0")
      .max(2500000000, "Maximum value is 2.5 billion"),
    rangeEnd: z
      .number({
        required_error: "Please select a range end value",
      })
      .min(0, "Minimum value is 0")
      .max(2500000000, "Maximum value is 2.5 billion"),
    cost: z.number({
      required_error: "Please enter a cost",
    }),
  })
  .refine((data) => data.rangeEnd > data.rangeStart, {
    message: "End value must be greater than start value",
    path: ["rangeEnd"],
  });

type FormValues = z.infer<typeof formSchema>;

export function AddPricingForm({
  classifications,
  defaultValues,
}: {
  classifications: {
    id: number;
    name: string;
  }[];
  defaultValues?: {
    classification: string;
    unit: string;
    rangeStart: number;
    rangeEnd: number;
    cost: number;
  };
}) {
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      classification: defaultValues?.classification || "",
      unit: defaultValues?.unit || "",
      rangeStart: defaultValues?.rangeStart || 0,
      rangeEnd: defaultValues?.rangeEnd || 2500000000,
      cost: defaultValues?.cost || 0,
    },
  });

  const [range, setRange] = React.useState<[number, number]>([
    defaultValues?.rangeStart || 0,
    defaultValues?.rangeEnd || 2500000000,
  ]);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  async function onSubmit(values: FormValues) {
    setLoading(true);
    try {
      await axios.post(`${getBackendUrl()}/add-classification-price-range`, {
        classification: values.classification,
        sub_classification: " ", //Optional
        quantity_range: `${values.rangeStart}-${values.rangeEnd}`,
        range_cost: values.cost,
        unit_of_measurement: values.unit,
        //Optional
        new_registration_fee: " ",
        renewal_fee: " ",
        total_new_registration_fee: " ",
        total_renewal_fee: " ",
        fob_amount: " ",
      });
      toast.success("Pricing added successfully");
      form.reset();
      window.location.reload();
    } catch (error) {
      toast.error("Failed to add pricing");
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <FormField
          control={form.control}
          name="classification"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Classification {field.value}</FormLabel>
              <Select onValueChange={field.onChange} value={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select classification..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent className="max-h-[200px]">
                  {classifications.map((classification) => (
                    <SelectItem
                      key={classification.id}
                      value={classification.name}
                    >
                      {classification.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="unit"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Unit</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a unit" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="kg">Kilogram (kg)</SelectItem>
                  <SelectItem value="ton">Ton</SelectItem>
                  <SelectItem value="piece">Piece</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4">
          <FormLabel>Amount Range</FormLabel>
          <div className="flex gap-4 items-center">
            <div className="flex-1">
              <Input
                type="number"
                placeholder="Min"
                value={range[0]}
                min={0}
                max={2500000000}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0 && value <= range[1]) {
                    setRange([value, range[1]]);
                    form.setValue("rangeStart", value);
                  }
                }}
                className="text-right"
              />
            </div>
            <span className="text-primary-700">to</span>
            <div className="flex-1">
              <Input
                type="number"
                placeholder="Max"
                value={range[1]}
                min={0}
                max={2500000000}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= range[0] && value <= 2500000000) {
                    setRange([range[0], value]);
                    form.setValue("rangeEnd", value);
                  }
                }}
                className="text-right"
              />
            </div>
          </div>
          {/* <div className="pt-2">
            <Slider
              defaultValue={[0, 2500000000]}
              min={0}
              max={2500000000}
              step={1000}
              value={range}
              onValueChange={(value: [number, number]) => {
                setRange(value);
                form.setValue("rangeStart", value[0]);
                form.setValue("rangeEnd", value[1]);
              }}
              className="w-full"
            />
          </div> */}
          <div className="flex justify-between text-sm text-muted-foreground">
            <div>₦{range[0].toLocaleString()}</div>
            <div>₦{range[1].toLocaleString()}</div>
          </div>
        </div>

        <FormField
          control={form.control}
          name="cost"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Range Cost</FormLabel>
              <FormControl>
                <Input
                  type="number"
                  placeholder="Enter cost"
                  {...field}
                  onChange={(e) => field.onChange(Number(e.target.value))}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <DialogFooter>
          <Button type="submit" className="w-full" disabled={loading}>
            {loading ? "Saving..." : "Save Changes"}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
}
