import * as React from "react";
import { TrendingUp } from "lucide-react";
import { Label, Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useClearanceAnalytics } from "@/hooks/analytics/useClearanceAnalytics";
import { getPercentage } from "@/lib/utils";
import { useLegalAnalytics } from "@/hooks/analytics/useLegalAnalytics";

const chartConfig = {
  "resolved-cases": {
    label: "Resolved Cases",
    color: "rgb(var(--primary))",
  },
  "ongoing-cases": {
    label: "Ongoing Cases",
    color: "rgb(var(--secondary))",
  },
} satisfies ChartConfig;

export function FalsifiedRecordsChart({
  data,
}: {
  data: ReturnType<typeof useLegalAnalytics>["data"];
}) {
  const chartData = [
    {
      category: "ongoing-cases",
      count: 30,
      fill: "var(--color-ongoing-cases)",
    },
    {
      category: "resolved-cases",
      count: 100,
      fill: "var(--color-resolved-cases)",
    },
  ];

  const legendData = Object.entries(chartConfig).map(([key, value]) => ({
    label: value.label,
    color: value.color,
    value: chartData.find((item) => item.category === key)?.count || 0,
  }));
  return (
    <div className="flex flex-col">
      <div className="flex-1 flex flex-col justify-center items-center pb-0">
        <ChartContainer
          config={chartConfig}
          className="aspect-square min-w-[300px] max-h-[300px]"
        >
          <PieChart>
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Pie
              data={chartData}
              dataKey="count"
              nameKey="category"
              strokeWidth={2}
              stroke="white"
            ></Pie>
          </PieChart>
        </ChartContainer>
        <div className="flex flex-col w-full max-w-[250px]">
          {legendData.map((data, index) => (
            <div className="flex items-center gap-1">
              <div
                className="w-2 min-w-2 aspect-square rounded-full"
                style={{ backgroundColor: data.color }}
              ></div>
              <span className="capitalize text-sm">{data.label}</span>
              <p className="ml-auto">
                {getPercentage(
                  data.value,
                  chartData
                    .map((item) => item.count)
                    .reduce((prev, val) => val + prev)
                )}%
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
