import React, { useCallback, useEffect } from "react";
import { TrendingUp } from "lucide-react";
import { CartesianGrid, Label, Line, LineChart, XAxis, YAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { usePaymentAnalytics } from "@/hooks/analytics/usePaymentAnalytics";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function YearlyRevenueTrends({
  payment_type,
}: {
  payment_type: "all" | "clearance" | "registration";
}) {
  const data = useCallback(() => usePaymentAnalytics(), [])();

  const chartConfig = {
    desktop: {
      label: "trend",
      color: "rgb(var(--primary))",
    },
  } satisfies ChartConfig;

  const tickData = { stroke: "black", strokeWidth: 0.25, fontSize: 11 };


  return (
    <LoaderWrapper isLoading={data.utils.isLoading} page={false}>
      <div className="pb-10">
        <ChartContainer config={chartConfig}>
          <LineChart
            accessibilityLayer
            data={data?.charts?.[payment_type].yearly || []}
            margin={{
              left: 30,
              right: 12,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeWidth={0.25} />
            <XAxis
              dataKey="year"
              tickLine={false}
              tick={tickData}
              axisLine={false}
              label={{
                value: "Years",
                position: "insideBottom",
                offset: -20,
                stroke: "green",
                strokeWidth: 0.5,
              }}
              style={{
                fill: "black",
              }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              tickMargin={0}
              tick={tickData}
              style={{
                fill: "black",
              }}
              tickFormatter={(value) =>
                `₦${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
              }
            />

            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <Line
              dataKey="trend"
              type="linear"
              stroke="#58B7CB"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ChartContainer>
      </div>
    </LoaderWrapper>
  );
}
