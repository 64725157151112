import React from "react";
import { DataTable } from "./data-table";
import { ClientsColumns, WaiverRequestsTableData } from "./columns";
import { useStore } from "@/contexts/agentStore";
import { useStore as userStore_ } from "@/contexts/userStore";
import { toast } from "sonner";
import { getBackendUrl } from "@/lib/utils";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";

export default function WaiverRequestsTable() {
  const agentStore = useStore((state) => state);
  const userStore = userStore_((state) => state.user);

  const { data, isLoading } = useQuery({
    queryKey: ["importer-waiver-applications"],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `${getBackendUrl()}/get-waiver/${userStore.email}`
        );
        const waiver_apps: WaiverRequestsTableData[] = data?.Userwaiverlist.map(
          (application: any) => ({
            id: application.waiver_id,
            clearance_id: application.clearance_id,
            item: application.item,
            percentage: application.waiver_percentage,
            date: application.created_at,
            status: application.status,
            waiver_code: application?.waiver_code || "",
            applied: false,
          })
        );
        return waiver_apps || [];
      } catch (error) {
        // toast.error("An Error Occurred");
      }
    },
  });
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable columns={ClientsColumns} data={data || []} />
    </LoaderWrapper>
  );
}
