import React from "react";
import RevenueTopImportCategories from "../dashboard/components/RevenueTopImportCategories";
import WaiverRecordsChart from "./components/WaiverRecordsChart";
import RevenueByCategory from "./components/RevenueByCategory";
import RevenueByState from "./components/RevenueByState";
import Invoicing from "./components/Invoicing";
import { useStore } from "@/contexts/userStore";

export default function AccountingPage() {
  const SECTION_NAME = "Accounting";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  if (!permission_data || !permission_data.isAllowed) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }

  const module_perms = permission_data?.permissions;
  const perm = module_perms.find((perm) => perm.module === SECTION_NAME);
  return (
    <div className="flex flex-col gap-7">
      {perm?.permissions.includes("view:revenue-of-top-import-categories") && (
        <RevenueTopImportCategories />
      )}
      {perm?.permissions.includes("view:waiver-records") && (
        <WaiverRecordsChart />
      )}
      {/* <RevenueByCategory /> */}
      {perm?.permissions.includes("view:revenue-by-state") && (
        <RevenueByState />
      )}
      {perm?.permissions.includes("view:invoicing") && <Invoicing />}
    </div>
  );
}
