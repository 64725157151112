import { buttonVariants } from "@/components/ui/button";
import ExportComponentAsPDF from "@/components/ui/export-component-as-pdf";
import DataFilter, { FilterData } from "@/components/ui/filter";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { Filter } from "lucide-react";
import React, { useState } from "react";

const CardStats = [
  {
    title: "Chemicals (Raw Materials)",
    value: 100000000,
    remark: {
      percentage: 25.2,
      increased: true,
    },
  },
  {
    title: "Used Electrical Electronics Equipment",
    value: 70000000,
    remark: {
      percentage: 10.16,
      increased: true,
    },
  },
  {
    title: "Chemicals (Processed)",
    value: 50000000,
    remark: {
      percentage: 18.5,
      increased: false,
    },
  },
  {
    title: "Plastics (Thermosets)",
    value: 40000000,
    remark: {
      percentage: 10.16,
      increased: true,
    },
  },
];

export default function RevenueTopImportCategories() {
  const componentRef = React.useRef<HTMLDivElement>(null);
  const [data, setData] = useState<FilterData>(
    CardStats.map((data) => ({
      id: data.title,
      value: data.value,
    }))
  );
  const [filteredData, setFilteredData] = useState<FilterData>(data);

  const filteredIDs = filteredData.map((d) => d.id);
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="w-full flex justify-between items-center">
        <h4 className="font-medium text-primary-700">
          Revenue of Top Import Categories
        </h4>
        <div className="flex items-center gap-5">
          <DataFilter
            data={data}
            setData={setData}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
          />
          <ExportComponentAsPDF componentRef={componentRef} />
        </div>
      </div>
      <div ref={componentRef} className="grid grid-cols-4 gap-5 w-full p-4">
        {CardStats.filter((stat) => filteredIDs.includes(stat.title)).map(
          (stat) => (
            <div
              key={`${stat.title} ${stat.value}`}
              className="w-full bg-primary-50 rounded-xl p-2 flex flex-col gap-3 justify-between"
            >
              <p className="text-primary-700 text-sm max-w-[150px]">
                {stat.title}
              </p>
              <h3 className="font-bold text-xl">
                ₦{stat.value.toLocaleString()}
              </h3>
              <p
                className={cn(
                  stat.remark.increased
                    ? "text-primary-300"
                    : "text-destructive"
                )}
              >
                {stat.remark.increased ? "+" : "-"}
                {stat.remark.percentage}% (Last 30 days)
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
}
