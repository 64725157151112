import React from "react";
import { DataTable } from "./data-table";
import { ClassificationPricingColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";
import {
  flattenHSCodeArray,
  HSCodeFlattened,
  HSCodes,
} from "@/lib/dummy-data/hsCodes";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { FilterData } from "@/components/ui/filter";

export interface ClassificationPricingResponseData {
  classificationquantityrange: {
    id: number;
    classification: string;
    quantity_range: string;
    range_cost: string;
    unit_of_measurement: string;
    status: string;
    created_at: string;
    updated_at: string;
    new_registration_fee: string | null;
    renewal_fee: string | null;
    total_new_registration_fee: string | null;
    total_renewal_fee: string | null;
    fob_amount: string | null;
    sub_classification: string | null;
  }[];
}

export default function ClassificationPricingTable({
  permissions,
}: {
  permissions: string[];
}) {
  const agentStore = useStore((state) => state);
  const flattenedHSCodes = flattenHSCodeArray(HSCodes);
  const { data } = useQuery({
    queryKey: ["classification-pricing"],
    queryFn: async () => {
      const { data }: { data?: ClassificationPricingResponseData } =
        await axios.get(`${getBackendUrl()}/get-classification-price-range`);

      return data?.classificationquantityrange || [];
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.classification,
      value: Number(item.range_cost || "0"),
      date: item.created_at,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <div>
      <DataTable
        columns={ClassificationPricingColumns({ permissions })}
        data={updatedData}
        setData_={setData_}
        permissions={permissions}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
      />
    </div>
  );
}
