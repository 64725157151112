import { userStoreInitialState, useStore } from "@/contexts/userStore";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { getBackendUrl } from "@/lib/utils";
import { IndividualUpdateFormType } from "./_schema";
import axios from "axios";
import IndividualUpdateFormPageOne from "./_formpages/formPageOne";
import { stateNamesMap } from "@/lib/site-data";

export const useIndividualUpdateForm = () => {
  const userStore = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [formPage, setFormPage] = useState(1);
  const navigate = useNavigate();

  const firstFormHandleSubmit = async (values: IndividualUpdateFormType) => {
    setIsLoading(true);
    try {
      // const individual_profile_data = {
      //   account_type: "agent",
      //   email: userStore.user.email,
      //   importer_name: values.fullName,
      //   phone_number: values.phoneNumber,
      //   state: stateNamesMap[values.state] || values.state,
      //   lga: values.lga,
      //   street: values.street,
      //   association: values.association,
      //   national_identity_number: userStore.user.nin,
      //   created_by: userStore.user.email,
      // };
      const individual_profile_data = {
        account_type: userStore.user.account_type,
        name: values.fullName,
        phone_number: values.phoneNumber,
        email: userStore.user.email,
        state: stateNamesMap[values.state] || values.state,
        lga: values.lga,
        street_address: values.street,
        association: values.association,
        national_identity_number: userStore.user.nin,
      };
      await axios.post(
        `${getBackendUrl()}/store-importer-information`,
        individual_profile_data
      );
      setIsLoading(false);
      const updated_user: userStoreInitialState["user"] = {
        ...userStore.user,
        name: values.fullName,
        street: values.street,
        association: values.association,
      };
      window.localStorage.setItem("nesrea_user", JSON.stringify(updated_user));
      toast.success("User Profile Data Updated Successfully");
      navigate("/user/dashboard");
    } catch (error) {
      setIsLoading(false);
      toast.error("An error occurred");
    }
  };

  const formPages = [
    {
      key: "1",
      component: (
        <>
          <IndividualUpdateFormPageOne
            handleSubmit={firstFormHandleSubmit}
            canSubmit={isEditing}
          />
        </>
      ),
    },
  ];

  return {
    individual_form: {
      formPages,
      firstFormHandleSubmit,
      formPage,
      setFormPage,
    },
    utils: {
      isLoading,
      setIsLoading,
      isEditing,
      setIsEditing,
    },
  };
};
