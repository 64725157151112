import React from "react";
import { DataTable } from "./data-table";
import { RegistrationPaymentHistoryColumns } from "./columns";
import { useStore } from "@/contexts/agentStore";
import paymentsData from "@/lib/dummy-data/paymentsData";
import certificatesData from "@/lib/dummy-data/certificatesData";
import { importDocumentationData } from "@/lib/dummy-data/importDocumentationData";
import { registrationPaymentsHistoryData } from "@/lib/dummy-data/registrationPayments";
import { useQuery } from "@tanstack/react-query";
import LoaderWrapper from "@/components/ui/loader-wrapper";
import axios from "axios";
import { getBackendUrl } from "@/lib/utils";
import { FilterData } from "@/components/ui/filter";
import { RegistrationPaymentHistoryData } from "@/lib/interfaces";

export default function GeneralRegistrationPaymentHistoryTable() {
  const agentStore = useStore((state) => state);
  const { data, isLoading } = useQuery({
    queryKey: ["all-registration-payments"],
    queryFn: async () => {
      try {
        const { data }: { data: { allagent: any[]; alluser: any[] } } =
          await axios.get(`${getBackendUrl()}/all-registration-payments`);
        let payment_details: RegistrationPaymentHistoryData = [];

        data?.allagent.map((item: any) => {
          payment_details.push({
            id: item.agent_id,
            name: item.agent_name,
            amount: item.amount,
            paymentID: item.reference,
            paymentDate: item.payment_date,
            category: item.importer_category,
            nesreaID: item.nesrea_id,
            status: item.payment_status,
          });
        });

        data?.alluser.map((item: any) => {
          payment_details.push({
            id: item.nesrea_id,
            name: item.importer_name,
            amount: item.amount,
            paymentID: item.reference,
            paymentDate: item.payment_date,
            nesreaID: item.nesrea_id,
            category:item.importer_category,
            status: item.payment_status,
          });
        });
        return payment_details;
      } catch (error) {}
    },
  });

  const [data_, setData_] = React.useState<FilterData>([]);
  const [filteredData, setFilteredData] = React.useState<FilterData>([]);
  const filteredIDs = filteredData.map((d) => d.id);

  const updatedData = React.useMemo(() => {
    return data?.filter((stat) => filteredIDs.includes(`${stat.id}`)) || [];
  }, [data, filteredIDs]);

  React.useEffect(() => {
    const newData = data?.map((item) => ({
      id: `${item.id}`,
      name: item.name,
      value: Number(item.amount || "0"),
      payment_id: item.paymentID,
      date: item.paymentDate,
      status: item.status,
    }));
    setData_(newData || []);
    if (!filteredData.length) {
      setFilteredData(newData || []);
    }
  }, [data]);
  return (
    <LoaderWrapper isLoading={isLoading}>
      <DataTable
        columns={RegistrationPaymentHistoryColumns}
        setData_={setData_}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        data_={data_}
        data={updatedData || []}
      />
    </LoaderWrapper>
  );
}
