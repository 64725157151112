import { ImportDocumentationData } from "@/lib/interfaces";

export const importDocumentationData: ImportDocumentationData = [
  {
    id: "1",
    category: "Chemicals(Raw Materials)",
    documents: [
      {
        id: "1",
        documentName: "CAC",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "2",
        documentName: "Sodium Chloride",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "3",
        documentName: "Potassium Nitrate",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "4",
        documentName: "Calcium Carbonate",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
    ],
  },
  {
    id: "2",
    category: "Pharmaceuticals",
    documents: [
      {
        id: "5",
        documentName: "Aspirin",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "6",
        documentName: "Ibuprofen",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "7",
        documentName: "Paracetamol",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
    ],
  },
  {
    id: "3",
    category: "Plastics",
    documents: [
      {
        id: "8",
        documentName: "Polyethylene",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "9",
        documentName: "Polypropylene",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "10",
        documentName: "Polystyrene",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
    ],
  },
  {
    id: "4",
    category: "Metals",
    documents: [
      {
        id: "11",
        documentName: "Aluminum",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "12",
        documentName: "Copper",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
      {
        id: "13",
        documentName: "Steel",
        documentStatus: "compulsory",
        documentCode: "123456",
      },
    ],
  },
  // Add more categories and documents as needed
];
