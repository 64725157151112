import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown, MoreHorizontal, Pencil } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

import { cn, formatDate } from "@/lib/utils";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { agentStoreInitialState, useStore } from "@/contexts/agentStore";
import { PayementTableData, PaymentsSchema } from "@/lib/interfaces";
import { Link } from "react-router-dom";
import { format } from "fecha";

export const ClientsColumns: ColumnDef<PayementTableData>[] = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Title
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  {
    accessorKey: "payment_id",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Payment ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
  },
  // {
  //   accessorKey: "clearance_id",
  //   header: ({ column }) => {
  //     return (
  //       <div
  //         className="flex items-center cursor-pointer hover:underline"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
  //       >
  //         Clearance ID
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: "amount",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Amount
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { amount } = row.original;
      return (
        <p className="font-medium">
          ₦ {Number(amount || "0").toLocaleString()}
        </p>
      );
    },
  },

  {
    accessorKey: "date_created",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Date Created
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      const { date_created } = row.original;
      return <p>{format(new Date(date_created), "mediumDate")}</p>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => {
      return (
        <div
          className="flex items-center cursor-pointer hover:underline"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Status
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </div>
      );
    },
    cell: ({ row }) => {
      let { status } = row.original;
      let isPaid = status?.toLowerCase() == "success";
      return (
        <p className={cn(isPaid ? "text-primary-300" : "text-orange-600")}>
          {isPaid ? "PAID" : "Pending"}
        </p>
      );
    },
  },
  // {
  //   accessorKey: "action",
  //   header: ({ column }) => {
  //     return (
  //       <div className="flex items-center">
  //         Action
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </div>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     return (
  //       <Dialog>
  //         <DialogTrigger className="underline text-primary-700 text-sm">
  //           View
  //         </DialogTrigger>
  //         <DialogContent>
  //           <div
  //             className="w-full aspect-[9/12] bg-contain bg-center bg-no-repeat"
  //             style={{ backgroundImage: `url('/images/cert.png')` }}
  //           ></div>
  //           <Link
  //             to={"/images/cert.png"}
  //             target="_blank"
  //             className={cn(buttonVariants({ variant: "default" }), "px-8")}
  //           >
  //             Download
  //           </Link>
  //         </DialogContent>
  //       </Dialog>
  //     );
  //   },
  // },
];
