import axios from "axios";
import { getBackendUrl } from "../utils";
import { userStoreInitialState, useStore } from "@/contexts/userStore";
import { toast } from "sonner";
import { NavigateFunction } from "react-router-dom";

export const isLoggedIn = async (): Promise<boolean> => {
  return true;
};

export const logout = async (
  clearUser: () => void,
  navigate: NavigateFunction
) => {
  const user: userStoreInitialState["user"] = JSON.parse(
    localStorage.getItem("nesrea_user") || "{}"
  );
  try {
    // TODO: logout from backend

    //

    // Remove user data from local storage
    localStorage.removeItem("nesrea_user");
    localStorage.removeItem("nesrea_session_id");
    localStorage.removeItem("nesrea_session_expiry");
    clearUser();
    // toast.success("Logged out successfully");
    if (user?.account_type == "admin") {
      navigate("/admin");
      return;
    }
    navigate("/user");
  } catch (error) {
    // toast.error("Failed to logout");
  }
};
