import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Bar, LabelList } from "recharts";
import { ChartConfig, ChartTooltipContent } from "@/components/ui/chart";
import { ChartTooltip } from "@/components/ui/chart";
import { BarChart, XAxis } from "recharts";
import { YAxis } from "recharts";
import { ChartContainer } from "@/components/ui/chart";

const filters = [
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
  },
  {
    title: "Monthly",
    value: "monthly",
  },
  {
    title: "Quarterly",
    value: "quarterly",
  },
  {
    title: "Annually",
    value: "annually",
  },
];

const chartData = [
  {
    category: "Chemicals (Raw Materials)",
    value: 275,
    fill: "var(--color-chemicals)",
  },
  {
    category: "Used Electrical Electronics Equipment",
    value: 200,
    fill: "var(--color-electronics)",
  },
  {
    category: "Used Auto Parts/Vehicles",
    value: 187,
    fill: "var(--color-autoParts)",
  },
  {
    category: "Used Industrial Machineries/Equipment",
    value: 173,
    fill: "var(--color-industrial)",
  },
  { category: "Used Mixed Items", value: 90, fill: "var(--color-mixed)" },
  {
    category: "Used Medical Items/Equipment",
    value: 150,
    fill: "var(--color-medical)",
  },
  {
    category: "Used Household Items",
    value: 120,
    fill: "var(--color-household)",
  },
  {
    category: "Used Sports/Gym Equipment",
    value: 80,
    fill: "var(--color-sports)",
  },
  {
    category: "Chemical for Explosives: NSA, NNRA",
    value: 60,
    fill: "var(--color-explosives)",
  },
  {
    category: "Food and Drugs: NAFDAC",
    value: 100,
    fill: "var(--color-foodDrugs)",
  },
  {
    category: "New Equipment: SONCAP",
    value: 140,
    fill: "var(--color-newEquipment)",
  },
  {
    category: "Complete Aircraft: Ministry of Aviation",
    value: 50,
    fill: "var(--color-aircraft)",
  },
];
const chartConfig = {
  chemicals: {
    label: "Chemicals (Raw Materials)",
    color: "#005C00",
  },
  electronics: {
    label: "Used Electrical Electronics Equipment",
    color: "#005C00",
  },
  autoParts: {
    label: "Used Auto Parts/Vehicles",
    color: "#005C00",
  },
  industrial: {
    label: "Used Industrial Machineries/Equipment",
    color: "#005C00",
  },
  mixed: {
    label: "Used Mixed Items",
    color: "#005C00",
  },
  medical: {
    label: "Used Medical Items/Equipment",
    color: "#005C00",
  },
  household: {
    label: "Used Household Items",
    color: "#005C00",
  },
  sports: {
    label: "Used Sports/Gym Equipment",
    color: "#005C00",
  },
  explosives: {
    label: "Chemical for Explosives: NSA, NNRA",
    color: "#005C00",
  },
  foodDrugs: {
    label: "Food and Drugs: NAFDAC",
    color: "#005C00",
  },
  newEquipment: {
    label: "New Equipment: SONCAP",
    color: "#005C00",
  },
  aircraft: {
    label: "Complete Aircraft: Ministry of Aviation",
    color: "#005C00",
  },
} satisfies ChartConfig;

const tickData = {
  stroke: "#000000",
  strokeWidth: 0,
  fill: "#000000",
  fontSize: 14,
};

export default function ImportClearanceApplicationsByItemCategory() {
  return (
    <div className="w-full p-5 flex flex-col gap-5 shadow-[0_4px_20px_0px_#0000001A] rounded-xl">
      <div className="flex justify-between items-center">
        <h4 className="font-semibold text-primary-700 flex">
          Clearances By Item Category
        </h4>
        <Select>
          <SelectTrigger className="w-fit">
            <div className="flex items-center gap-2 text-primary-700 font-semibold">
              <svg
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 6H14M1.5 1H16.5M6.5 11H11.5"
                  stroke="currentColor"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <SelectValue placeholder="Filters" />
            </div>
          </SelectTrigger>
          <SelectContent>
            {filters.map((filter) => (
              <SelectItem key={filter.value} value={filter.value}>
                {filter.title}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="w-full flex items-center justify-center h-[60vh]">
        <ChartContainer className="h-full w-full" config={chartConfig}>
          <BarChart
            accessibilityLayer
            data={chartData}
            layout="vertical"
            
            margin={{
              right: 42,
              bottom:20
            }}
          >
            <YAxis
              dataKey="category"
              type="category"
              tick={tickData}
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              width={350}
              style={{
                fill:'black'
              }}
            />
            <XAxis dataKey="value" type="number" tickMargin={10} style={{fill:'black'}} />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="value" layout="vertical" radius={0}>
              <LabelList
                position="right"
                offset={5}
                fontSize={14}
                className="fill-foreground"
              />
            </Bar>
          </BarChart>
        </ChartContainer>
      </div>
    </div>
  );
}
