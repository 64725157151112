import React from "react";
import ClearanceApplicationTable from "@/components/tables/clearanceApplicationTable";
import { useStore } from "@/contexts/userStore";

export default function ClearanceApplicationPage() {
  const SECTION_NAME = "Clearance";
  const MODULE_NAME = "Clearance-Application";

  const user = useStore((state) => state.user);
  const permission_data = user.admin_role?.find(
    (role) => role.name === SECTION_NAME
  );

  const module_perms = permission_data?.permissions;
  const perm = module_perms?.find((perm) => perm.module === SECTION_NAME);
  const mod_perm = module_perms?.find((perm) => perm.module === MODULE_NAME);
  if (
    !permission_data ||
    !permission_data.isAllowed ||
    !mod_perm?.isAllowed ||
    !perm?.permissions.includes("view:import-clearance-applications")
  ) {
    return (
      <div>
        <h1 className="text-2xl font-bold">
          You do not have access to this page
        </h1>
      </div>
    );
  }
  return (
    <div>
      <ClearanceApplicationTable />
    </div>
  );
}
