import { buttonVariants } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn, getPercentage } from "@/lib/utils";
import React from "react";
import { Link } from "react-router-dom";
import { TrendingUp } from "lucide-react";
import { Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { useUserAnalytics } from "@/hooks/analytics/useUserAnalytics";

export default function Registrations() {
  const data = useUserAnalytics();

  const chartData = [
    {
      category: "pending-registrations",
      value: Number(data?.registration_status.TOTAL_NEW_USERS || 0),
      fill: "#50A7B9",
    },
    {
      category: "rejected-registrations",
      value: Number(data?.registration_status.TOTAL_REJECTED_USERS || 0),
      fill: "#8F8F8F",
    },
    {
      category: "approved-registrations",
      value: Number(data?.registration_status.TOTAL_APPROVED_USERS || 0),
      fill: "#005C00",
    },
  ];


  const chartConfig = {
    "approved-registrations": {
      label: "Approved Registrations",
    },
    "pending-registrations": {
      label: "Pending Registrations",
    },
    "rejected-registrations": {
      label: "Rejected Registrations",
    },
  } satisfies ChartConfig;
  return (
    <div className="w-full shadow-[0_4px_20px_0px_#00000014] rounded-xl p-4 flex flex-col gap-5">
      <div className="flex items-center justify-between w-full">
        <h1 className="text-primary-700 text-xl font-medium">Registrations</h1>
        <Sheet>
          <SheetTrigger
            className={cn(
              buttonVariants({ variant: "default" }),
              "bg-transparent text-primary-700 hover:text-primary-foreground flex gap-1 items-center"
            )}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Filters
          </SheetTrigger>
          <SheetContent className="bg-secondary-50 flex flex-col gap-5 py-10">
            {/* Filters Here */}
          </SheetContent>
        </Sheet>
      </div>
      <div className="w-full min-h-screen">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[450px]"
        >
          <PieChart>
            <ChartTooltip content={<ChartTooltipContent />} />
            <Pie data={chartData} dataKey="value" nameKey="category" />
          </PieChart>
        </ChartContainer>

        <div className="flex flex-col gap-2 items-center w-fit mx-auto">
          {[...chartData]
            .sort((a, b) => b.value - a.value)
            .map((data) => (
              <div 
              key={`${data.category} ${data.value}`}
              className="flex items-center gap-2 w-full justify-between">
                <div
                  className="w-[15px] aspect-square rounded-full"
                  style={{ backgroundColor: data.fill }}
                ></div>
                <p className="capitalize">
                  {data.category.split("-").join(" ")}
                </p>
                <p>
                  {getPercentage(
                    data.value,
                    chartData
                      .map((item) => item.value)
                      .reduce((prev, val) => val + prev)
                  )}
                  %
                </p>
              </div>
            ))}
        </div>

        <div className="flex flex-wrap justify-center gap-5 w-full my-10">
          {[...chartData]
            .sort((a, b) => b.value - a.value)
            .map((data, index) => (
              <div
                key={`${data.category} ${data.value}`}
                className={cn(
                  "flex flex-col gap-3 p-5 rounded-xl flex-1 min-w-[200px] max-w-[250px] h-full justify-between bg-primary-50 text-primary-700",
                  index == 1 && "bg-primary-700 text-primary-foreground"
                )}
              >
                <p className={cn("text-xs ")}>
                  Total number of {data.category.split("-").join(" ")}
                </p>
                <h4 className="font-bold text-2xl">{data.value}</h4>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
